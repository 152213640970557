import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { LoginComponent } from "./login.component";
import { LoginGuard } from "./login.guard";
import { LoginRoutingModule } from "./login.routing";
import {
    ModalFormLanguageModule
} from "app/shared/components/modal-form-language/modal-form-language.module";

@NgModule({
    imports: [LoginRoutingModule, SharedModule, ModalFormLanguageModule],
    declarations: [LoginComponent],
    providers: [LoginGuard],
})
export class LoginModule {}
