<div class="p-2 border">
    <form class="mb-2" [formGroup]="form">
        <ng-multiselect-dropdown class="dropdown-in-tab" (onDeSelect)="prepareProducts('DeSelect')" (onSelectAll)="prepareProducts('selectAll')" (onDeSelectAll)="prepareProducts('deselectAll')" (onSelect)="prepareProducts('select', $event)" formControlName="selectedProducts" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownSettings" [data]="productList" [disabled]="disabled || !selectedLanguage?.isBasic"></ng-multiselect-dropdown>
    </form>

    <ng-container *ngIf="products.length > 0">
        <ul ngbNav #nav="ngbNav" class="nav-tabs mt-3" [appSortable]="sortableJSOptions">
            <li *ngFor="let product of products; let i = index" [ngbNavItem]="i">
                <a ngbNavLink class="sortable">
                    {{ product.name }}
                </a>
                <ng-template ngbNavContent>
                    <div *ngFor="let field of product.value">
                        <div class="form-group row">
                            <label class="col-12 col-form-label text-truncate" for="">{{ field.name }}</label>
                            <div class="col-11">
                                <div class="row ml-1" >
                                    <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="selectedLanguage?.hasOwnProperty('flag') && !selectedLanguage.isBasic && ['text','textarea', 'number'].includes(field.fieldType)">
                                        <img [src]="selectedLanguage?.flag" />
                                    </span>
                                    <!-- Text -->
                                    <input [disabled]="disabled || (!selectedLanguage.isBasic && !field.translatable)" (change)="updateValue($event.target.value, field)" value="{{ selectedLanguage &&
                                        !selectedLanguage.isBasic &&
                                        field.valueTranslations &&
                                        field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                        field.valueTranslations[selectedLanguage.internationalCode] &&
                                        field.translatable
                                            ? field.valueTranslations[selectedLanguage.internationalCode]
                                            : field.value }}" type="text" class="form-control col-11" *ngIf="field.fieldType === 'text'"/>
                                    <!-- Number -->
                                    <input [disabled]="disabled || (!selectedLanguage.isBasic && !field.translatable)" (keypress)="checkIfNumber($event)" (change)="updateValue($event.target.value, field)" [value]="selectedLanguage &&
                                    !selectedLanguage.isBasic &&
                                    field.valueTranslations &&
                                    field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                    field.valueTranslations[selectedLanguage.internationalCode] &&
                                    field.translatable
                                        ? field.valueTranslations[selectedLanguage.internationalCode]
                                        : field.value" type="number" class="form-control col-11" *ngIf="field.fieldType === 'number'"/>
                                    <!-- Textarea -->
                                    <app-text-editor class="col-11"[disabled]="disabled || (!selectedLanguage.isBasic && !field.translatable)" *ngIf="field.fieldType === 'textarea'" [value]="selectedLanguage &&
                                    !selectedLanguage.isBasic &&
                                    field.valueTranslations &&
                                    field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                    field.valueTranslations[selectedLanguage.internationalCode] &&
                                    field.translatable
                                        ? field.valueTranslations[selectedLanguage.internationalCode]
                                        : field.value" (focusOut)="updateValue($event, field)"></app-text-editor>
                                    <!-- Checkbox -->
                                    <label class="switch switch-pill" *ngIf="field.fieldType === 'checkbox'" [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}">
                                        <input [disabled]="disabled" (debounceClick)="updateValue($event.target.value, field)" [checked]="field.value === '1'" value="{{ field.value }}" type="checkbox" floating-label class="switch-input" />
                                        <span class="switch-slider"></span>
                                    </label>
                                    <!-- <label class="switch switch-pill switch-primary">
                                        <input [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                               (change)="updateValue($event.target.checked, field)"
                                               type="checkbox" class="switch-input"
                                               [checked]="field.value === '1'" value="{{field.value}}">
                                        <span class="switch-slider"></span>
                                    </label> -->
    
                                    <!-- Date -->
                                    <input [disabled]="disabled || !selectedLanguage?.isBasic" (change)="updateValue($event.target.value, field)" value="{{ field.value }}" class="form-control col-11" type="date" *ngIf="field.fieldType === 'date'" [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}"/>
    
                                    <!-- List / Simple selection -->
                                    <select [disabled]="disabled || !selectedLanguage?.isBasic" (change)="updateValueList($event.target.value, field)" [(ngModel)]="field.value" class="form-control col-11" *ngIf="field.fieldType === 'list'" [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}">
                                        <option></option>
                                        <option *ngFor="let value of field.listValue">{{ value }}</option>
                                    </select>
    
                                    <!-- List / Multiple selection -->
                                    <app-edit-list-multiple [disabled]="disabled || !selectedLanguage?.isBasic" (saveList)="updateValueList($event, field)" [field]="field" *ngIf="field.fieldType === 'list multiple'" [ngClass]="!selectedLanguage?.isBasic ? 'offset-1 col-12' : 'col-12'"></app-edit-list-multiple>
    
                                    <!-- Fieldset -->
                                    <app-edit-fieldset [disabled]="disabled" [field]="field" *ngIf="field.fieldType === 'fieldset'"></app-edit-fieldset>
    
                                    <!-- Product Link -->
                                    <app-edit-product-link [disabled]="disabled || !selectedLanguage?.isBasic" [field]="field" *ngIf="field.fieldType === 'productLink'"></app-edit-product-link>
    
                                    <!-- Color -->
                                    <app-color-picker-maestro
                                        [disabled]="diasbled || !selectedLanguage?.isBasic"
                                        *ngIf="field.fieldType === 'colors'"
                                        (colorSelect)="updateValue($event, field)"
                                        [value]="field.value" [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}"></app-color-picker-maestro>
    
                                     <!-- Table -->
                                    <app-edit-table *ngIf="field.fieldType === 'table'" [isInput]="true" [field]="field" (_saveValue)="_saveValue($event)"></app-edit-table>
                                </div>
                            </div>
                            <div class="col-1" *ngIf="field.fieldType !== 'productLink' && field.fieldType !== 'fieldset'">
                                <svg *ngIf="field.valueProjectId && !field.isLoading" (click)="emitHistory(field.valueProjectId)" style="width: 1.1rem; height: 1.1rem" class="mt-2 pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                                <div *ngIf="field.isLoading" class="spinner-border mx-auto text-primary spinner-border-sm align-middle" style="vertical-align: -webkit-baseline-middle !important;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="col-11 my" *ngIf="!selectedLanguage.isBasic && ['text', 'textarea', 'number'].includes(field.fieldType) && field.translatable">
                                <div class="row ml-1">
            
                                    <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="basicLanguage?.hasOwnProperty('flag')">
                                        <img [src]="basicLanguage?.flag" />
                                    </span>
            
                                    <input *ngIf="['text', 'number'].includes(field.fieldType)" 
                                    [value]="field.value"
                                    class="form-control col-11" 
                                    [disabled]="true"/>
            
                                    <app-text-editor *ngIf="field.fieldType == 'textarea'" [disabled]="true" class="col-11">{{field.value}}</app-text-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </ng-container>
</div>
