<div class="card">
    <div class="card-body">
        <h3 class="text-primary text-center">{{ 'general.listOf' | translate }} {{ tableTitle }}</h3>
        <div *ngIf="selectRows" class="col text-right">
            <button id="cart" class="btn btn-maestro-yellow" data-toggle="modal" data-target="#cartModal"
                [disabled]="!_selectedRows.length">
                {{ "datatable.selection" | translate }}
                <span *ngIf="_selectedRows.length"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                    _selectedRows.length }}</span>
            </button>
        </div>
        <hr />
        <!-- For csv export, reorderable columns and global filtering without the globalFilterFields property -->
        <p-table #dt [columns]="_selectedColumns" [value]="tableRows" styleClass="p-datatable-striped"
            [paginator]="paginator" [rows]="rows" [showCurrentPageReport]="showCurrentPageReport"
            [currentPageReportTemplate]="'datatable.info' | translate" [rowsPerPageOptions]="rowsPerPageOptions"
            [lazy]="true" (onLazyLoad)="preLoad(); lazyEvent.emit($event)" [totalRecords]="recordsFiltered"
            [globalFilterFields]="globalFilterFields" [scrollable]="scrollable" [dataKey]="dataKey"
            [(selection)]="_selectedRows" [exportFilename]="exportFilename" [defaultSortOrder]="defaultSortOrder" [sortOrder]="sortOrder"
            [(first)]="first">
            <!--
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            (onHeaderCheckboxToggle)="headerCheckboxToggle($event)"
            -->

            <ng-template pTemplate="caption">
                <div *ngIf="showAdvancedSearch" class="row">
                    <div class="col row justify-content-center">
                        <label class="col-form-label text-center">{{ 'datatable.search' | translate }} {{ 'datatable.searchData.strictMode' | translate }}</label>
                        <div class="text-right ml-2 mt-1">
                            <label class="switch switch-label switch-outline-primary">
                                <input class="switch-input" type="checkbox" [checked]="strictMode" (change)="changeAdvancedSearchParameter('strictMode')"/>
                                <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col row justify-content-center">
                        <label class="col-form-label text-center ">{{ 'datatable.search' | translate }} {{ 'datatable.searchData.fieldValue' | translate }}</label>
                        <div class="text-right ml-2 mt-1">
                            <label class="switch switch-label switch-outline-primary">
                                <input class="switch-input" type="checkbox" [checked]="searchOnFieldValue" (change)="changeAdvancedSearchParameter('searchOnFieldValue')"/>
                                <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="'project' !== searchDataParameter" class="col row justify-content-center">
                        <label class="col-form-label text-center ">{{ 'datatable.search' | translate }} {{ 'datatable.searchData.media' | translate }}</label>
                        <div class="text-right ml-2 mt-1">
                            <label class="switch switch-label switch-outline-primary">
                                <input class="switch-input" type="checkbox" [checked]="searchOnMedia" (change)="changeAdvancedSearchParameter('searchOnMedia')"/>
                                <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </div>
                    </div>
                    <div *ngIf="'project' === searchDataParameter" class="col row justify-content-center">
                        <label class="col-form-label text-center ">{{ 'datatable.search' | translate }} {{ 'datatable.searchData.project' | translate }} {{ 'datatable.searchData.archived' | translate }}</label>
                        <div class="text-right ml-2 mt-1">
                            <label class="switch switch-label switch-outline-primary">
                                <input class="switch-input" type="checkbox" [checked]="searchOnArchived" (change)="changeAdvancedSearchParameter('searchOnArchived')"/>
                                <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="searchBar" class="d-md-flex w-full ">
                    <!-- Search bar -->
                    <div  class="w-full">
                        <span class="input-group-lg p-input-icon-right ml-auto w-full">
                            <i class="pi pi-search"></i>
                            <input #search pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                   class="w-full form-control"
                                   placeholder="{{ 'datatable.search' | translate }} {{ 'datatable.searchData.' + searchDataParameter | translate }}" />
                        </span>
                    </div>
                </div>
                <div class="row mt-2">
                    <!-- Toggleable columns -->
                    <div class="col-sm-4" *ngIf="selectColumns">
                        <p-multiSelect [options]="tableColumns" [(ngModel)]="_selectedColumns" ngDefaultControl
                            optionLabel="header" selectedItemsLabel="{0} {{ 'datatable.selectedColumns' | translate }}"
                            [style]="{minWidth: '200px'}" placeholder="{{ 'datatable.selectColumns' | translate }}" class="text-muted">>
                        </p-multiSelect>
                    </div>

                    <!-- Short Paginator -->
                    <div class="col-sm-4 text-center">
                        <div *ngIf="paginator" class="btn-group btn-group-md">
                            <button class="btn"
                                    [ngClass]="shortPaginatorIsFirstPage ? 'btn-outline-secondary' : 'btn-outline-primary'"
                                    (click)="shortPaginatorPrev.emit()" [disabled]="shortPaginatorIsFirstPage"
                                    pTooltip="{{ 'general.previous' | translate }}" tooltipPosition="bottom"
                                    tooltipZIndex="{{ tooltipZIndex }}"> <i class="fa fa-chevron-left"></i>
                            </button>
                            <button class="btn"
                                    [ngClass]="shortPaginatorIsFirstPage ? 'btn-outline-secondary' : 'btn-outline-primary'"
                                    (click)="shortPaginatorReset.emit()" [disabled]="shortPaginatorIsFirstPage"
                                    pTooltip="{{ 'general.reset' | translate }}" tooltipPosition="bottom"
                                    tooltipZIndex="{{ tooltipZIndex }}"> <i class="fas fa-sync"></i>
                            </button>
                            <button class="btn"
                                    [ngClass]="shortPaginatorIsLastPage ? 'btn-outline-secondary' : 'btn-outline-primary'"
                                    (click)="shortPaginatorNext.emit()" [disabled]="shortPaginatorIsLastPage"
                                    pTooltip="{{ 'general.next' | translate }}" tooltipPosition="bottom"
                                    tooltipZIndex="{{ tooltipZIndex }}"> <i class="fa fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-4 text-right">

                        <!-- Clear filters -->
                        <button type="button" class="inline btn btn-outline-danger"
                                (click)="nodeSelected.emit(false); clearTable(dt)"><i class="pi pi-filter-slash"></i><span class="ml-2">{{
                            'general.clear' | translate }}</span></button>
                    </div>
                </div>
                <div class="row mt-2">

                    <div class="col-md-12 text-center">
                        <!-- Export buttons -->
                        <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
                        
                        <div class="inline" *ngIf="selectRows && _selectedRows.length && _selectedColumns.length && filters && filters['filterTags'] && 'element' === searchDataParameter">
                            <!-- Mass Tag Action -->
                            <button id="massTag" type="button" data-toggle="modal" data-target="#appTreeTagModal"
                                    (click)="massTagOpen()" class="btn btn-secondary text-white"
                                    pTooltip="{{ 'massAction.object.tag' | translate }} {{ 'massAction.mass' | translate }}"
                                    tooltipPosition="bottom" tooltipZIndex="{{ tooltipZIndex }}"
                                    [disabled]="!filters['filterTags'] || !filters['filterTags'].data.length">
                                <i class=" pi pi-tags"></i>
                                <span class="ml-2">{{ 'massAction.object.tag' | translate }} {{ 'massAction.mass' | translate }}</span>
                            </button>

                            <!-- Mass Update Action -->
                            <button id="massUpdateButton" class="btn btn-primary ml-1 mr-5" type="button"
                                    pTooltip="{{ 'massAction.object.update' | translate }} {{ 'massAction.mass' | translate }}"
                                    tooltipPosition="bottom" tooltipZIndex="{{ tooltipZIndex }}" (click)="openMassUpdate()"> <!--[disabled]="" data-toggle="modal" data-target="#massUpdate"-->
                                <i class="pi pi-pencil"></i>
                                <span class="ml-2">{{ 'massAction.object.update' | translate }} {{ 'massAction.mass' | translate }}</span>
                            </button>

                            <button type="button" (click)="clearSelection()" class="inline btn btn-outline-danger">
                                <i class="pi pi-check-square"></i>
                                <span class="ml-2">{{'datatable.clearSelection' | translate }}</span>
                            </button>

                            <button type="button" (click)="removeSelection()" class="inline btn btn-danger ml-1">
                                <i class="pi pi-exclamation-triangle"></i>
                                <span class="ml-2">{{'datatable.removeSelection' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>

            </ng-template>
            <!-- Scrollable tables require a column group to support resizing (removed from v12). Used to avoid a decal between columns -->
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col *ngIf="exportRows && selectRows" style="width: 1rem">
                    <col [style]="{'visibility': expandableRow ? 'visible' : 'hidden' }" style="width: 2rem">
                    <col *ngFor="let col of columns" [ngStyle]="{'width': colWidth}">
                </colgroup>
            </ng-template>
            <!-- Columns -->
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="_selectedColumns.length">
                    <th *ngIf="exportRows && selectRows" style="width: 1rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th [style]="{'visibility': expandableRow ? 'visible' : 'hidden' }" style="width: 2rem"></th>
                    <th *ngFor="let col of columns" pSortableColumn="{{ col.sortField }}">
                        <div [ngClass]="{ 'action-right': 'actions' === col.field }">
                            <span> {{ 'table.' + col.field | translate }}
                            </span>
                            <br>
                            <i *ngIf="!col.sortField" class="pi pi-sort-alt-slash"
                                style="vertical-align: middle; margin-left: 0.5rem">
                            </i>
                            <p-sortIcon *ngIf="col.sortField" field="{{ col.sortField }}"></p-sortIcon>

                            <p-columnFilter *ngIf="filters && filters.hasOwnProperty(col.filterList)"
                                field="{{ col.filterList }}" matchMode="in" display="menu" [showMatchModes]="false"
                                [showOperator]="false" [showAddButton]="false" [showApplyButton]="false"
                                [showClearButton]="false">
                                <!-- @TODO: remove [showClearButton]="false" when find why on clear button click content tree still selected (linked to selectUnselectAll() in .ts) -->
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="text-bold">{{ 'table.' + col.field | translate }}</span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <!-- multiSelectFilter($event, col.filterList) -->
                                    <div *ngIf="!filters[col.filterList] || !filters[col.filterList].cascadeSelect" class="row" >
                                        <p-multiSelect class="col-8"
                                            [(ngModel)]="nodes[col.filterList]" [options]="filters[col.filterList].data"
                                            optionLabel="name" (onChange)="checkSelection($event); eventValue = $event.value"
                                            [selectionLimit]="'validity' === col.field ? 1: null">
                                            <ng-template let-option pTemplate="item">
                                                <div class="multiselect-representative-option">
                                                    <span class="ml-1">{{option.name}}</span>
                                                </div>
                                            </ng-template>
                                        </p-multiSelect>
                                        <div *ngIf="filters[col.filterList] && !filters[col.filterList].cascadeSelect" class="col-4 pt-0 mt-1 d-md-flex justify-content-center d-sm-flex-column">
                                            <button [disabled]="null == eventValue || !nodes[col.filterList]" class="col btn btn-primary" (click)="filter(eventValue); eventValue = null">{{ 'general.Tovalidate' | translate}}</button>
                                        </div>
                                    </div>

                                    <div *ngIf="filters[col.filterList] && filters[col.filterList].cascadeSelect">
                                        <div class="mb-1 d-md-flex justify-content-center d-sm-flex-column">
                                            <button *ngIf="filters[col.filterList].data.length" type="button" class="btn btn-primary mr-1"
                                                (click)="_treeService.expandCollapseAll(col.filterList, filters[col.filterList].data)">
                                                <span *ngIf="_treeService.nodesState[col.filterList + 'Expanded']">{{
                                                    'datatable.collapse'
                                                    |
                                                    translate }}</span>
                                                <span *ngIf="!_treeService.nodesState[col.filterList + 'Expanded']">{{
                                                    'datatable.expand' |
                                                    translate }}</span>
                                            </button>
                                            <!-- @TODO: See selectUnselectAll() in .ts -->
                                            <!--<button type="button" class="btn btn-info"
                                                (click)="selectUnselectAll(col.filterList); filter(changeNodesReference(nodes[col.filterList]))">
                                                <span *ngIf="nodesState[col.filterList + 'Selected']">{{
                                                    'datatable.unselectAll'
                                                    |
                                                    translate }}</span>
                                                <span *ngIf="!nodesState[col.filterList + 'Selected']">{{
                                                    'datatable.selectAll' |
                                                    translate }}</span>
                                            </button>-->
                                        </div>
                                        <p-tree [value]="filters[col.filterList].data" selectionMode="checkbox"
                                            [(selection)]="nodeArray" [filter]="true" filterMode="strict"
                                            [propagateSelectionUp]="false" [propagateSelectionDown]="false"
                                            scrollHeight="300px"
                                            (onNodeSelect)="nodeSelected.emit(true); treeSelectFilter($event, true, col.filterList);"
                                            (onNodeUnselect)="nodeSelected.emit(false); treeSelectFilter($event, false, col.filterList);">
                                        </p-tree>
                                        <div class="mt-1 d-md-flex justify-content-center d-sm-flex-column">
                                            <button class="col btn btn-primary" (click)="filter(changeNodesReference(nodes[col.filterList]))">{{ 'general.Tovalidate' | translate}}</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <!-- Rows -->
            <ng-template pTemplate="body" let-row let-columns="columns" let-expanded="expanded" let-rowIndex="rowIndex">
                <tr *ngIf="_selectedColumns.length">
                    <td *ngIf="exportRows && selectRows" class="pt-2">
                        <p-tableCheckbox [value]="row"></p-tableCheckbox>
                    </td>
                    <td [style]="{'visibility': expandableRow ? 'visible' : 'hidden' }">
                        <!-- @TODO : Fix button's position when Toggleable columns is displayed and few columns are selected -->
                        <button type="button" pRipple [pRowToggler]="row" class="btn mb-1 mx-2 p-0"
                            [ngClass]="expanded && !reloadRowExpansion ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"
                            (click)="onExpandAction(!expanded, row.id)">
                        </button>
                    </td>
                    <td *ngFor="let col of columns">
                        <div [ngClass]="{ 'action-right': 'actions' === col.field }">
                            <!-- Standard row case-->
                            <span
                                *ngIf="'validity' !== col.field && 'tags' !== col.field && actions !== col.field && 'groups' !== col.field &&(linkColName !== col.field || affLink != 'true') && 'workflow' !== col.field && 'step' !== col.field" >
                                {{ controlValue(row[col.field]) }}
                            </span>

                            <div *ngIf="linkColName === col.field && affLink == 'true'">
                                <a href="{{ baseLink + row.id }}">{{ row[col.field] }}</a>
                            </div>

                            <!-- Tag & Group row case -->
                            <div *ngIf="'tags' === col.field || 'groups' === col.field">
                                <span *ngFor="let data of row[col.field]" class="badge badge-pill badge-primary mr-1">{{ data
                                    }}</span>
                            </div>

                            <div *ngIf="'validity' === col.field">
                                <span *ngIf="controlValue(row[col.field]) && ((tagAsSalabilityIndicator && row['tags'] && row['tags'].length > 0) || !tagAsSalabilityIndicator)">
                                    {{ 'general.table.validIndicator' | translate }}
                                </span>
                                <span *ngIf="controlValue(row[col.field]) && tagAsSalabilityIndicator && (!row['tags'] || row['tags'].length <= 0)">
                                    {{ 'general.table.noValidIndicator' | translate }}
                                </span>
                                <span *ngIf="!controlValue(row[col.field])">{{ 'general.table.noValidIndicator' | translate }}</span>
                            </div>

                            <span *ngIf="'workflow' === col.field">
                                {{ controlValue(row[col.field]) }} 
                                <div *ngIf="row['step'] && row['step'].length  > 0" class="languageTooltip" ><i class="bi bi-flag"></i>
                                    <span class="tooltiptext row" style="width: 200px; z-index: 10;">
                                        <div class="pl-0 ml-0" *ngFor="let stepLanguage of row['step']" >
                                            <span class="pl-0 ml-0">{{ stepLanguage['name'] }}</span>
                                            <ul class="ml-2">
                                                <li class="row" *ngFor="let language of stepLanguage['language']"><span class="col-8 pl-0 pt-0">{{language.name}}</span>  <fa-icon class="col-1 pt-0" [icon]="language.valid ? faCheck : faTimes"></fa-icon></li>
                                            </ul>
                                        </div>   
                                    </span>
                                </div>
                            </span>
                            <!-- Action row case -->
                            <div *ngIf="'actions' === col.field">
                                <app-action-buttons [groupRight]="row.groupRight" [actions]="actions" (action)="onActionClick($event, row.id)"
                                    [hideViewIfEditAllowed]="hideViewButtonIfEditAllowed" buttonOutlined="true">
                                </app-action-buttons>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!_selectedColumns.length && rowIndex == 0" class="bg-danger">
                    <td [attr.colspan]="tableColumns.length + 2" class="text-left">{{ 'datatable.emptyColumn' | translate }}
                    </td>
                </tr>
            </ng-template>
            <!-- RowExpand (Use "tableColumns.length + 2" since select box and row expand button not added in count)-->
            <ng-template *ngIf="expandableRow" pTemplate="rowexpansion" let-row>
                <!-- @TODO : Fix the fact that after expand a row then reload data it's necessary to click 2 times to expand again if the row wasn't closed before reload -->
                <tr *ngIf="!reloadRowExpansion">
                    <td [attr.colspan]="tableColumns.length + 2">
                        <div *ngIf="subData[row.id] && 0 === subData[row.id].length" class="text-left">
                            {{ 'datatable.emptySubData' | translate }}
                        </div>
                        <div *ngIf="subData[row.id] && subData[row.id].length > 0">
                            <div *ngIf="subDataType === 'medias'; else elseBlock" class="card-deck">
                                <!-- @TODO: Find a class to set max value + see for min -->
                                <div *ngFor=" let sub of subData[row.id]" class="card"
                                    style="max-width: 100px; max-height: 100px;">
                                    <img [src]="sub.thumb ? 'data:image/' + sub.extension + ';base64,' + sub.thumb : sub.thumbPath ? damUrl+sub.thumbPath : sub.link"
                                        class="maestro-background-checkerboard shadow" alt="{{ sub.name }}"
                                        pTooltip="{{sub.name ? sub.name : sub.link}}" tooltipPosition="bottom"
                                        tooltipZIndex="{{tooltipZIndex}}" style="max-height: 98px; object-fit: contain" (error)="_thumbnailsService.setDefaultImage($event)"/>
                                </div>
                            </div>
                            <div *ngIf="subData[row.id].length" class="text-left mt-1">
                                <a *ngIf="affLink == 'true'" href="{{ baseLink + row.id }}">{{ 'pim.element.moreInProduct' | translate }}</a>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template #elseBlock>
                <tr>
                    <td [attr.colspan]="tableColumns.length + 2">{{ 'datatable.invalid.dataTypeStructure' | translate }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="tableColumns.length + 2" class="text-left">{{ 'datatable.emptyTable' | translate
                        }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<!-- Template Mass Buttons -->
<ng-template #exportButtons>
    <div class="inline" *ngIf="exportRows && selectRows && _selectedRows.length && _selectedColumns.length && 'element' === searchDataParameter">
        <!--<button type="button" pButton pRipple
            (click)="prepareCSVExport(); dt.exportCSV({selectionOnly:true}); rollbackCSVData()"
            class="btn btn-info text-white mr-1 " pTooltip="CSV" tooltipPosition="bottom"
            tooltipZIndex="{{tooltipZIndex}}"><i class="pi pi-file-o"></i><span class="ml-2">{{
                'datatable.export.csv' | translate }}</span></button>-->
        <!--<button type="button" pButton pRipple (click)="exportExcel()" class="btn btn-success text-white mr-1 "
            pTooltip="XLS" tooltipPosition="bottom" tooltipZIndex="{{tooltipZIndex}}"><i class="pi pi-file-excel"></i><span
                 class="ml-2">{{ 'datatable.export.xls' | translate
                }}</span></button>-->
        <button type="button" pButton pRipple (click)="exportPdf()" class="btn btn-warning text-white mr-2"
            pTooltip="PDF" tooltipPosition="bottom" tooltipZIndex="{{tooltipZIndex}}"><i class="pi pi-file-pdf"></i><span
                 class="ml-2">{{ 'datatable.export.pdf' | translate
                }}</span></button>

        <!--<span>{{ 'datatable.selectedRowsNumber' | translate }} : {{ _selectedRows.length }}</span>-->
    </div>
    <div class="inline" *ngIf="exportRows && selectRows && _selectedRows.length && _selectedColumns.length && 'project' === searchDataParameter">
        <button type="button" pButton pRipple
            (click)="getIdsForArchive()"
            class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
                'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveBd' | translate }}</span></button>
        <button type="button" pButton pRipple
        (click)="getIdsForArchive(true)"
        class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
            'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveHd' | translate }}</span></button>
    </div>
</ng-template>

<!-- Selection Modal -->
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="cartModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cartModalLabel">{{ "datatable.selection" | translate }} : {{
                    _selectedRows.length }} {{ ('project' === searchDataParameter ? 'projects.project' : 'table.product') | translate }}(s)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled">
                    <div *ngIf="_selectedRows.length">
                        <li *ngFor="let row of _selectedRows" class="pb-2 pt-2">
                            <div class="row">
                                <div class="col-8 text-truncate mt-3">
                                    <span *ngIf="row.hasOwnProperty('elementType')">{{ row.elementType }} : </span>
                                    <span class="font-weight-bold">{{ row.name }} </span>
                                </div>
                                <div class="col-4 mt-2 text-right">
                                    <button (click)="removeFromSelection(_selectedRows, row.id)" class="ml-auto btn btn-sm-danger">
                                        <i class="pi pi-minus-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div *ngIf="!_selectedRows.length">
                        {{ "datatable.emptySelection" | translate }}
                    </div>
                </ul>
            </div>
            <div class="modal-footer">
                <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
            </div>
        </div>
    </div>
</div>

<!-- Selection massTag -->
<app-treeTag *ngIf="filters['filterTags']" #treeTag [dataLength]="_selectedRows.length" [value]="filters['filterTags'].data"
    (massTagStart)="massTagSelection($event)"></app-treeTag>

<!-- Mass Update -->
<swal #massUpdate [width]="'95%'" title="{{ 'massAction.object.update' | translate }} {{ 'massAction.mass' | translate }} {{ _selectedRows.length }} {{ 'projects.flatplan.product' | translate }}" confirmButtonText="{{ 'general.update' | translate }}"
    (confirm)="saveMassUpdate()" [showCancelButton]="true"
    cancelButtonText="{{ 'general.cancel' | translate }}" [cancelButtonColor]="'#FF0000'">
    <div *swalPortal class="modal-body">
        <div class="alert alert-info alert-dismissible fade show" role="alert">
            <i class="pi pi-info-circle"></i> {{ 'general.massUpdateContentLength' | translate }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form [formGroup]="massUpdateForm">
            <div formArrayName="models">
              <p-tabView *ngIf="massUpdateData">
                <p-tabPanel *ngFor="let modelGroup of models.controls; let modelIndex=index" header="{{ modelGroup.value.model }}" [formGroupName]="modelIndex">
                  <table class="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th style="border: 0;" class="text-left" scope="col"></th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.value' | translate }}</th>
                        <th style="border: 0;" class="text-center" scope="col">{{ 'general.clearField' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody class="text-left">
                      <ng-container formArrayName="tabs">
                        <ng-container *ngFor="let tabGroup of tabs(modelIndex).controls; let tabIndex=index" [formGroupName]="tabIndex">
                          <tr>
                            <td colspan="3">
                              <span class="font-weight-bold text-primary text-left pl-1 pb-1" style="border-left: solid thin black; border-bottom: solid thin black;">{{ tabGroup.value.tab }} :</span>
                            </td>
                          </tr>
                          <ng-container formArrayName="sections">
                            <ng-container *ngFor="let sectionGroup of sections(modelIndex, tabIndex).controls; let sectionIndex=index" [formGroupName]="sectionIndex">
                              <tr>
                                <td colspan="3">
                                  &nbsp;&nbsp;&nbsp;&nbsp;<span class="font-italic text-primary text-left" style="border-bottom: solid thin black;">{{ sectionGroup.value.section }} :</span>
                                </td>
                              </tr>
                              <ng-container formArrayName="fields">
                                <ng-container *ngFor="let fieldGroup of fields(modelIndex, tabIndex, sectionIndex).controls; let fieldIndex=index" [formGroupName]="fieldIndex">
                                  <tr *ngIf="fieldGroup.value.fieldType !== 'fieldset'">
                                    <td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ fieldGroup.value.name }}</span><span *ngIf="fieldGroup.value.fieldType === 'list' || fieldGroup.value.fieldType === 'media'"><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>{{ fieldGroup.value.multiple ? '(Multiple)' : '(Simple)' }}</small></span>
                                    </td>
                                    <td class="text-center">
                                        <!-- Text & Number-->
                                        <input *ngIf="fieldGroup.value.fieldType === 'text' || fieldGroup.value.fieldType === 'number'" formControlName="value" class="form-control"
                                            type="{{ fieldGroup.value.fieldType }}" step="0.01">

                                        <!-- Textarea -->
                                        <app-text-editor *ngIf="fieldGroup.value.fieldType === 'textarea'" formControlName="value"></app-text-editor>
                                        
                                        <!-- Date -->
                                        <app-date-picker *ngIf="fieldGroup.value.fieldType === 'date'" formControlName="value" [active]="true" [minDate]="{ year: 1930, month: 1, day: 1 }"></app-date-picker>

                                        <!-- Checkbox -->
                                        <label *ngIf="fieldGroup.value.fieldType === 'checkbox'" class="switch switch-pill switch-primary">
                                            <input type="checkbox"
                                                class="switch-input form-control" formControlName="value">
                                            <span class="switch-slider"></span>
                                        </label>

                                        <!-- Select -->
                                        <!--
                                        <select *ngIf="fieldGroup.value.fieldType === 'list' && !fieldGroup.value.multiple" class="form-control">
                                            <option></option>
                                            <option *ngFor="let value of fieldGroup.value.selectable" [value]="value.id">{{ value.name }}</option>
                                        </select>
                                        -->
                                        <p-multiSelect *ngIf="fieldGroup.value.fieldType === 'list' && !fieldGroup.value.multiple"
                                            formControlName="value"
                                            selectionLimit="1"
                                            placeholder="{{ 'general.simpleChoice' | translate }}"
                                            [options]="fieldGroup.value.selectable"
                                            optionLabel="name"
                                            [style]="{'width':'100%'}">
                                        </p-multiSelect>

                                        <!-- Multiselect -->
                                        <p-multiSelect *ngIf="(fieldGroup.value.fieldType === 'list' && fieldGroup.value.multiple) || fieldGroup.value.fieldType === 'productLink'"
                                            formControlName="value"
                                            placeholder="{{ 'general.multipleChoice' | translate }}"
                                            [options]="fieldGroup.value.selectable"
                                            optionLabel="name"
                                            [style]="{'width':'100%'}"
                                            [maxSelectedLabels]="3"
                                            selectedItemsLabel="{0} {{'general.selectedItem' | translate}}">
                                        </p-multiSelect>

                                        <!-- ListCascade -->
                                        <div *ngIf="fieldGroup.value.fieldType === 'listcascade'">
                                            <div class="mb-2 text-left pl-3">
                                                <button [disabled]="fieldGroup.value.clear ? true : false" type="button" class="btn btn-primary" (click)="_dataService.expandCollapse('expand',fieldGroup.value.selectable)">{{ 'datatable.expand' | translate }}</button>
                                                <button [disabled]="fieldGroup.value.clear ? true : false" type="button" class="btn btn-primary ml-2" (click)="_dataService.expandCollapse('collapse',fieldGroup.value.selectable)">{{ 'datatable.collapse' | translate }}</button>
                                            </div>
                                            <div class="col">
                                                <p-tree
                                                    [disabled]="true"
                                                    [value]="fieldGroup.value.selectable"
                                                    selectionMode="checkbox"
                                                    [filter]="true"
                                                    filterMode="strict" 
                                                    [propagateSelectionUp]="true" 
                                                    [propagateSelectionDown]="false"
                                                    (onNodeSelect)="onNodeSelect($event, modelIndex, tabIndex, sectionIndex, fieldIndex)"
                                                    (onNodeUnselect)="onNodeUnselect($event, modelIndex, tabIndex, sectionIndex, fieldIndex)"
                                                    [style.opacity]="fieldGroup.value.clear ? 0.4 : 1"
                                                    [style.pointer-events]="fieldGroup.value.clear ? 'none' : 'auto'">
                                                </p-tree>
                                            </div>
                                        </div>

                                        <!-- Color -->
                                        <app-color-picker-maestro *ngIf="fieldGroup.value.fieldType === 'colors'" formControlName="value"></app-color-picker-maestro>

                                        <!-- Table -->
                                        <ng-container *ngIf="fieldGroup.value.fieldType === 'table'">
                                            <div class="ml-3 tableOverlayPanelContainer">
                                                <div class="row">
                                                    <button [disabled]="fieldGroup.value.clear ? true : false" class="btn btn-primary" #target data-toggle="modal" [attr.data-target]="'#tableModal-' + modelIndex + '-' + tabIndex + '-' + sectionIndex + '-' + fieldIndex">
                                                        {{ 'general.table.configure' | translate }}
                                                    </button>
                                                </div>

                                                <div class="modal fade" [attr.id]="'tableModal-' + modelIndex + '-' + tabIndex + '-' + sectionIndex + '-' + fieldIndex" tabindex="-1" role="dialog" aria-labelledby="tableModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog modal-xl" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="tableModalLabel">
                                                                    {{ 'general.table.configure' | translate }} {{ currentTitle }}
                                                                </h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <app-form-table [id]="sh" formControlName="value"></app-form-table>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-primary" data-dismiss="modal">
                                                                    {{ 'general.close' | translate }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <!-- Media -->
                                        <ng-container *ngIf="fieldGroup.value.fieldType === 'media'">
                                            <!--<app-element-update-media formControlName="value" [singleSelection]="fieldGroup.value.multiple" [forElementMedia]="false"></app-element-update-media>-->
                                            <app-form-media formControlName="value" [id]="'mediaModal-' + modelIndex + '-' + tabIndex + '-' + sectionIndex + '-' + fieldIndex" [disabledImport]="fieldGroup.value.clear ? true : false" [singleSelection]="!fieldGroup.value.multiple"></app-form-media>
                                        </ng-container>

                                        <!-- Fieldset : MEME COMPORTEMENT QUE TABLE ? (Modal, Ajout de collection) -->
                                    </td>
                                    <td class="text-center">
                                      <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                        <input type="checkbox" class="switch-input" formControlName="clear">
                                        <span class="switch-slider"></span>
                                      </label>
                                    </td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </tbody>
                  </table>
                </p-tabPanel>
              </p-tabView>
            </div>
          </form>
    </div>
</swal>
