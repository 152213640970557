import {Component} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-root",
    template: "<router-outlet></router-outlet>",
})
export class AppComponent  {
    constructor(private _translate: TranslateService) {
        this._translate.addLangs(["fr"]);
        this._translate.setDefaultLang("fr");

        const browserLang = this._translate.getBrowserLang();
        this._translate.use(browserLang.match(/fr|en/) ? browserLang : "fr");
    }


}
