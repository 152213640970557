import { NgModule } from '@angular/core';
import { SharedModule } from "app/shared";
import { ModalFormLanguageComponent } from "app/shared/components/modal-form-language/modal-form-language.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [
        ModalFormLanguageComponent
    ],
    imports: [
        SharedModule,
        CommonModule
    ],
    exports: [
        ModalFormLanguageComponent
    ],
    providers:[
    ]
})
export class ModalFormLanguageModule { }
