import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faLink, faPlusCircle, faSort, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { fieldTypes } from "app/shared/models/field-type.enum";
import { Language } from "app/shared/models/language";
import { FieldsetResources } from "app/shared/models/pim/fieldset-resources";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-fieldset-update",
    templateUrl: "./fieldset-update.component.html",
    styleUrls: ["./fieldset-update.component.scss"],
})
export class FieldsetUpdateComponent implements OnInit, OnDestroy {
    fieldset: { id: number; name: string; fields: any; sourceId: number | null; sourceName: string | null };
    fieldsets: any[];
    formLinkInputRef: FormGroup;
    elementTypes: any[];
    inputs: any[];
    listcascades: any[] = [];

    lists: { id: number; name: string }[];

    updateName: boolean;
    nameAtTheOpening: string;

    formArray: FormArray;

    fieldTypes = fieldTypes;
    actualField: number;
    actualSection: FormGroup;

    sectionCurrent: any;

    selectedValue: any;
    isnotValid: boolean;

    @ViewChild("linkInputRef") private linkInputRef: SwalComponent;
    readonly faSave = faSave;
    readonly faSort = faSort;
    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrashAlt;
    readonly faEdit = faEdit;
    readonly faClose = faTimes;
    readonly faLink = faLink;

    /**
     * Sort for fields
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }
            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.formArray.controls, from, to);
            array_move(this.formArray.value, from, to);
            this.formArray.markAsDirty();
        }.bind(this),
    };

    private _ngUnsubscribe: Subject<void>;

    currentControlIndex: number;
    title: string = "";
    currentTable: string = ""; // Table in JSON
    currentSectionForm: FormGroup;
    showInputReferentName: boolean = false;
    showInputReferentProtocol: boolean = false;

    selectedLanguage: Language;
    basicLanguage: Language;
    languagesList: Language[] = [];

    constructor(
        private _fieldsetService: FieldsetService,
        private _elementTypeService: ElementTypeService,
        private _route: ActivatedRoute,
        private _layout: LayoutService,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _spinner: SpinnerService,
        private _fb: FormBuilder,
        private _modal: SwalModalService,
        private cdRef: ChangeDetectorRef,
        private _languageService: LanguageService,
        private _listcascadeService: ListCascadeService
    ) {
        this._ngUnsubscribe = new Subject();
        this.updateName = false;
        this.fieldTypes = this.fieldTypes.sort((a, b) => this._translate.instant("fieldType." + a).localeCompare(this._translate.instant("fieldType." + b)));
    }

    ngOnInit(): void {
        this._fieldsetService.getFieldsets().subscribe((fieldsets) => {
            this.fieldsets = fieldsets["data"];
        });

        this._elementTypeService
            .getElementTypes()
            .pipe(map((r) => (r as any).data))
            .subscribe((elementTypes) => {
                this.elementTypes = elementTypes;
            });

        this._fieldsetService
            .getInputs()
            .pipe(map((r) => (r as any).data))
            .subscribe((inputs) => {
                this.inputs = inputs;
            });
        this._listcascadeService
            .getListCascades()
            .pipe(map((r) => (r as any).data))
            .subscribe((listcascades) => {
                this.listcascades = listcascades;
            });

        this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: FieldsetResources }) => {
            const collator = new Intl.Collator("fr", { numeric: true, sensitivity: "base" });
            this.lists = data.resources.lists.sort((a, b) => collator.compare(a.name, b.name));
            this.fieldset = data.resources.fieldset;

            this._initData();

            this._layout.breadcrumb.setPath({ routerLink: "/pim/fieldset", name: "pim.fieldsets.title" }, 1);
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.fieldset.name }, 2);
        });

        // GET ALL LANGUAGES AND MAKE THEM ON THE SELECT BOX
        this._languageService.getAll().subscribe((languages) => {
            const languageIsVisible = languages.data.filter((language) => language.isVisible);
            this.languagesList = languageIsVisible;
        });

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
            this.changeLang();
        });

        this.basicLanguage = this._languageService.getBasicLanguage();
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.breadcrumb.setPath(null, 2);
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Save fieldset's fields
     */
    saveFields(): void {
        if (this.formArray.invalid || !this.formArray.length) {
            return;
        }

        const basicLanguage = this.basicLanguage;
        const internationalCode = this.selectedLanguage ? this.selectedLanguage.internationalCode : basicLanguage.internationalCode;

        this.formArray.controls.forEach((fieldset: FormGroup) => {
            const nameTranslations = fieldset.get("nameTranslations").value;
            const name = fieldset.get("name").value.trim();;
            nameTranslations[internationalCode] = name;

            fieldset.get("name").setValue(nameTranslations[basicLanguage.internationalCode] ?? name);
            fieldset.get("nameTranslations").setValue(nameTranslations);
        });

        const payload = {
            model: {
                "Contener Field": this.formArray.controls.map((v: FormGroup) => ({
                    FieldId: v.controls.id.value,
                    Nom: v.controls.name.value.trim(),
                    NomTranslations: v.controls.nameTranslations.value,
                    Protocol: v.controls.protocol.value.trim(),
                    "Type de champ": v.controls.fieldType.value,
                    "Liste de liste": v.controls.list.value && v.controls.list.value !== -1 ? this.lists.find((l) => l.id === Number(v.controls.list.value)).name : null,
                    ValueId: -1,
                    Preview: v.controls.preview.value,
                    Multiple: v.controls.multiple.value,
                    Mandatory: v.controls.mandatory.value,
                    SalabilityIndicator: v.controls.salabilityIndicator.value,
                    Translatable: v.controls.translatable.value,
                    DefaultValue: v.controls.defaultValue.value,
                    collectionReferent: v.controls.collectionReferent.value,
                    Propagate: v.controls.propagate.value,
                })),
            },
        };

        this._fieldsetService
            .updateFormField(this.fieldset.id, payload)
            .pipe(map((r: any) => r.data))
            .subscribe((data: any) => {
                this.formArray.markAsPristine();
                this._toaster.show({
                    message: this._translate.instant("general.saved"),
                    type: "success",
                });

                this.fieldset = data;
                this._initData();
            });
    }

    /**
     * Save fieldset's name
     */
    saveName(): void {
        this._spinner.disable();
        this._fieldsetService.changeName(this.fieldset.id, this.fieldset.name).subscribe((_) => {
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.fieldset.name }, 2);
            this.updateName = false;
            this.nameAtTheOpening = this.fieldset.name;
            this._toaster.show({
                message: this._translate.instant("pim.fieldsets.fieldsetNameSaved"),
                type: "success",
            });
            this._spinner.activate();
        });
    }

    /**
     * Add a new field
     */
    addField(): void {
        const formGroup = this._fb.group({
            id: [-1, Validators.required],
            name: ["", [Validators.required]],
            nameTranslations: [{}, []],
            protocol: [""],
            fieldType: ["", Validators.required],
            list: [-1, Validators.required],
            preview: [false],
            multiple: [false],
            mandatory: [false],
            salabilityIndicator: [false],
            translatable: [false],
            propagate: [false],
            defaultValue: [-1, Validators.required],
            collectionReferent: [null],
        });

        formGroup.controls.fieldType.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
            const control = formGroup.controls.list;
            const controlDefaultValue = formGroup.controls.defaultValue;
            value === "list" ? control.patchValue("") : control.patchValue(-1);
            ["fieldset", "productLink", "listcascade"].includes(value) ? controlDefaultValue.patchValue("") : controlDefaultValue.patchValue(-1);
        });

        this.formArray.push(formGroup);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }

    /**
     * Delete a field
     *
     * @param index
     */
    deleteField(index: number): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                this.formArray.removeAt(index);
                this.saveFields();
            }
        });
    }

    /**
     * Control that the procotol & name is unique
     * @returns
     */
    uniqueValues(): ValidatorFn {
        return (formArray: FormArray): null => {
            const protocols = formArray.value.map((v) => v.protocol.toLowerCase());
            const names = formArray.value.map((v) => v.name.toLowerCase());

            formArray.controls.forEach((formGroup: FormGroup) => {
                const control = formGroup.controls.protocol;
                let a = protocols.filter((v: string) => v.trim() === control.value.trim().toLowerCase()).length > 1;
                let b = names.filter((v: string) => v.trim() === control.value.trim().toLowerCase()).length > 1;

                if ((a || b) && control.value) {
                    control.setErrors({ uniqueValue: true });
                } else {
                    const errors = control.errors;
                    if (errors && errors.uniqueValue) {
                        delete errors.uniqueValue;
                    }
                    if (errors && Object.keys(errors).length === 0) {
                        control.setErrors(null);
                    } else {
                        control.setErrors(errors);
                    }
                }
            });
            return null;
        };
    }

    /**
     * Init the data
     */
    private _initData(): void {
        this.nameAtTheOpening = this.fieldset.name;

        this.formArray = this._fb.array([], this.uniqueValues());

        this.fieldset.fields.forEach((f) => {
            const translatedValue = this.selectedLanguage ? f.nameTranslations[this.selectedLanguage.internationalCode] : f.name;
            this.formArray.push(
                this._fb.group({
                    id: [f.id, Validators.required],
                    name: [translatedValue, [Validators.required]],
                    nameTranslations: [f.nameTranslations, []],
                    protocol: [f.protocol || ""],
                    fieldType: [f.fieldType, Validators.required],
                    list: [{ value: f.listId, disabled: true }],
                    preview: [f.preview || false],
                    multiple: [f.multiple || false],
                    mandatory: [f.mandatory || false],
                    salabilityIndicator: [f.salabilityIndicator || false],
                    translatable: [f.translatable || false],
                    propagate: [f.propagate || false],
                    defaultValue: [{ value: f.defaultValue || "", disabled: true }],
                    collectionReferent: [f.collectionReferent || null],
                })
            );
        });
    }

    /**
     * Configure a table field
     *
     * @param sectionForm
     * @param controlIndex
     */
    openConfigureTableModal(sectionForm: FormGroup, controlIndex: number) {
        this.currentSectionForm = sectionForm;
        this.currentControlIndex = controlIndex;
        this.title = this._translate.instant("pim.elementTypes.configureTable") + " : " + this.currentSectionForm.value.name;
        this.currentTable = this.currentSectionForm.value.defaultValue;
        this.cdRef.detectChanges();
    }

    setCells(cells: any) {
        if (this.currentSectionForm) {
            let defaultValue = this.currentSectionForm.controls.defaultValue;
            defaultValue.setValue(cells);
            defaultValue.markAsDirty(); // Enabled save button
            this.currentTable = cells;
            this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
        }
    }

    getInputReferent(controlId: number, key: string) {
        const input = this.fieldset.fields.find((i) => i.id == controlId);
        let referent = "";

        if (undefined !== input) {
            if ("name" === key && null !== input.inputSourceName) {
                referent = "(" + this._translate.instant("pim.fieldsets.referentFrom") + ` : ${input.inputSourceName})`;
            } else if ("protocol" === key && null !== input.inputSourceProtocol) {
                referent = "(" + this._translate.instant("pim.fieldsets.referentFrom") + ` : ${input.inputSourceProtocol})`;
            }
        }

        return referent;
    }

    openLinkInputRef(sectionForm: FormGroup, controlIndex: number) {
        this.sectionCurrent = sectionForm.value[controlIndex].collectionReferent;

        this.formLinkInputRef = this._fb.group({
            input: [sectionForm.value[controlIndex].collectionReferent],
        });

        this.actualField = controlIndex;
        this.actualSection = sectionForm;
        this.linkInputRef.fire().then((result) => {
            sectionForm.controls[controlIndex]["controls"].collectionReferent.setValue(this.formLinkInputRef.value.input);
            sectionForm.controls[controlIndex]["controls"].collectionReferent.markAsDirty();
        });
    }

    onSelectChange(event: any) {
        // Récupérer la valeur sélectionnée
        this.selectedValue = event.target.value;

        if (this.selectedValue === "null") {
            this.isnotValid = true;
        } else {
            this.isnotValid = false;
        }
    }

    isNotValidTooltip() {
        const selectedValueInput = this.formLinkInputRef?.get("input").value;
        if (this.isnotValid || selectedValueInput === null || selectedValueInput?.length === 0) {
            return this._translate.instant("pim.fieldsets.mandatorySelectReference");
        } else {
            return;
        }
    }

    getInputRefName(fieldsetId, inputId) {
        if (this.inputs === undefined) {
            return "";
        }

        const ref = this.inputs[fieldsetId].find((input) => input.id == inputId);
        return ref ? ref.name : "";
    }

    /**
     * Save name of the data model
     */
    loadData(event) {
        const selectedLanguage: Language = event.value;

        // Utilisez la fonction pour mettre à jour la langue sélectionnée
        this._languageService.setSelectedLanguage(selectedLanguage);
    }

    changeLang() {
        if (!this.selectedLanguage) {
            return;
        }
        this.formArray.controls.forEach((option) => {
            const nameTranslations = option.get("nameTranslations").value || {};
            this.languagesList.forEach((language) => {
                if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                    nameTranslations[language.internationalCode] = option.get("name").value;
                }
            });

            const translatedValue = nameTranslations[this.selectedLanguage.internationalCode];

            if (undefined !== translatedValue) {
                option.get("name").setValue(translatedValue);
            }
        });
    }

    getBasicLanguage(): Language {
        const basicLanguage = this.languagesList.find((language) => language.isBasic);

        if (basicLanguage) {
            return basicLanguage;
        }

        // Si aucune langue de base n'est trouvée, retourner une valeur par défaut
        return {
            id: 0,
            label: "Français",
            icon: "",
            code: "fr",
            internationalCode: "fr_FR",
            isVisible: true,
            isBasic: true,
        };
    }
}
