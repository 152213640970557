<div class="table-responsive overflow-visible"> <!-- overflow-hidden will hide color field selector ... -->
    <table class="table-striped table-bordered" style="width: 94%; margin-left: 3%;">
        <tbody>
            <tr *ngFor="let field of product.values; let indexValue = index" class="row" style="height:auto;">
                <td style="width:25%; padding: 0 1.5em; ">
                    <div class="row">
                        <div class="col-12">
                            <span style="text-transform: capitalize;" class=" text-muted ">
                                {{ field.key }}
                            </span>
                        </div>
                    </div>
                </td>
                <td style="width:75%;padding: 0 1.5em;">
                    <div class="row d-flex  flex-nowrap ">
                        <div [ngClass]="inputId != field.variableProjectId ? 'col-10' : 'col-12'">
                            <span>

                                <span class="underline pointer" [innerHTML]="changePToBr(field)"
                                    *ngIf="inputId !== field.variableProjectId && field.fieldType !='fieldset' && field.fieldType !='table' && field.fieldType !='checkbox' && field.fieldType !='colors' && field.fieldType != 'media' && canUpdate(field) === false && !diplayEmptyTextarea(field)"
                                    (click)="displayInput(field.variableProjectId, field);"></span>

                                <!-- Media -->
                                <span class="underline pointer" (click)="displayInput(field.variableProjectId, field)"
                                    *ngIf="inputId !== field.variableProjectId && field.fieldType === 'media' && null !== field.value && field.value.length && canUpdate(field) === false">
                                     {{ 'general.configureMedias' | translate }}
                                </span>

                                <!-- Checkbox -->
                                <div id="field-{{field.variableProjectId}}" *ngIf="field.fieldType === 'checkbox'">
                                    <div class="col-10 container">
                                        <label class="switch switch-pill switch-primary row">
                                            <input id="" [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                                   (change)="updateValue($event.target.checked, field)" type="checkbox"
                                                   class="switch-input" [checked]="field.value === '1'"
                                                   value="{{field.value}}">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>

                                <!-- Color -->
                                <app-color-picker-maestro id="field-{{field.variableProjectId}}"
                                                          (colorSelect)="updateValue($event, field)"
                                                          *ngIf="field.fieldType === 'colors' && field.editable === true"
                                                          [value]="field.value" [disabled]="canUpdate(field)"></app-color-picker-maestro>

                                <span class="underline pointer" (click)="displayInput(field.variableProjectId,field)"
                                      *ngIf="inputId !== field.variableProjectId && field.fieldType === 'fieldset' && field.value.length !== 0 && canUpdate(field) === false">
                                    {{ "pim.element.fieldset" | translate }}
                                </span>
                                <span class="underline-red pointer"
                                      (click)="displayInput(field.variableProjectId,field)"
                                      *ngIf="field.fieldType === 'fieldset' && field.value.length === 0 || null"
                                      [ngClass]="{'not-allowed' : canUpdate(field) === true}">
                                    {{ "pim.element.fieldsetEmpty"| translate }}
                                </span>
                                <span class="underline-red pointer"
                                      (click)="displayInput(field.variableProjectId,field)"
                                      *ngIf="field.fieldType === 'productLink' && inputId != field.variableProjectId && field.value.length === 0 || null"
                                      [ngClass]="{'not-allowed' : canUpdate(field) === true}">
                                    {{ "pim.element.productLinkEmpty"| translate }}
                                </span>

                                <span class="underline-red pointer" *ngIf="field.value == null && inputId !== field.variableProjectId && field.fieldType != 'checkbox' && field.fieldType != 'table' && field.fieldType != 'colors'
                                      && !(diplayEmptyTextarea(field) && field.fieldType === 'textarea')"
                                      (click)="displayInput(field.variableProjectId, field, field)">
                                    <span [ngClass]="{'not-allowed': canUpdate(field) === true}">
                                        {{ "pim.element.empty" | translate }}
                                    </span>
                                </span>
                                <span class="underline-red pointer"
                                      *ngIf=" diplayEmptyTextarea(field) && field.fieldType === 'textarea'"
                                      (click)="displayInput(field.variableProjectId,field)">
                                    {{ "pim.element.empty" | translate }}
                                </span>

                                <span class="underline-red pointer"
                                      *ngIf="field.fieldType === 'list' && inputId !== field.variableProjectId && (field.value.length <=0 || ['',null,undefined].includes(field.value[0]) || field.value == null )"
                                      (click)="displayInput(field.variableProjectId, field)">
                                    {{ "pim.element.empty" | translate }}
                                </span>
                                <span class="underline-red pointer"
                                      (click)="displayInput(field.variableProjectId, field)"
                                      *ngIf="field.fieldType === 'list multiple' && inputId !== field.variableProjectId  && (field.value == null || field.value.length <=0 || ['',null,undefined].includes(field.value[0]) )"
                                      (click)="displayInput(field.variableProjectId, field)">
                                    {{ "pim.element.empty" | translate }}
                                </span>

                                <!-- Table -->
                                <app-edit-table id="field-{{field.variableProjectId}}"
                                                *ngIf="field.fieldType === 'table'" [field]="field"
                                                (_saveValue)="_saveValue($event)"></app-edit-table>
                                <div *ngIf="inputId === field.variableProjectId">
                                    <div class="row d-flex justify-content-end position-relative"
                                         style="bottom:15px;right: 10px;">
                                        <div style="margin-right: .5rem;"
                                             *ngIf="field.fieldType !== 'productLink' && field.fieldType !== 'fieldset'">
                                            <svg *ngIf="!field.isLoading && field.valueProjectId"
                                                 (click)="openHistory(field.valueProjectId, field.fieldType)"
                                                 style="width: 1.1rem; height: 1.1rem" class="mt-2 pointer" fill="none"
                                                 stroke="currentColor" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <div *ngIf="field.isLoading"
                                                 class="spinner-border mx-auto text-primary spinner-border-sm align-middle"
                                                 style="vertical-align: -webkit-baseline-middle !important;"
                                                 role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div style="margin-right: .5rem; margin-top: 2px;">
                                            <button *ngIf="closeHide && inputId === field.variableProjectId"
                                                    (click)="hideInput(field.variableProjectId)" type="button"
                                                    class="close mt-1 " aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="row position-relative" style="bottom: 10px;">
                                        
                                        <div class="col-12 my">

                                            <div class="row">

                                                <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="selectedLanguage?.hasOwnProperty('flag') && !selectedLanguage.isBasic && ['text', 'number', 'date','textarea','list', 'list multiple'].includes(field.fieldType)">
                                                    <img [src]="selectedLanguage?.flag" />
                                                </span>
                                                <!-- Text -->
                                                <input id="field-{{field.variableProjectId}}" [disabled]="canUpdate(field) || (!selectedLanguage.isBasic && !field.translatable)"
                                                       (change)="updateValue($event.target.value, field)"
                                                       value="{{
                                                                selectedLanguage &&
                                                                !selectedLanguage.isBasic &&
                                                                field.valueTranslations &&
                                                                field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                                field.valueTranslations[selectedLanguage.internationalCode] &&
                                                                field.translatable
                                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                                    : field.value
                                                                }}"
                                                       type="text" class="form-control col-11"
                                                       *ngIf="field.fieldType === 'text' && inputId === field.variableProjectId"/>
                                                <!-- Number -->
                                                <input id="field-{{field.variableProjectId}}" [disabled]="canUpdate(field) || (!selectedLanguage.isBasic && !field.translatable)"
                                                       (keypress)="checkIfNumber($event)"
                                                       (change)="updateValue($event.target.value, field)"
                                                       value="{{  selectedLanguage &&
                                                                  !selectedLanguage.isBasic &&
                                                                  field.valueTranslations &&
                                                                  field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                                  field.valueTranslations[selectedLanguage.internationalCode] &&
                                                                  field.translatable
                                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                                    : field.value }}" type="number" class="form-control col-11"
                                                       *ngIf="field.fieldType === 'number' && inputId === field.variableProjectId"/>
                                                <!-- Textarea -->
                                                <app-text-editor [focus]="true" [fieldId]="'field-'+field.variableProjectId"
                                                                 [disabled]="canUpdate(field) || (!selectedLanguage.isBasic && !field.translatable)"
                                                                 class="col-11"
                                                                 *ngIf="field.fieldType === 'textarea' && inputId === field.variableProjectId"
                                                                 [value]="selectedLanguage &&
                                                                          !selectedLanguage.isBasic &&
                                                                          field.valueTranslations &&
                                                                          field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                                          field.valueTranslations[selectedLanguage.internationalCode] &&
                                                                          field.translatable
                                                                            ? field.valueTranslations[selectedLanguage.internationalCode]
                                                                            : field.value
                                                                         "
                                                                 (focusOut)="updateValue($event, field)"></app-text-editor>
                                                <!-- Color -->
                                                <app-color-picker-maestro id="field-{{field.variableProjectId}}"
                                                                          (colorSelect)="updateValue($event, field)"
                                                                          *ngIf="field.fieldType === 'colors'&& inputId === field.variableProjectId"
                                                                          [value]="field.value"
                                                                          [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"></app-color-picker-maestro>
                                                <!-- Checkbox -->
                                                <div id="field-{{field.variableProjectId}}" class="form-group"
                                                     *ngIf="field.fieldType === 'checkbox'&& inputId === field.variableProjectId">
                                                    <div class="col-10">
                                                        <label class="switch switch-pill switch-primary">
                                                            <input [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                                                   (change)="updateValue($event.target.checked, field)"
                                                                   type="checkbox" class="switch-input"
                                                                   [checked]="field.value === '1'" value="{{field.value}}">
                                                            <span class="switch-slider"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <!-- Date -->
                                                <app-date-picker id="field-{{field.variableProjectId}}"
                                                                 *ngIf="field.fieldType === 'date'&& inputId === field.variableProjectId"
                                                                 [active]="!disabled && selectedLanguage?.isBasic" (dateSelect)="updateValue($event, field)"
                                                                 class="form-control p-0 col-11" [minDate]="{ year: 1930, month: 1, day: 1 }"
                                                                 [value]="field.value"></app-date-picker>
    
                                                <!-- List / Simple selection -->
                                                <select id="field-{{field.variableProjectId}}" [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                                        (change)="updateValueList($event.target.value, field)"
                                                        [(ngModel)]="field.value[0]" class="form-control"
                                                        [ngClass]="selectedLanguage?.isBasic ? 'col-11' : 'col-10'"
                                                        *ngIf="field.fieldType === 'list' && inputId === field.variableProjectId">
                                                    <option></option>
                                                    <option [selected]="field.value && field.value[0] && field.value[0] === value[basicLanguage.internationalCode]" *ngFor="let value of field.listValueTranslations" [value]="value[basicLanguage.internationalCode]">{{ !selectedLanguage.isBasic ? value[selectedLanguage.internationalCode] : value[basicLanguage.internationalCode] }}</option>
                                                </select>
                                                <!-- List / Multiple selection -->
                                                <app-edit-list-multiple appendTo="self"
                                                                        id="field-{{field.variableProjectId}}" [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                                                        (saveList)="updateValueList($event, field)" [field]="field" [variableProjectId]="field?.variableProjectId"
                                                                        *ngIf="field.fieldType === 'list multiple'&& inputId === field.variableProjectId" class=" col-11"></app-edit-list-multiple>
    
                                                <!-- Fieldset -->
                                                <app-edit-fieldset id="field-{{field.variableProjectId}}"
                                                                   (historyClick)="openHistory($event.objectId, $event.fieldType, $event.tableHistoryFromFieldset)"
                                                                   [disabled]="canUpdate(field)" [field]="field"
                                                                   *ngIf="field.fieldType === 'fieldset'&& inputId === field.variableProjectId"
                                                                   [pageDuplicateId]="pageDuplicateId"
                                                                   [selectedLanguage]="selectedLanguage"
                                                                   [basicLanguage]="basicLanguage"  [productId]="field.productId" class="col-12"></app-edit-fieldset>
    
                                                <!-- Product Link -->
                                                <app-edit-product-link id="field-{{field.variableProjectId}}"
                                                    (historyClick)="openHistory($event)" [disabled]="canUpdate(field)"
                                                    [field]="field"
                                                    [selectedLanguage]="selectedLanguage" [basicLanguage]="basicLanguage"
                                                    *ngIf="field.fieldType === 'productLink'&& inputId === field.variableProjectId"
                                                    [pageDuplicateId]="pageDuplicateId" class="col-12"></app-edit-product-link>
    
                                                <!-- Media -->
                                                <ng-container *ngIf="field.fieldType === 'media' && inputId === field.variableProjectId">
                                                    <app-edit-media (mediasSaved)="updateValue($event, field)" [medias]="field.value" id="field-{{field.variableProjectId}}" class="col-10 p-0" [disabled]="canUpdate(field)" [pageDuplicateId]="undefined !== pageDuplicateId ? pageDuplicateId : -1" [singleSelection]="!field.multiple" [forElementMedia]="false" [idElementValue]="field.valuePimId" [idElementValueProject]="field.valueProjectId ? field.valueProjectId : -1"></app-edit-media>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 my" *ngIf="!selectedLanguage.isBasic && ['text', 'number','textarea'].includes(field.fieldType) && field.translatable">
                                            <div class="row">

                                                <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="basicLanguage?.hasOwnProperty('flag')">
                                                    <img [src]="basicLanguage?.flag" />
                                                </span>
    
                                                <input *ngIf="['text', 'number'].includes(field.fieldType) && inputId === field.variableProjectId" 
                                                [value]="field.value"
                                                class="form-control col-11" 
                                                [disabled]="true"/>

                                                <app-text-editor class="col-11" *ngIf="field.fieldType == 'textarea' && inputId === field.variableProjectId" [disabled]="true" [value]="field.value"></app-text-editor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        <!-- ACl  -->
                        <span *ngIf="canUpdate(field) === true">
                                <span
                                    *ngIf="inputId !== field.templateTwigId &&  field.fieldType !='fieldset' && field.fieldType !='table' && field.fieldType != 'media'"
                                    [innerHTML]="selectedLanguage &&
                                                 !selectedLanguage.isBasic &&
                                                 field.valueTranslations &&
                                                 field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                 field.valueTranslations[selectedLanguage.internationalCode]
                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                    : field.value">
                                </span>
                                
                            <!-- Text -->
                                <input id="field-{{field.templateTwigId}}" [disabled]="canUpdate(field)"
                                       (change)="updateValue($event.target.value, field)" value="{{ selectedLanguage &&
                                                 !selectedLanguage.isBasic &&
                                                 field.valueTranslations &&
                                                 field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                 field.valueTranslations[selectedLanguage.internationalCode]
                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                    : field.value }}"
                                       type="text" class="form-control"
                                       *ngIf="field.fieldType === 'text'&& inputId === field.templateTwigId"/>
                            <!-- Number -->
                                <input id="field-{{field.templateTwigId}}" [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                       (keypress)="checkIfNumber($event)"
                                       (change)="updateValue($event.target.value, field)" value="{{ selectedLanguage &&
                                                 !selectedLanguage.isBasic &&
                                                 field.valueTranslations &&
                                                 field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                 field.valueTranslations[selectedLanguage.internationalCode]
                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                    : field.value }}"
                                       type="number" class="form-control"
                                       *ngIf="field.fieldType === 'number' && inputId === field.templateTwigId"/>
                            <!-- Textarea -->
                                <app-text-editor [focus]="true" [fieldId]="'field-'+field.templateTwigId"
                                                 [disabled]="canUpdate(field)"
                                                 *ngIf="field.fieldType === 'textarea' && inputId === field.templateTwigId"
                                                 [value]="selectedLanguage &&
                                                 !selectedLanguage.isBasic &&
                                                 field.valueTranslations &&
                                                 field.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                                                 field.valueTranslations[selectedLanguage.internationalCode]
                                                    ? field.valueTranslations[selectedLanguage.internationalCode]
                                                    : field.value" (focusOut)="updateValue($event, field)"></app-text-editor>
                            <!-- Color -->
                                <app-color-picker-maestro id="field-{{field.templateTwigId}}"
                                                          (colorSelect)="updateValue($event, field)"
                                                          *ngIf="field.fieldType === 'colors'&& inputId === field.templateTwigId"
                                                          [value]="field.value"
                                                          [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"></app-color-picker-maestro>
                            <!-- Checkbox -->
                                <div id="field-{{field.templateTwigId}}" class="form-group"
                                     *ngIf="field.fieldType === 'checkbox'&& inputId === field.templateTwigId">
                                    <div class="col-10">
                                        <label class="switch switch-pill switch-primary">
                                            <input [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                                   (change)="updateValue($event.target.checked, field)" type="checkbox"
                                                   class="switch-input" [checked]="field.value === '1'"
                                                   value="{{field.value}}">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>

                            <!-- Date -->
                                <app-date-picker id="field-{{field.templateTwigId}}"
                                                 *ngIf="field.fieldType === 'date'&& inputId === field.templateTwigId"
                                                 [active]="!disabled && selectedLanguage?.isBasic" (dateSelect)="updateValue($event, field)"
                                                 class="form-control p-0" [minDate]="{ year: 1930, month: 1, day: 1 }"
                                                 [value]="field.value"></app-date-picker>

                            <!-- List / Simple selection -->
                                <select id="field-{{field.templateTwigId}}" [disabled]="canUpdate(field) || !selectedLanguage?.isBasic"
                                        (change)="updateValueList($event.target.value, field)" [(ngModel)]="field.value[0]"
                                        class="form-control"
                                        *ngIf="field.fieldType === 'list'&& inputId === field.templateTwigId">
                                    <option></option>
                                    <option *ngFor="let value of field.listValue">{{ value }}</option>
                                </select>

                            <!-- List / Multiple selection -->
                                <app-edit-list-multiple id="field-{{field.templateTwigId}}"
                                                        [disabled]="canUpdate(field) || !selectedLanguage?.isBasic" (saveList)="updateValueList($event, field)"
                                                        [field]="field"
                                                        *ngIf="field.fieldType === 'list multiple'&& inputId === field.templateTwigId"></app-edit-list-multiple>

                            <!-- Fieldset -->
                                <app-edit-fieldset id="field-{{field.templateTwigId}}"
                                                   (historyClick)="openHistory($event.objectId, $event.fieldType, $event.tableHistoryFromFieldset)"
                                                   [disabled]="canUpdate(field)" [field]="field" *ngIf="field.fieldType === 'fieldset'"
                                                   [pageDuplicateId]="pageDuplicateId" [selectedLanguage]="selectedLanguage"
                                                   [basicLanguage]="basicLanguage" [productId]="field.productId"></app-edit-fieldset>

                            <!-- Product Link -->
                                <app-edit-product-link id="field-{{field.templateTwigId}}"
                                    (historyClick)="openHistory($event)" [disabled]="canUpdate(field)" [field]="field"
                                    *ngIf="field.fieldType === 'productLink'&& inputId === field.templateTwigId"
                                    [pageDuplicateId]="pageDuplicateId"></app-edit-product-link>

                                <!-- Media -->
                                <!--
                                <ng-container *ngIf="field.fieldType === 'media' && inputId === field.templateTwigId">
                                    <app-edit-media (mediasSaved)="updateValue($event, field)" [medias]="field.value" id="field-{{field.templateTwigId}}" class="col-10 p-0" [disabled]="canUpdate(field)" [pageDuplicateId]="undefined !== pageDuplicateId ? pageDuplicateId : -1" [singleSelection]="!field.multiple" [forElementMedia]="false" [idElementValue]="field.valuePimId" [idElementValueProject]="field.valueProjectId ? field.valueProjectId : -1"></app-edit-media>
                                </ng-container>
                                -->
                            </span>
                    </div>
                    <div class="col-2" *ngIf="inputId != field.variableProjectId">
                        <div class="row">

                            <div style="margin-top: 0;padding-top: 0;" class="col-5" *ngIf="field.fieldType !== 'productLink' && field.fieldType !== 'fieldset'"  [title]="_translate.instant('tooltip.history')">
                                <svg *ngIf="!field.isLoading && field.valueProjectId"
                                     (click)="openHistory(field.valueProjectId, field.fieldType)"
                                     style="width: 1.1rem; height: 1.1rem" class="pointer" fill="none"
                                     [ngClass]="{'mt-clock' : field.fieldType === 'table' }" stroke="currentColor"
                                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                </svg>
                                <div *ngIf="field.isLoading"
                                     class="spinner-border mx-auto text-primary spinner-border-sm align-middle"
                                     style="vertical-align: -webkit-baseline-middle !important;" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div  style="margin-top: 0;padding-top: 0; top: 3px" class="col-5"  *ngIf="field.productId && field.elemTypeFieldId && field.valueProjectId && (field.fieldType !== 'fieldset' || (field.fieldType == 'fieldset' && selectedLanguage.isBasic))" [title]="_translate.instant('tooltip.reInitValuePIM')">
                                <fa-icon style="cursor: pointer;" *ngIf="field.productId && field.elemTypeFieldId" (click)="reInitValue(field, indexValue)" [icon]="faRedo"></fa-icon>
                            </div>
                        </div>

                    </div>

                </div>


            </td>

            <!--
                 <label *ngIf="field.fieldType !== 'checkbox'" class="col-2row col-form-label text-truncate" for="">
                    {{ field.key }}
                </label>
            -->
        </tr>
        </tbody>
    </table>
</div>

<!-- history -->
<swal #historyModal width="700px" [showConfirmButton]="false" title="{{ 'history.historyChange' | translate }}">
    <div *swalPortal>
        <div *ngIf="history.length === 0" class="alert alert-warning" role="alert">
            {{ 'history.notHistory' | translate }}
        </div>
        <div *ngIf="history.length > 0">
            <div *ngIf="!tableHistory && !checkboxHistory && !colorsHistory" class="row justify-content-center">
                <label class="switch switch-label switch-outline-primary-alt">
                    <input class="switch-input" type="checkbox" (change)="showDiffSwitcher()"/>
                    <span class="switch-slider" data-checked="Diff" data-unchecked="Diff"></span>
                </label>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>{{ 'history.modify' | translate }}</th>
                        <th>{{ 'history.modifyBy' | translate }}</th>
                        <th>{{ 'history.publicationDate' | translate }}</th>
                        <th *ngIf="!tableHistory && !checkboxHistory">{{ 'history.action' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let modification of history; let i = index">
                        <ng-container *ngFor="let values of modification.value; let j = index">
                            <tr>
                                <td *ngIf="tableHistory">
                                    <app-form-table [id]="j"
                                                    [cells]="_tableService.parse(values.newValue.value, tableHistoryFromFieldset)"
                                                    [readOnly]="true">
                                    </app-form-table>
                                </td>
                                <td *ngIf="!tableHistory">

                                    <div *ngIf="showDiff === false || !values.oldValue.value">
                                            <span
                                                [innerHtml]="fieldTypeHistory === 'colors' || fieldTypeHistory === 'checkbox'  ? null : sanitize(values.newValue.value.toString().replaceAll(regexBalisePEmpty, '<br/>'))"></span>
                                    </div>

                                    <span *ngIf="showDiff === true && values.oldValue.value" diff
                                          [left]="values.oldValue.value.toString().replaceAll(regexBalisePEmpty, '<br/>')"
                                          [right]="values.newValue.value.toString().replaceAll(regexBalisePEmpty, '<br/>')">
                                        </span>
                                    <div *ngIf="fieldTypeHistory === 'checkbox'">
                                        <label class="switch switch-pill switch-primary">
                                            <input [disabled]="true" type="checkbox" class="switch-input col-10"
                                                   [checked]="values.newValue.value === '1'">
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                    <div *ngIf="fieldTypeHistory === 'colors'">
                                        <app-color-picker-maestro [value]="values.newValue.value" [disabled]="true">
                                        </app-color-picker-maestro>
                                    </div>
                                </td>
                                <td *ngIf="modification.nameUser.length === 0">
                                    {{ 'history.maestroUser' | translate }}
                                </td>
                                <td *ngIf="modification.nameUser.length > 0">
                                    {{ modification.nameUser }}
                                </td>
                                <td>
                                    {{ modification.date }}
                                </td>
                                <td *ngIf="!tableHistory && !checkboxHistory">
                                    <button *ngIf="!modification.isCopy" data-toggle="popover" [id]="'tooltip-' + i"
                                            (click)="copyToClip(values.newValue.value, i,'tooltip-')"
                                            data-placement="top" class="btn btn-secondary">
                                        <fa-icon [icon]="faClone"></fa-icon>
                                    </button>
                                    <p *ngIf="modification.isCopy" class="p-copied">
                                        <i style="color: black; font-size: 15px;" class="bi bi-check-lg"></i>
                                        {{ 'tooltip.copied' | translate }}
                                    </p>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</swal>
