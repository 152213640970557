import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, UrlTree } from "@angular/router";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { Language } from "app/shared/models/language";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class LanguageFormGuard implements Resolve<Language>, CanActivate {
    constructor(private _service: LanguageService, private userService: UserService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const languageId = route.params.id;
        return forkJoin([this.userService.getAll(), this._service.findById(languageId)]).pipe(
            map(
                (data) =>
                    <any>{
                        users: data[0]["data"].map((user, i) => ({ id: user.id, name: user.firstName + " " + user.lastName })),
                        language: data[1].data,
                        languageId: languageId,
                    }
            )
        );
    }
}
