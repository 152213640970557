import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, UrlTree } from "@angular/router";
import { ExportPageService } from "app/core/services/project/export/export-page-view.service";
import { MaestroExport } from "app/shared/models";
import { PageResources } from "app/shared/models/project/page-resources";
import { Observable } from "rxjs";
import {concatMap, map} from "rxjs/operators";

@Injectable()
export class ExportPageResolver implements Resolve<MaestroExport>, CanActivate {
    constructor(private _service: ExportPageService) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return !!route.firstChild;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const pageId = route.params.id;
        const exportId = route.parent.parent.parent.params.id;
        let pageDuplicateId = route.queryParams.duplicate;
        return this._service.getById(pageId, pageDuplicateId).pipe(
            concatMap((page: any) => this._service.getTemplateByElements(pageId, [...(page.data && page.data.products ? page.data.products : [])], exportId, pageDuplicateId)
                .pipe(
                    map(
                        (a) =>
                            <PageResources>{
                                templateByElement: (a as any).data,
                                page: (page as any).data,
                            }
                    )
                )
            )
        );
    }
}
