import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CommonService } from "app/core/services/admin/common.service";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { Language } from "app/shared/models/language";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { ConfirmationService } from "primeng-lts/api";
import { Subscription } from "rxjs";

@Component({
    selector: "app-language-list",
    templateUrl: "./language-list.component.html",
    styleUrls: ["./language-list.component.scss"],
})
export class LanguageListComponent implements TableView, OnInit, OnDestroy {
    private subscription: Subscription;
    table: [string, string][];
    columns: string[];
    public actions: ActionButtonsDef = [];
    private acl: ACL;
    private static envParamControl: string = "enable_group_control";
    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _tableDataPipe: TableDataPipe,
        private _modal: SwalModalService,
        private _translateService: TranslateService,
        private _modalService: SwalModalService,
        private _languageService: LanguageService,
        private _commonService: CommonService,
        private _confirmationService: ConfirmationService,
        private _userService: UserService
    ) {}

    ngOnInit() {
        const languages: Language[] = this._route.snapshot.data.language.data;
        this._initList(languages);


        this.acl = this._userService.getUserAclFromToken();

        this._commonService.checkParamControl(LanguageListComponent.envParamControl).subscribe((data) => {
            const enableGroupControl = data.data;
            /**
             * Prepare the create button
             */
            if (!enableGroupControl && this.acl.MAESTRO_ADMIN_LANGUAGE_CREATE) {
                this._layout.actionButton.enable = true;
                this._layout.actionButton.title = "admin.create.language";
                this.subscription = this._layout.actionButton.click$.subscribe((_) => this.onCreate());
            }

            if (this.acl.MAESTRO_ADMIN_LANGUAGE_UPDATE) {
                this.actions.push({ type: ActionType.Update, right: "MAESTRO_ADMIN_LANGUAGE_UPDATE" });
            }

            if (!enableGroupControl && this.acl.MAESTRO_ADMIN_LANGUAGE_DELETE) {
                this.actions.push({ type: ActionType.Delete, right: "MAESTRO_ADMIN_LANGUAGE_DELETE" });
            }
        });

    }

    ngOnDestroy() {
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.breadcrumb.setPath(null, 1);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }

    /**
     * Init the list using the tableDataPipe
     * @param languages
     */
    private _initList(languages: Language[]) {
        const formatedData = this._tableDataPipe.transform(languages);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    /**
     * Handle the action click
     * @param event
     */
    onActionClick(event: ActionEvent): void {
        const id = event.rowId;
        switch (event.action) {
            /**
             * If update redirect
             */
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
            case ActionType.Delete:
                this._delete(id);
                break;
            default:
                break;
        }
    }

    /**
     * Open the create modal
     */
    onCreate() {
        this._router.navigate([ MAESTRO_ROUTES.actions.create], { relativeTo: this._route });

    }

    /**
     * Delete a language
     *
     * @param id
     */
    private _delete(id: number) {

        if (id) {
            this._languageService.delete(id).subscribe(
                () => {
                    this._languageService.getAll().subscribe((languages) => this._initList(languages.data));
                    this._modalService.success(this._translateService.instant("general.deleted"));
                },
                () => {
                    this._modalService.error(this._translateService.instant("general.deleted"));

                }
            )
        }
    }
}
