import { ACL } from "./acl";

export class User {
    jwt: string;
    refreshToken: string;
    master_code: string;
    master_language: string;
    acl: ACL;
    systemLanguage: string;
    contentLanguages: [];
}
