<div *ngIf="data; else elseBlock">
    <p id="support">{{ 'dashboard.no_support' | translate }}</p>
    <div *ngIf="blocDatas.length > 0">
        <div class="row">
            <ng-container *ngFor="let bloc of blocDatas; let i = index">
                <ng-container *ngIf="bloc.data" [ngSwitch]="bloc.level">
                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 py-2" [ngClass]="{ 'col-xl-3': blocDatas.length > 6, 'col-xl-4': blocDatas.length <= 6 && blocDatas.length >= 5, 'col-xl-6': blocDatas.length <= 4, 'col-xl-12': blocDatas.length <= 2 }"
                        [ngStyle]="{ 'position': 'relative', 'height': '40vh', 'width': '80vw' }">
                        <div *ngSwitchCase="'0'" [bloc]="blocDatas[i]" id="{{ 'item' + (i + 1) }}" class="card h-100"
                            style="overflow: auto" app-dashboard-list></div>
                        <div *ngSwitchCase="'1'" [bloc]="blocDatas[i]" id="{{ 'item' + (i + 1) }}"
                            class="card h-100 bg-primary" app-dashboard-chart-level-1>
                        </div>
                        <div *ngSwitchCase="'2'" [bloc]="blocDatas[i]" id="{{ 'item' + (i + 1) }}"
                            class="card h-100 bg-info" app-dashboard-chart-level-2>
                        </div>
                        <div *ngSwitchCase="'3'" [bloc]="blocDatas[i]" id="{{ 'item' + (i + 1) }}" class="card h-100"
                            style="overflow: hidden" app-dashboard-chart-level-3></div>
                        <p *ngSwitchDefault>No graph found for type {{ bloc.type }}...</p>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #elseBlock>
    <div class="alert alert-warning" role="alert">
        {{ 'dashboard.empty' | translate }}
    </div>
</ng-template>
<div #showDashboardConfigurationModal class="modal fade" id="showDashboardConfigurationModal" tabindex="-1" role="dialog" aria-labelledby="showDashboardConfigurationLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ 'dashboard.configure' | translate }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="dashboard" class="modal-body">
            <div class="alert alert-info alert-dismissible fade show" role="alert">
                <i class="pi pi-info-circle"></i> {{ 'general.configuration.warning.delete' | translate }}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
          <div *ngFor="let config of dashboard.configurations">
            <p-accordion (onOpen)="getBlocSelected(config)">
                <p-accordionTab header="{{ (config.default ? config.name + ' (' + ('general.configuration.byDefault' | translate) + ')' : config.name) + (config.default ? '' : ' : ' + ((config.active ? 'general.configuration.enabled' : 'general.configuration.disabled') | translate)) }}">
                    <div *ngIf="config.default" class="alert alert-warning alert-dismissible fade show" role="alert">
                        <i class="pi pi-exclamation-triangle"></i> {{ 'general.configuration.warning.update' | translate }}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="mb-4 container">
                        <div class="row">
                            <div class="col">
                                <label for="active" translate>{{ 'general.configuration.active' | translate }}</label>
                                <label class="switch switch-label switch-outline-primary ml-2 pt-3">
                                    <input id="active" class="switch-input" type="checkbox" [checked]="config.active" (change)="config.active = !config.active; updateConfig(config.id, config.active)" [disabled]="config.default"/>
                                    <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                                </label>
                            </div>
                            <div class="col ml-5 pt-4">
                                <label for="nameConfiguration" class="pl-5" translate>{{ 'general.configuration.name' | translate }}</label>
                                <input id="nameConfiguration" class="ml-2" type="text" pInputText [(ngModel)]="config.name">
                                <i class="btn btn-primary pi pi-save ml-2" type="button" (click)="updateConfig(config.id, config.active)"></i>
                            </div>
                        </div>
                    </div>
                    <ng-template pTemplate="content" class="picklist">
                        <p-pickList [source]="blocSelectable[config.id]" [target]="blocSelected[config.id]" [responsive]="true" sourceHeader="{{ 'general.configuration.available' | translate }}" targetHeader="{{ 'general.configuration.selected' | translate }}" filterBy="name,blocType.name,blocType.type,dataTypes.0.type,dataTypes.0.dataOptions.0.module" [dragdrop]="true" [sourceStyle]="{'height':'500px'}" [targetStyle]="{'height':'500px'}" filterBy="name"
                        sourceFilterPlaceholder="{{ 'general.search' | translate }}" targetFilterPlaceholder="{{ 'general.search' | translate }}" (onMoveToTarget)="updateConfig(config.id, config.active)" (onMoveToSource)="updateConfig(config.id, config.active)" (onMoveAllToTarget)="updateConfig(config.id, config.active)" (onMoveAllToSource)="updateConfig(config.id, config.active)" (onTargetReorder)="updateConfig(config.id, config.active)">
                            <ng-template let-bloc pTemplate="item">
                                <div>
                                    <p-card>
                                        <ng-template pTemplate="header">
                                            <strong>{{ bloc.name }}</strong> ({{ bloc.dataTypes[0].dataOptions[0].module | uppercase }})
                                        </ng-template>
                                        <ng-container [ngSwitch]="bloc.blocType.level">
                                            <div *ngSwitchCase="'0'" [bloc]="{name: bloc.name, type: bloc.blocType.type, data: sample[bloc.blocType.level]}" id="{{ bloc.id }}"
                                                style="overflow: auto; max-height: 250px !important; max-width: 250px !important" app-dashboard-list></div>
                                            <div *ngSwitchCase="'1'" [bloc]="{name: bloc.name, type: bloc.blocType.type, data: sample[bloc.blocType.level]}" id="{{ bloc.id }}"
                                                class="bg-primary" style="max-height: 250px !important; max-width: 250px !important" app-dashboard-chart-level-1>
                                            </div>
                                            <div *ngSwitchCase="'2'" [bloc]="{name: bloc.name, type: bloc.blocType.type, data: sample[bloc.blocType.level]}" id="{{ bloc.id }}"
                                                class="bg-info" style="max-height: 250px !important; max-width: 250px !important" app-dashboard-chart-level-2>
                                            </div>
                                            <div *ngSwitchCase="'3'" [bloc]="{name: bloc.name, type: bloc.blocType.type, data: sampleWorkflowList, subQuery: sample[bloc.blocType.level], isSample: true}" id="{{ bloc.id }}"
                                                style="overflow: hidden; max-height: 250px !important; max-width: 250px !important" app-dashboard-chart-level-3></div>
                                            <p *ngSwitchDefault>No graph found for type {{ bloc.blocType.type }}...</p>
                                        </ng-container>
                                    </p-card>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'general.close' | translate  }}</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" [disabled]="!dashboard" (click)="saveDashboardConf()">{{ 'general.save' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

<app-modal-form-language></app-modal-form-language>

