import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faDownload, faEdit, faEye, faInfo } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { GroupService } from "app/core/services/admin/group/group.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { PageService } from "app/core/services/project/export/page.service";
import { ProjectDashboardService } from "app/core/services/project/project/dashboard.service";
import { ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { IMaestroEntitys, MaestroExports, MaestroProject } from "app/shared/models";
import { ProjectDashboardResources } from "app/shared/models/project/dashboard";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-project-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class ProjectDashboardComponent implements OnInit {
    @ViewChild("versionSwal", { static: true }) versionSwal: SwalComponent;
    table: [string, string][];
    columns: string[];
    exports: MaestroExports = [];
    project: MaestroProject;
    pageStep: IMaestroEntitys = [];
    groups: any;
    // auths: any;
    groupsIds: number[] = []; // Contains all user's groups ids associated to project's groups
    readonly faInfo = faInfo;
    readonly faEdit = faEdit;
    readonly faEye = faEye;
    readonly faDownload = faDownload;
    newVersion: FormGroup;
    resources: ProjectDashboardResources;
    users: IMaestroEntitys = [];
    separator: string;

    readonly actions: ActionButtonsDef = [{ type: ActionType.FormUpdate, right: "MAESTRO_PROJECT_PROJECTS_UPDATE" }];

    //Lié à l'export Flux
    csvData: Blob; // Contient le contenu du fichier CSV sous forme de Blob
    csvHeaders: string[]; // Contient les en-têtes de colonnes du fichier CSV
    csvRows: string[][]; // Contient les lignes de données du fichier CSV
    exportName: string;
    typeExport: string = "";
    //END

    _selectedRows: any[] = []; // Define which rows are selected

    // FOR TRADUCTION
    selectedLanguage : Language;

    constructor(
        private _tableDataPipe: TableDataPipe,
        private route: ActivatedRoute,
        private _router: Router,
        private _translate: TranslateService,
        private _layout: LayoutService,
        private _fb: FormBuilder,
        private _dashboardService: ProjectDashboardService,
        private _exportService: ProjectExportService,
        private _swalModalService: SwalModalService,
        private cdRef: ChangeDetectorRef,
        private _userService: UserService,
        private _groupService: GroupService,
        private _dataService: DataService,
        private _pageService: PageService,
        private _languageService: LanguageService
    ) {}

    ngOnInit(): void {
        this.resources = this.route.snapshot.data.resources;
        this.project = this.resources.project;
        this.pageStep = this.resources.pageStep;
        this.separator = ";";

        // this.auths = resources.auths;
        this.groups = this.filterGroups();

        this._initExportVersion(this.resources.exports);
        this._layout.breadcrumb.setPath(
            {
                name: this.project.title,
                routerLink: `/${MAESTRO_ROUTES.projects.base}/${this.project.id}/${MAESTRO_ROUTES.projects.dashboard}`,
            },
            1
        );
        this._initList(this.pageStep);

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });

    }

    removeTruncation(td: HTMLElement): void {
        const computedStyle = window.getComputedStyle(td);
        if (computedStyle.textOverflow === "ellipsis") {
            td.style.textOverflow = "initial";
            td.style.whiteSpace = "normal";
        } else {
            td.style.textOverflow = "ellipsis";
            td.style.whiteSpace = "nowrap";
        }
    }

    private _initExportVersion(exports) {
        let self = this;
        this.exports = [];
        const idOwnerGroupProject = this.project.idOwnerGroup;
        exports.forEach(function (exp) {
            self.exports.push(exp);
            if (exp.versions.length > 0) {
                exp.versions.forEach(function (version) {
                    const group = self.resources.projectGroups.find((g) => g.id === version.ownerGroup);

                    const versionObject = {
                        id: exp.id,
                        name: version.name,
                        type: exp.type,
                        description: exp.description,
                        elementLength: exp.elementLength,
                        pageLength: /*exp.pageLength*/ version.nbPages,
                        maxLength: exp.maxLength,
                        color: exp.color,
                        workflow: { id: exp.workflow_id },
                        versionId: version.id,
                        ownerGroup: version.ownerGroup,
                        idUser: version.idUser,
                        groupNames: undefined !== group ? [group.name] : [],
                    };

                    /**
                     * If none of user's groups is associated to project : every users see every versions (usefull for project's creator and immutable user Maestro)
                     * If user has project's owner group : see every versions
                     * If user has version's group : see version
                     * If user is version's creator : see version
                     * Else no groups used : every users see every versions
                     */
                    if (idOwnerGroupProject) {
                        if (
                            !self.groupsIds.length ||
                            self.groupsIds.includes(idOwnerGroupProject) ||
                            self.groupsIds.includes(version.ownerGroup) ||
                            version.idUser === self._userService.getUserIdFromToken()
                        ) {
                            self.exports.push(versionObject);
                        }
                    } else {
                        self.exports.push(versionObject);
                    }
                });
            }
        });
    }

    /**
     * Init the list
     * @param pageStep
     */
    private _initList(pageStep: IMaestroEntitys) {
        const formatedData = this._tableDataPipe.transform(pageStep);
        this.columns = formatedData[0];
        this.table = formatedData[1];
    }

    saveVersion() {
        let version = this.newVersion.value;
        if (version.steps) {
            version.steps.forEach((step) => {
                step.users = step.users.map((user) => ({ id: user }));
            });
        }
        let result = {
            id: version.versionId,
            name: version.name,
            groups: version.groups ? version.groups : [],
            steps: version.steps ? version.steps : [],
            duplicatePages: version.duplicatePages,
        };

        this._exportService.saveVersion(version.id, result).subscribe((data) => {
            if (version.versionId == "" && version.groups && version.groups.length && data.data) {
                this.onUpdateVersion(version.id, data.data, true);
            }
            this._dashboardService.getDashboardByProjectId(this.project.id).subscribe((data) => {
                this._initExportVersion(data);
            });
        });
    }

    /**
     * Handle action click
     * @param projectExport
     */
    onActionClick(projectExport: any, download = true) {
        if (projectExport.type === "Cdf_page" /*&& row[1].length === 5*/) {
            this._router.navigate(["../", MAESTRO_ROUTES.projects.export, projectExport.id, "page", projectExport.id], {
                relativeTo: this.route,
            });
        } else if (projectExport.type.toUpperCase() === "CSV") {
            this._dashboardService.getCsv(projectExport.id, this.selectedLanguage).subscribe((response) => {
                this._dashboardService.getSeparator(projectExport.id).subscribe((data) => {
                    this.separator = data.data;
                    if (download) {
                        saveAs(response.body, projectExport.name + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".csv");
                    } else {
                        this.csvData = response.body;
                        this.exportName = projectExport.name;
                        this.typeExport = projectExport.type.toUpperCase();
                        this.readFluxData(this.csvData);
                    }
                });
            });
        } else if (projectExport.type.toUpperCase() === "EXCEL") {
            this._dashboardService.getCsv(projectExport.id, this.selectedLanguage).subscribe((response) => {
                this._dashboardService.getSeparator(projectExport.id).subscribe((data) => {
                    this.separator = data.data;
                    if (download) {
                        saveAs(response.body, projectExport.name + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".xlsx");
                    } else {
                        this.csvData = response.body;
                        this.exportName = projectExport.name;
                        this.typeExport = projectExport.type.toUpperCase();
                        this.readFluxData(this.csvData);
                    }
                });
            });
        } else if (projectExport.type.toUpperCase() === "XML") {
            this._dashboardService.getXml(projectExport.id).subscribe((response) => {
                saveAs(response.body, projectExport.name + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".xml");
            });
        } else if (projectExport.type.toUpperCase() === "JSON") {
            this._dashboardService.getJson(projectExport.id).subscribe((response) => {
                saveAs(response.body, projectExport.name + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".json");
            });
        } else {
            this._router.navigate(["../", MAESTRO_ROUTES.projects.export, projectExport.id], {
                relativeTo: this.route,
                queryParams: { version: projectExport.versionId },
            });
        }
    }
    /**
     * Get the color for display the extension
     * @returns
     */
    getIconClass(type: string): string {
        switch (type) {
            case "CDF":
                return "fas fa-file-pdf";
            case "CSV":
                return "fas fa-file-csv";
            case "XML":
                return "fas fa-file-code";
            case "marketing":
                return "fas fa-file-pdf";
            case "HTML":
                return "fas fa-file-code";
            case "Réseaux Sociaux":
                return "fab fa-linkedin";
            default:
                return "fas fa-file-upload";
        }
    }

    getPageStepProgressInfo(m_export: any): any {
        //@TODO reprendre cette partie, mettre l'esnemble dans un composant + changer les appels du back
        //Ici je boucle sur les different pageStep renvoyé, si il y a en plusieur je suis dans le cas d'un CDF
        let pageStepForOneExport = this.pageStep.filter((element) => element.id === m_export.id);
        let result;
        let progression = 0;
        if (pageStepForOneExport.length == 1) {
            result = pageStepForOneExport[0];
        } else {
            let i = 0;
            pageStepForOneExport.forEach(function (value) {
                result = value;
                result.workflow = value["workflow"];
                progression = progression + value["pageProgressionWorkflow"];
                result.type = value["type"];
                i++;
            });
            result.progress = progression / i;
        }
        return result;
    }

    /**
     * Open version modal
     */
    onNewVersion(exportId): void {
        this.newVersion = this._fb.group({
            id: [exportId, Validators.required],
            versionId: [""],
            name: ["", Validators.required],
            groups: [[]],
            duplicatePages: [true],
        });

        this.versionSwal.fire().then((result) => {
            if (result.isConfirmed) {
                this.saveVersion();
            }
        });

        this.defineGroups();
    }

    onUpdateVersion(exportId, versionId, fromCreation: boolean = false) {
        this._exportService.readVersion(versionId).subscribe((data) => {
            let version = data.data;

            if (!fromCreation || (fromCreation && undefined !== version.steps && version.steps.length)) {
                this.newVersion = this._fb.group({
                    id: [exportId, Validators.required],
                    versionId: [versionId],
                    name: version.name,
                    groups: [version.groups],
                    steps: this._fb.array([]),
                });

                if (version.groups.length > 0) {
                    this._userService.getUsersByGroupsAndAssoc(version.groups[0]).subscribe((data) => {
                        this.users = data.data;
                    });
                }

                version.steps.forEach((versionStep) => {
                    if (versionStep.name != "workflowEnd") {
                        this.addStep(versionStep);
                    }
                });

                this.defineGroups(true);

                this.versionSwal.fire().then((result) => {
                    this.cdRef.detectChanges();
                    if (result.isConfirmed) {
                        this.saveVersion();
                    }
                });
            }
        });
        this.cdRef.detectChanges();
    }

    addStep(versionStep: any): void {
        let steps = <FormArray>this.newVersion.controls.steps;
        steps.push(
            this._fb.group({
                id: [versionStep.id ? versionStep.id : 0],
                name: [versionStep.name ? versionStep.name : "", Validators.required],
                dateFin: [versionStep.dateFin ? versionStep.dateFin : ""],
                users: [versionStep.users ? versionStep.users.map((user) => user.id) : []],
                workflowOptionId: [versionStep.workflowOptionId ? versionStep.workflowOptionId : 0, Validators.required],
            })
        );
    }

    canCreateVersion() {
        const acl = this._userService.getUserAclFromToken();
        let result = acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1; /*||this.auths.includes("ON_PROJECT_EXPORT_VERSION")*/
        return result;
    }

    onDeleteVersion(versionId: number) {
        this._swalModalService.delete().then((result) => {
            if (result.value) {
                this._exportService.deleteVersion(versionId).subscribe(() => {
                    this._dashboardService.getDashboardByProjectId(this.project.id).subscribe((data) => {
                        this._initExportVersion(data);
                    });
                });
            }
        });
    }

    defineGroups(updateCase: boolean = false): void {
        this.groups = this.filterGroups();
        const length = this.groups.length;

        if (!length) {
            this.newVersion.controls["groups"].disable();

            const controlValue = this.newVersion.controls["groups"].value;
            this.groups = this.resources.projectGroups.filter((g) => controlValue.some((i) => i === g.id));
        } else {
            if (1 === length && !updateCase) {
                this.newVersion.get("groups").setValue([this.groups[0].id]);
            }

            this.newVersion.controls["groups"].enable();
        }
    }

    filterGroups() {
        let userGroupsWithSub = this._groupService.getRecursiveGroups(this.resources.userGroups); // Get all nested groups
        let uniqueUserGroupsWithSub = [...new Map(userGroupsWithSub.map((item) => [item.id, item])).values()]; // Avoid duplicates
        const groups = uniqueUserGroupsWithSub.filter((g) => this.resources.projectGroups.some((i) => i.id === g.id)); // Return only groups associated to project's step 1

        this.groupsIds = groups.map((g) => {
            return g.id;
        });

        return groups;
    }

    canEdit(projectExport: any) {
        let canEdit = true;

        if (undefined !== projectExport && !projectExport.hasOwnProperty("versionId") && this.groups.length) {
            canEdit = this._userService.getUserIdFromToken() === this.project.idUser || this.groupsIds.includes(this.project.idOwnerGroup);
        }

        return canEdit;
    }

    getUsers(group) {
        let steps = <FormArray>this.newVersion.get("steps");
        if (steps) {
            if (group.value.length > 0) {
                this._userService.getUsersByGroupsAndAssoc(group.value[0]).subscribe((data) => {
                    this.users = data.data;
                });
            } else {
                for (let step of steps.controls) {
                    step.get("users").setValue([]);
                }

                this.users = [];
            }
        }
    }

    openDocument(id: number, filename: string) {
        this._dashboardService.openDocument(id, filename);
        return false;
    }

    readFluxData(data: Blob) {
        const reader = new FileReader();
        if (this.typeExport == "CSV") {
            reader.readAsText(data);
            reader.onloadend = () => {
                const csvText = reader.result as string;
                const rows = csvText.split("\n");
                this.csvHeaders = rows[0].split(this.separator);

                this.csvRows = rows.slice(1).map((row) => this.parseCSVRow(row));
            };
        } else if (this.typeExport == "EXCEL") {
            reader.readAsBinaryString(data);
            reader.onload = (e: any) => {
                /* create workbook */
                const binarystr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

                /* selected the first sheet */
                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                /* save data */
                const excel = XLSX.utils.sheet_to_json(ws, { header: 1 }); // to get 2d array pass 2nd parameter as object {header: 1}
                this.csvHeaders = excel[0] as string[];
                this.csvRows = this.parseExcelRow(excel.slice(1) as string[][], this.csvHeaders.length);
            };
        }
    }

    parseExcelRow(data: string[][], headerLength) {
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < headerLength; j++) {
                if (!data[i][j]) {
                    data[i][j] = "";
                }
            }
        }
        return data;
    }

    parseCSVRow(row: string): string[] {
        let insideQuotes = false;
        let currentCell = "";
        const cells = [];

        for (let i = 0; i < row.length; i++) {
            const char = row[i];

            if (char === '"') {
                insideQuotes = !insideQuotes;
            } else if (char === this.separator && !insideQuotes) {
                cells.push(currentCell.trim());
                currentCell = "";
            } else {
                currentCell += char;
            }
        }

        cells.push(currentCell.trim());

        return cells;
    }

    hideQuotes(value) {
        let val = value.replace(/"/g, "");
        return val;
    }

    showQuotes(value) {
        if (value === "") {
            return "";
        } else if (typeof value === "string" && value.startsWith('"') && value.endsWith('"')) {
            return value;
        }
        return `"${value}"`;
    }

    downloadFlux() {
        if (this.typeExport == "EXCEL") {
            this.separator == ";";
        }
        const csvContent = `${this.csvHeaders.join(this.separator)}\n${this.csvRows.map((row) => row.map((value) => this.showQuotes(value)).join(this.separator)).join("\n")}`;
        const csvContentEncoded = encodeURIComponent(csvContent);
        const csvContentWithBOM = "\uFEFF" + csvContentEncoded;
        const csvContentDecoded = decodeURIComponent(csvContentWithBOM);

        if (this.typeExport == "CSV") {
            const blob = new Blob([csvContentDecoded], { type: "text/csv;charset=utf-8" });
            saveAs(blob, this.exportName + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".csv");
        } else if (this.typeExport == "EXCEL") {
            const blob = new Blob([csvContentDecoded], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(blob, this.exportName + "-" + new Date().toISOString().slice(0, 10).replace(/-/g, "") + ".xlsx");
        }
    }

    getIdsForArchive(archiveHd: boolean = false) {
        let data = this._dataService.getIdsForArchive(this._selectedRows, "export", archiveHd);

        this._pageService.downloadArchive(data);
    }

    /**
     * Remove one row from selected
     * @param rowId // Name used since id are same for export + version ...
     */
    removeFromSelection(rowId: /*number*/ string): void {
        this._selectedRows = this._dataService.removeFromSelection(this._selectedRows, rowId, "name");
    }
}
