<div class="form-group" [formGroup]="form">
    <div class="row">
        <label class="col-sm-4 col-form-label" [ngClass]="{ 'text-danger': submitted && form.controls['elementTypes'].invalid }">
            {{ 'pim.elementTypes.title' | translate }}
        </label>
        <div class="col-sm-8">
            <app-drop-down [attr.disabled]="!canUpdate" type="custom" [settings]="dropdownSettings" [list]="elementTypes" formControlName="elementTypes" [selected]="form.get('elementTypes').value"> </app-drop-down>
            <small *ngIf="submitted && form.controls['elementTypes'].hasError('required')" class="text-danger form-text first-letter">
                {{ 'general.required' | translate }}
            </small>
        </div>
    </div>

    <div class="row mt-2" *ngIf="typesFormArray.controls.length > 0">
        <div class="col-sm-12">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li *ngFor="let typeTab of typesFormArray.controls; let i = index" [ngbNavItem]="i">
                    <a ngbNavLink (click)="init = true;searchTab(typeTab.get('typeId').value)">
                        <app-form-label [invalid]="typeTab.invalid" [valid]="typeTab.valid" >
                            {{ typeTab.get('title').value }}
                        </app-form-label>
                    </a>
                    <ng-template ngbNavContent>
                        <div *ngIf="tabNoProducts && !noProducts[typeTab.get('typeId').value].hasProducts; then showErrorMessage else showForm"></div>
                        <ng-template #showErrorMessage>
                            <div class="alert alert-danger" role="alert">
                                {{ 'projects.conf.errorMessageNoProducts' | translate : { dm: typeTab.get('title').value } }}
                            </div>
                        </ng-template>
                        <ng-template #showForm>
                            <form [formGroup]="typeTab">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label">{{ 'general.version' | translate }}</label>
                                    <div class="col-sm-8">
                                        <app-drop-down (click)="init = false" [attr.disabled]="!canUpdate" [type]="'single'" [list]="versionsByType[typeTab.get('typeId').value]" formControlName="version"  [selected]="typeTab.get('version').value"></app-drop-down>
                                    </div>
                                </div>
                                <ng-container *ngIf="typeTab.get('version').value && typeTab.get('version').value.length">
                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label">{{ 'breadcrumb.tags.title' | translate }} ({{ 'general.optional' | translate }})</label>
                                        <div class="col-sm-8">
                                            <app-drop-down [attr.disabled]="!canUpdate" type="custom" [settings]="dropdownSettings" [list]="tagsByType[typeTab.get('typeId').value]" formControlName="tags" [selected]="typeTab.get('tags').value"></app-drop-down>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label">{{ 'pim.element.title' | translate }}</label>
                                        <div *ngIf="elementsByType[typeTab.get('typeId').value] && elementsByType[typeTab.get('typeId').value].length; else showErrorMessage" class="col-sm-8">
                                            <app-drop-down [attr.disabled]="!canUpdate" type="custom" [settings]="dropdownSettings" [list]="elementsByType[typeTab.get('typeId').value]" formControlName="elements" [selected]="typeTab.get('elements').value"></app-drop-down>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-4 col-form-label">
                                            {{ 'projects.conf.fields' | translate }}
                                        </label>
                                        <div class="col-sm-8">
                                            <app-drop-down [attr.disabled]="!canUpdate" type="custom" [settings]="dropdownSettings" [list]="fieldsByType[typeTab.get('typeId').value]" formControlName="fields" [selected]="typeTab.get('fields').value" ></app-drop-down>
                                        </div>
                                    </div>
                                </ng-container>
                            </form>
                        </ng-template>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-6 text-left">
        <div class="btn-group">
            <button class="btn btn-danger" (click)="onBack()">
                <fa-icon [icon]="faSave"></fa-icon>
                <span class="d-none d-md-inline-block ml-2">{{ 'general.cancel' | translate }}</span>
            </button>
        </div>
    </div>
    <div class="col-6 text-right">
        <div class="btn-group">
            <button class="btn btn-primary" [disabled]="form.invalid || form.value.description === '<p></p>'"
                (click)="onSubmit()">
                <fa-icon [icon]="faSave"></fa-icon>
                <span *ngIf="!canUpdate" class="d-none d-md-inline-block ml-2">{{ 'general.next' | translate }} </span>
                <span *ngIf="canUpdate" class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }} </span>
            </button>
        </div>
    </div>
</div>
