import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ListListComponent } from "./list-list.component";
import { ListListGuard } from "./list-list.guard";
import { ListListResolver } from "./list-list.resolver";
import {
    ModalFormLanguageModule
} from "app/shared/components/modal-form-language/modal-form-language.module";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ModalFormLanguageModule],
    declarations: [ListListComponent],
    exports: [],
    providers: [ListListGuard, ListListResolver],
})
export class ListListModule {}
