<div class="card mb-2 p-0">
    <div class="card-header text-center d-flex">
        <ng-container *ngIf="!updateName">
            <h4 class="font-weight-bold text-primary mb-0 pb-0 mx-auto">{{ name }}</h4>
            <fa-icon container="body"[ngbTooltip]="'pim.actions.edit'| translate" (click)="updateName = true; nameAtOpening = name" class="text-warning font-lg pointer" [icon]="faEdit"></fa-icon>
        </ng-container>
        <ng-container *ngIf="updateName">
            <input class="form-control form-control-sm col-9 col-md-4 mx-auto text-center font-weight-bolder" type="text" [(ngModel)]="name">
            <fa-icon *ngIf="name !== nameAtOpening" (click)="saveName(name);updateName = false;" class="text-primary font-lg pointer" [icon]="faSave"></fa-icon>
            <fa-icon (click)="updateName = false; name = nameAtOpening;" class="text-danger font-lg pointer ml-2" [icon]="faClose"></fa-icon>
        </ng-container>
    </div>
    <div class="card-body p-0">
        <div class="mt-3">
            <div>
                <div role="alert" style="width: 93%;" id="size-fieldset"
                    class="ml-5  text-center alert alert-warning ">
                    {{ "general.addSubLevelTuto" | translate }}
                </div>
                <div class="ml-5 mb-2">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="expandCollapse('expand')">{{ 'datatable.expand' | translate }}</button>
                    <button type="button" class="btn btn-primary ml-1" data-dismiss="modal" (click)="expandCollapse('collapse')">{{ 'datatable.collapse' | translate }}</button>
                </div>
                <p-tree #treeListCascade [value]="list" selectionMode="checkbox"  [(selection)]="selectedValues" [filter]="true" filterMode="strict" [propagateSelectionUp]="false" [propagateSelectionDown]="false" (onNodeSelect)="onSelect($event)" (onNodeUnselect)="onUnselect()" [emptyMessage]="'general.noValueFound'| translate" draggableNodes="true" droppableNodes="true" draggableScope="self" droppableScope="self"></p-tree>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="row">
            <button class="btn btn-danger ml-auto" (click)="deleteValue()" [disabled]="currentValue == ''">
                <fa-icon [icon]="faTrashAlt"></fa-icon>
                {{ "breadcrumb.pim.value.delete" | translate }}
            </button>
            <button class="btn btn-primary mr-2 ml-1" (click)="openUpdateModal()" [disabled]="currentValue == ''">
                <fa-icon [icon]="faPlus"></fa-icon>
                {{ "breadcrumb.pim.value.update" | translate }}
            </button>

            <button class="btn btn-primary mr-2" (click)="openCreateModal()">
                <fa-icon [icon]="faPlus"></fa-icon>
                {{ 'general.field.newValue' | translate }}
            </button>

            <button class="btn btn-primary" (click)="saveList()">
                <fa-icon [icon]="faSave"></fa-icon>
                {{ 'general.save' | translate }}
            </button>
        </div>
    </div>

    <!-- @TODO: Create a shared component ? -->
    <div #createModal data-backdrop="false" class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ "breadcrumb.pim.value.create" | translate }}</h5>&nbsp; <h5 class="modal-title" *ngIf="this.selectedValue != null">  - {{'general.addIn' | translate }} {{ this.selectedValue.name }} </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="chips = []">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div role="alert"  style="width: 93%;" id="size-fieldset"
                    class="mt-2 info ml-4  text-center alert alert-warning ">
                    {{ infoAddValue }}
                </div>
                <div class="modal-body">
                    <div class="form-group p-fluid">
                        <p-chips class="form-control" [(ngModel)]="chips" allowDuplicate="false" class="w-100">
                            <ng-template let-item pTemplate="item">
                                <span>{{item}}</span>
                            </ng-template>
                        </p-chips>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="chips = []">{{ 'general.close' | translate }}</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addValue(); treeListCascade.resetFilter()" [disabled]="!chips.length">{{ 'modal.add' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
    <div #updateModal data-backdrop="false" class="modal fade" id="updateodal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ "breadcrumb.pim.value.update" | translate }}</h5>
                </div>
                <div class="modal-body">
                    <input  class="form-control" [(ngModel)]="currentValue">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'general.close' | translate }}</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="updateValue(); treeListCascade.resetFilter()">{{ 'general.save' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
