<div *ngIf="!noFormMessage; else noViewAvailable">
    <div *ngFor="let field of tab.fields | keyvalue; let j = index">
        <div class="row">
            <div class="col-12" *ngIf="field.value.sections">
                <div class="card" *ngFor="let section of field.value.sections | keyvalue">
                    <div class="card-header" id="section.key">
                        <span *ngIf="section.key != 'unnamedSection'">{{ section.key }}</span>
                        <span *ngIf="section.key == 'unnamedSection'">{{ 'general.unnamedSection' | translate }}</span>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngFor="let elementField of field.value.sections[section.key]">
                            <div class="col-4">{{ elementField.name }} : </div>
                            <div class="col-8">
                                <app-view-tab-value [data]="elementField"></app-view-tab-value>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="field.value.elementValue !== undefined || field.value.collections !== undefined" class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">{{ selectedLanguage && !selectedLanguage.isBasic && field.value.nameTranslations[selectedLanguage.internationalCode] ? field.value.nameTranslations[selectedLanguage.internationalCode] : field.value.name }}</div>
                            <div class="col-8">
                                <app-view-tab-value [data]="field.value"> </app-view-tab-value>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noViewAvailable>
    <app-element-no-field-data [link]="routerLink" buttonTitle="button.editType">
        <p>
            {{ noFormMessage }} <b> {{ noFormName }}</b>
        </p>
    </app-element-no-field-data>
</ng-template>
