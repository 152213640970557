import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { LanguageFormComponent } from "./form/language-form.component";
import { LanguageFormGuard } from "./form/language-form.guard";
import { LanguageListComponent } from "./list/language-list.component";
import { LanguageListGuard } from "./list/language-list.guard";



const languageRoutes: Routes = [
    {
        path: "",
        component: LanguageListComponent,
        canActivate: [],
        resolve: {language: LanguageListGuard},
    },
    {
        path: `:id/${MAESTRO_ROUTES.actions.update}`,
        component: LanguageFormComponent,
        resolve: { ressource: LanguageFormGuard },
        data: {},
    },
    {
        path:`${MAESTRO_ROUTES.actions.create}`,
        component:LanguageFormComponent,

    }
];

@NgModule({
    imports: [RouterModule.forChild(languageRoutes)],
    exports: [RouterModule],
})
export class LanguageRoutingModule {}
