<section class="container">
    <div class="row">
        <div [ngClass]="!displaySortableStates[variableProjectId] ?'col-11' : 'col-5'" class="p-0">
            <ng-multiselect-dropdown class="dropdown-in-tab"
                [disabled]="disabled || !field.editable"
                (onSelect)="saveValueList(values)"
                (onSelectAll)="saveValueList(values)"
                (onDeSelectAll)="saveValueList(values)"
                (onDeSelect)="saveValueList(values)"
                *ngIf="field.fieldType === 'list multiple'"
                [settings]="dropdownListSettings"
                [data]="dropdownList"
                [(ngModel)]="values"
                style="width: 100%">
            </ng-multiselect-dropdown>
        </div>
        <div class="col-6" *ngIf="displaySortableStates[variableProjectId] && displaySortableStates[variableProjectId] == true">
            <div [appSortable]="sortablejsOptionsItems" class="d-flex flex-wrap">
                <div *ngFor="let valueInput of values; let index = index" class="item-selection">
                    <div class="item-content">
                        <div class="truncate-text">
                            <p>{{ valueInput.name }}</p>
                        </div>
                        <span (click)="deleteListValue(valueInput.name)" class="font-weight-bold mr-1 cursor-pointer">x</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1" *ngIf=" values.length > 0 && true && selectedLanguage.isBasic">
            <span *ngIf="!displaySortableStates[variableProjectId]; else elseBlock">
                <fa-icon (click)="displaySortable(variableProjectId)" class="cursor-pointer text-primary" [icon]="faChevronCircleDown"></fa-icon>
            </span>
            <ng-template #elseBlock>
                <span>
                    <fa-icon (click)="displaySortable(variableProjectId)" class="cursor-pointer text-primary" [icon]="faChevronCircleUp"></fa-icon>
                </span>
            </ng-template>
        </div>
    </div>
</section>


