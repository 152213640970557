import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {SidebarContentDef} from "../../../../shared/components/sidebar/sidebar";

@Injectable()
export class SidebarService {
    readonly enable$ = new BehaviorSubject<boolean>(false);
    readonly componentDef$ = new Subject<SidebarContentDef>();
    private _enabled;
    readonly errorSubmit$ = new BehaviorSubject<boolean>(false);
    private _errorSubmit;
    readonly tabName$ = new BehaviorSubject<string>('');
    private _tabName;
    readonly tabNameTranslations$ = new BehaviorSubject<any>({});
    private _tabNameTranslations;

    /**
     * Display or not the sidebar
     */
    set enable(value: boolean) {
        if (this._enabled !== value) {
            this._enabled = value;
            this.enable$.next(value);
        }
    }

    set errorSubmit(value: boolean) {
        if (this._errorSubmit !== value) {
            this._errorSubmit = value;
            this.errorSubmit$.next(value);
        }
    }

    set currentTabName(value: any) {
        if (this._tabName !== value) {
            this._tabName = value;
            this.tabName$.next(value);
        }
    }

    set currentTabNameTranslations(value:any) {
        if (this._tabNameTranslations !== value) {
            this._tabNameTranslations = value;
            this.tabNameTranslations$.next(value);
        }
    }

    get getCurrentTabName() {
        return this.tabName$;
    }

    /**
     * Set the sidebar content
     */
    set sideBarDef(def: SidebarContentDef) {
        this.componentDef$.next(def);
    }
}
