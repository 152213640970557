import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {Router} from '@angular/router';
import {faEdit, faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Tab} from "app/shared/models";
import {Subscription} from "rxjs";
import {SidebarService} from "../../..";
import {FormDisplayedService} from "app/core/services/global/layout/form-displayed.service";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-sidebar-routing-form",
    templateUrl: "./sidebar-routing-form.component.html",
    styleUrls: ["./sidebar-routing-form.component.scss"],
})
export class SidebarRoutingFormComponent implements OnInit, OnDestroy {
    @Output() validSubmit: EventEmitter<Tab> = new EventEmitter();
    @Output() delete: EventEmitter<Tab> = new EventEmitter();
    @Input() currentMode: string;
    @Input() aclOptions: {
        delete: number;
        edit: number;
        create: number;
    };
    @Input() allOptions;
    private _errorSubmit: Subscription;
    controlInput:boolean;
    private _currentNametabs :Subscription;
    private _currentNameTranslations :Subscription;
    nameTabs;
    nameTranslationsTabs;
    formDisplayed: boolean
    private previousNameTabs: string;
    currentName: string;
    selectedLanguage : Language;
    languagesList : Language[] = [];

    constructor(fb: FormBuilder,private sidebarService:SidebarService,private _router:Router, private formDisplayedService: FormDisplayedService, private _languageService: LanguageService) {
        this.tabForm = fb.group({
            [this.tabNameInput]: ["", Validators.required],
            [this.tabNameTranslations]: [{}],
        });
    }


    ngOnInit(): void {
        this._errorSubmit = this.sidebarService.errorSubmit$.subscribe((def) => this.controlInput = def);
        this._currentNametabs = this.sidebarService.tabName$.subscribe((def) => {
            this.nameTabs = def;
            if (this.previousNameTabs !== this.nameTabs) {
                this.previousNameTabs = this.nameTabs;
                this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);

            }
        });
        this._currentNameTranslations = this.sidebarService.tabNameTranslations$.subscribe((def) => this.nameTranslationsTabs = def);

        this.formDisplayedService.formDisplayed$.subscribe(value => {
            this.formDisplayed = value;
        });

        this.sidebarService.getCurrentTabName.subscribe(data => {
            this.currentName = data;
        })

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });
    }

    ngOnDestroy() {
        this._currentNametabs.unsubscribe();
    }

    readonly faPlus = faPlus;
    readonly faEdit = faEdit;
    readonly faTrashAlt = faTrashAlt;

    readonly tabForm: FormGroup;
    readonly tabNameInput = "tabForm";
    readonly tabNameTranslations = "tabTranslationsForm";



    onCancel() {
        this.sidebarService.errorSubmit = false;
        this._closeForm();
    }

    onCreate() {
        this.tabForm.get(this.tabNameInput).setValue("");
        const obj = {};
        if (this.selectedLanguage) {
            obj[this.selectedLanguage.internationalCode] = ''
        }
        this.tabForm.get(this.tabNameTranslations).setValue(obj);

        this._openForm();
        this._changeMode("create");
    }

    onEdit() {
        if (this.tabForm.get(this.tabNameInput)) {
            this.controlInput = true
        }
        this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);
        this.tabForm.get(this.tabNameTranslations).setValue(this.nameTranslationsTabs);
        this._openForm();
        this._changeMode("edit");

    }

    onCreateSubmit() {
        if (this.tabForm.valid) {
            const nameTranslations = this.tabForm.value[this.tabNameTranslations];

            this.languagesList.forEach(language => {
                if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                    nameTranslations[language.internationalCode] = this.tabForm.value[this.tabNameInput].trim();
                }
            })
            this.validSubmit.emit({ name: this.tabForm.value[this.tabNameInput], nameTranslations: nameTranslations });
        }

        this._closeForm();
    }

    onUpdateSubmit() {
        if (this.tabForm.valid) {
            const nameTranslations = this.tabForm.value[this.tabNameTranslations];
            const internationalCode = this.selectedLanguage ? this.selectedLanguage.internationalCode : 'fr_FR';
            const basicLanguage = this._languageService.getBasicLanguage();

            this.languagesList.forEach(language => {
                if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                    nameTranslations[language.internationalCode] = nameTranslations[basicLanguage.internationalCode];
                }
            })
            nameTranslations[internationalCode] = this.tabForm.value[this.tabNameInput].trim();

            this.validSubmit.emit({
                id: 69,
                name: nameTranslations[basicLanguage.internationalCode],
                nameTranslations: nameTranslations,
            });
        }
    }

    private _closeForm() {
        this.formDisplayedService.setFormDisplayed(false);
    }

    private _changeMode(mode: "create" | "edit") {
        this.currentMode = mode;
    }

    private _openForm() {
        this.formDisplayedService.setFormDisplayed(true);
        this.controlInput = false;
    }

    isNotBasic(): boolean
    {
        return this.selectedLanguage && !this.selectedLanguage.isBasic;
    }
}
