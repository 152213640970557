import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ProjectListComponent } from "./project-list.component";
import { ProjectListResolver } from "./project-list.resolver";
import {
    ModalFormLanguageModule
} from "app/shared/components/modal-form-language/modal-form-language.module";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ModalFormLanguageModule],
    declarations: [ProjectListComponent],
    exports: [ProjectListComponent],
    providers: [ProjectListResolver],
})
export class ProjectListModule {}
