import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { TagService } from "app/core/services/tag/tag.service";
import { ActionButtonDef, ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { MaestroTags } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { TreeNode } from "primeng-lts/api";
import { Subscription } from "rxjs";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";
declare var jQuery: any;

@Component({
    selector: "app-tag-list",
    templateUrl: "./tag-list.component.html",
})
export class TagListComponent implements OnInit, OnDestroy {
    actions: ActionButtonsDef = [];
    actionUpdate: ActionButtonDef;
    actionDelete: ActionButtonDef;

    private _actionButtonClickedSource: Subscription;
    private breadcrumbLength: number;
    breadcrumb: any;

    private _url: string;
    private _redirectionPath: string;

    private _tags: MaestroTags;

    acl: ACL;

    tagNodes = [];
    selectedTags: TreeNode[];
    allExpanded: boolean = false;
    allSelected: boolean = false;
    propagateSelectionUp: boolean = false;
    propagateSelectionDown: boolean = false;
    readonly faTools = faTools;
    readonly faTrash = faTrashAlt;
    @ViewChild("createModal", { static: false }) createModal: ElementRef;
    chips: string[] = [];
    allTagsForCreation = [];
    tagsSeparator: "";

    selectedLanguage : Language;
    basicLanguage : Language;
    languagesList : Language[] = [];

    constructor(
        private _tagService: TagService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _modalService: SwalModalService,
        private _translateService: TranslateService,
        private _layout: LayoutService,
        private _authService: AuthService,
        private _treeService: TreeService,
        private _toastService: ToastService,
        private _languageService: LanguageService
    ) {}

    ngOnInit(): void {
        this.acl = this._authService.getUserAclFromToken();

        if (this.acl.SYSPAD_PIM_TAG_CREATE) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "tags.create";
            this._actionButtonClickedSource = this._layout.actionButton.click$.subscribe((_) => this._openCreateModal());
        }

        const actions = this._tagService.setActions();

        this.actionUpdate = actions.find((a) => a.type === ActionType.Update);
        this.actionDelete = actions.find((a) => a.type === ActionType.Delete);
        this._url = this._router.url;
        this._tagService.checkPath(this._url);
        this._redirectionPath = this._tagService.getRedirectionPath();

        const routerLink = this._route.snapshot.params.id ? `/${this._redirectionPath}` : null;

        this._layout.breadcrumb.setPath({ routerLink: routerLink, name: "tags.title" }, 1);

        this.breadcrumb = this._tagService.getBreadcrumb().subscribe((data) => {
            this._cleanBreadcrumb(data.length);
            this.breadcrumbLength = data.length;

            for (let i = 0; i < data.length; i++) {
                const link = i === data.length - 1 ? null : `/${this._redirectionPath}/${data[i].id}`;
                this._layout.breadcrumb.setPath({ routerLink: link, name: data[i].name }, 2 + i);
            }
        });

        this.tagsSeparator = this._translateService.instant("tags.separator", { data: this._translateService.instant("admin.roles.tag") });

        //For languages of trad
        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });

        this.basicLanguage = this._languageService.getBasicLanguage();

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });
        //END

        this._route.data.subscribe((_) => {
            this._languageService.selectedLanguage$.subscribe((language) => {
                this.selectedLanguage = language;

                this._tags = this._route.snapshot.data.tags.data;
                if (this._tags.length > 0 && this.selectedLanguage && !this.selectedLanguage.isBasic) {
                    this._tags.forEach(tag => {
                        if (tag.nameTranslations[this.selectedLanguage.internationalCode]) {
                            tag.name = tag.nameTranslations[this.selectedLanguage.internationalCode];
                        }
                    })
                }
                this.setTagNodes();
            });

        });

    }

    /**
     * Clean the breadcrumb
     * @param length
     */
    _cleanBreadcrumb(length: number): void {
        for (let i = length - 1; i >= 0; i--) {
            this._layout.breadcrumb.setPath(null, 2 + i);
        }
    }

    ngOnDestroy(): void {
        jQuery(this.createModal.nativeElement).modal("hide");
        this.breadcrumb.unsubscribe();
        this._tagService.resetBreadcrumb();
        this._cleanBreadcrumb(this.breadcrumbLength);
        if (undefined !== this._actionButtonClickedSource) {
            this._actionButtonClickedSource.unsubscribe();
        }
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
    }

    /**
     * Handle the action
     *
     * @param actionEvent
     */
    onActionClick(actionEvent: ActionButtonDef): void {
        switch (actionEvent.type) {
            case ActionType.Delete:
                this._delete();
                break;
            case ActionType.Update:
                this._openUpdateModal();
                break;
        }
    }

    /**
     * Open create modal
     */
    private _openCreateModal() {
        this.allTagsForCreation = this._treeService.getNestedData(this.tagNodes, "children");
        this.allTagsForCreation.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        jQuery(this.createModal.nativeElement).modal("show");
    }

    /**
     * Create new tag(s)
     *
     */
    _create() {
        this._tagService.createTags(this.chips, +$("#modalTagParent").val()).subscribe((res: any) => {
            this._modalService.success(this._translateService.instant("general.created"));

            this.chips = [];

            this._tags = res.data;

            this.setTagNodes();
        });
    }

    /**
     * Open update modal
     */
    private _openUpdateModal(): void {
        let htmlForm = "";
        if (this.languagesList.length > 0) {
            htmlForm += `
                    <div class="bg-light">
                        <div class="form-group pt-3">
                            <label for="modalTagTitle">${this._translateService.instant("table.label")}: ${this.selectedLanguage.label}</label>
                        </div>
                    </div>
                `;
        }

        this.selectedTags.forEach((tag: any) => {
            let allTagsWithoutCurrent = this._treeService.getNestedData(this.tagNodes, "children").filter((t) => t.id != tag.id);
            allTagsWithoutCurrent.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

            let options = '<option value="-1">' + this._translateService.instant("tags.no_parent") + "</option>";

            allTagsWithoutCurrent.forEach((t) => {
                if (tag.parent && t.id == tag.parent.id) {
                    options += '<option value="' + t.id + '" selected>' + t.label + " <strong>[Actual]</strong>" + "</option>";
                } else {
                    options += '<option value="' + t.id + '">' + t.label + "</option>";
                }
            });

            const tagName = this.selectedLanguage && !this.selectedLanguage.isBasic && tag.nameTranslations.hasOwnProperty(this.selectedLanguage.internationalCode) ? tag.nameTranslations[this.selectedLanguage.internationalCode] : tag.name;
            console.log(this.selectedLanguage && !this.selectedLanguage.isBasic && tag.nameTranslations.hasOwnProperty(this.selectedLanguage.internationalCode), tagName, tag);
            htmlForm += `
                    <div class="bg-light">
                        <div class="form-group pt-3">
                            <label for="modalTagTitle">${this._translateService.instant("tags.name")}</label>
                            <input class="form-control" type="text" id="modalTagTitle-${tag.id}" value="${tagName}">
                        </div>
                        <div class="form-group">
                            <label for="modalTagParent">${this._translateService.instant("tags.parent")}</label>
                            <select class="form-control" id="modalTagParent-${tag.id}">${options}</select>
                        </div>
                    </div>
                    <hr *ngIf="${this.selectedTags.length > 1}" class="mt-5 mb-5">
                `;
        });

        this._modalService
            .open({
                title: this._translateService.instant("tags.update"),
                confirmButtonText: this._translateService.instant("general.update"),
                confirmButtonColor: "$maestro",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                html: htmlForm,
            })
            .then((result) => {
                if (result.value) {
                    let updatedTags = [];

                    this.selectedTags.forEach((tag: any) => {
                        const nameTranslations = tag.nameTranslations || {};
                        if (this.selectedLanguage) {
                            nameTranslations[this.selectedLanguage.internationalCode] = $("#modalTagTitle-" + tag.id).val();
                        }

                        const name = this.selectedLanguage && this.selectedLanguage.isBasic ? nameTranslations[this.basicLanguage.internationalCode] : $("#modalTagTitle-" + tag.id).val();
                        const parent = $("#modalTagParent-" + tag.id).val();

                        if (undefined === tag.parent) {
                            tag.parent = -1;
                        }
                        if (tag.name !== name || (tag.parent !== null && tag.parent.id !== parent)) {
                            updatedTags.push({ id: tag.id, name: name, nameTranslations: nameTranslations, parent: parent });
                        }
                    });

                    if (updatedTags.length > 0) {
                        this._tagService.updateFormField(updatedTags).subscribe(
                            (res) => {
                                this._modalService.success(this._translateService.instant("general.saved"));

                                this.selectedTags = [];

                                this._tags = res.data;

                                this.setTagNodes();
                            },
                            () => {
                                this._modalService.error(this._translateService.instant("general.notSaved"));
                            }
                        );
                    } else {
                        this._toastService.show({ message: this._translateService.instant("general.modif.title"), type: "info" });
                    }
                }
            });
    }

    /**
     * Delete selected tags
     */
    private _delete() {
        let labels = this.selectedTags.map((t: any) => t.label);

        this._modalService
            .open({
                icon: "warning",
                title: this._translateService.instant("tags.delete"),
                confirmButtonText: this._translateService.instant("general.remove"),
                confirmButtonColor: "$maestro",
                showCancelButton: true,
                cancelButtonText: this._translateService.instant("general.cancel"),
                cancelButtonColor: "#FF0000",
                text: this._translateService.instant("tags.cascadeDelete"),
                focusCancel: true,
                footer: labels.join(", "),
            })
            .then((result) => {
                if (result.value) {
                    let ids = this.selectedTags.map((t: any) => t.id);

                    this._tagService.deleteTags(ids).subscribe((res) => {
                        this._modalService.success(this._translateService.instant("general.deleted"));

                        this.selectedTags = [];

                        this._tags = res.data;

                        this.setTagNodes();
                    });
                }
            });
    }

    setTagNodes() {
        this.tagNodes = this._treeService.createTreeNode([...this._tags], "name", "label");

        this.changeTagLabel(this.tagNodes);
    }

    changeTagLabel(tmp) {
        tmp.forEach((tag) => {
            let childrenLength = 0;

            if (undefined !== tag.children && null !== tag.children) {
                childrenLength = tag.children.length;

                if (tag.children.length) {
                    this.changeTagLabel(tag.children);
                }
            }

            tag.label = `${tag.label} (${childrenLength})`;
        });
    }

    expandRecursive(isExpanded: boolean) {
        this.allExpanded = isExpanded;

        this.tagNodes.forEach((node) => {
            this._treeService.expandRecursive(node, isExpanded);
        });
    }

    selectAll(isSelected: boolean) {
        this.allSelected = isSelected;

        if (isSelected) {
            this.selectedTags = this._treeService.getNestedData(this.tagNodes, "children");
        } else {
            this.selectedTags = [];
        }
    }
}
