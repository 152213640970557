    import {Component } from "@angular/core";
    import { FormBuilder, FormGroup, Validators } from "@angular/forms";
    import { Router } from "@angular/router";
    import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
    import { MAESTRO_ROUTES } from "app/shared/routes";
    import { AuthService } from "app/core/services/admin/auth/auth.service";
    import { activatedModules } from "src/environments/activated-modules";

    @Component({
        selector: "app-login",
        templateUrl: "./login.component.html",
    })
    export class LoginComponent{
        loginForm: FormGroup;
        isSubmitted = false;

        readonly faUser = faUser;
        readonly faKey = faKey;
        readonly route = MAESTRO_ROUTES;

        form: FormGroup;

        constructor(private _authService: AuthService, private router: Router, private formBuilder: FormBuilder) {
            this.loginForm = this.formBuilder.group({
                email: ["", Validators.required],
                password: ["", Validators.required],
            });
        }
        navigateBasedOnActivatedModules() {
            if (!activatedModules.dashboard) {
                if (activatedModules.pim) {
                    return this.router.navigate(["pim"]);
                }
                if (activatedModules.dam) {
                    return this.router.navigate(["dam"]);
                }
                if (activatedModules.project) {
                    return this.router.navigate(["projects"]);
                }
                if (activatedModules.admin) {
                    return this.router.navigate(["admin"]);
                }
            }
            return this.router.navigate(["dashboard"]);
        }


        get formControls() {
            return this.loginForm.controls;
        }
        login() {
            this.isSubmitted = true;
            if (this.loginForm.invalid) {
                return;
            }
            this._authService
                .login({
                    email: this.formControls.email.value,
                    password: this.formControls.password.value,
                })
                .subscribe(  (loggedIn) => {
                    if (loggedIn) {
                        if(this._authService.isLoggedIn()){
                            localStorage.setItem('isDisplayModal', null);
                            this.navigateBasedOnActivatedModules().then();
                        }

                    }
                })
        }

    }
