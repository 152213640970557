import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroWorkflows } from "app/shared/models";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class PimWorkflowService {
    constructor(private _http: HttpClient) {}

    getAll(): Observable<MaestroWorkflows> {
        return this._http.get<MaestroWorkflows>(`${environment.pimUrl}/workflow.json`);
    }

    deleteById(id: number): Observable<any> {
        return this._http.delete(`${environment.pimUrl}/configuration/${id}.json`);
    }

    validationStepTrad(objectId: number, stepId: number, langue: string, note: string) {
        return this._http.post<any>(`${environment.pimUrl}/element/${objectId}/place/trad.json`, {
            langue: langue,
            step: stepId,
            note: note
        });
    }
}
