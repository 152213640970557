import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { LanguageFormComponent } from "./form/language-form.component";
import { LanguageFormGuard } from "./form/language-form.guard";
import { LanguageRoutingModule } from "./language.routing";
import { LanguageListGuard } from "./list/language-list.guard";
import { LanguageListModule } from "./list/language-list.module";

@NgModule({
    declarations: [LanguageFormComponent],
    imports: [LanguageRoutingModule, LanguageListModule, SharedModule],
    exports: [LanguageFormComponent],
    providers: [LanguageListGuard, LanguageFormGuard]
})
export class LanguageModule {}
