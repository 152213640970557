import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Tab } from "app/shared/models";
import { MAESTRO_ROUTES } from "app/shared/routes";
import {Language} from "app/shared/models/language";
import {LanguageService} from "app/core/services/admin/language/language.service";

@Component({
    selector: "app-element-view-tab",
    templateUrl: "./view-tab.component.html",
})
export class ElementViewTabComponent implements OnInit {
    @Input() tab: Tab;
    noFormMessage: string;
    noFormName: string;

    readonly routerLink = ["../../../../", MAESTRO_ROUTES.pim.elementType, MAESTRO_ROUTES.actions.update];
    private readonly _noSectionMessage = `Aucun champs n'est renseigné pour la section `;

    selectedLanguage : Language;
    languagesList : Language[] = [];

    constructor(private route: ActivatedRoute, private _languageService: LanguageService) {}

    ngOnInit(): void {
        this.route.data.subscribe((data: { tab: any }) => {
            this.tab = data.tab.data;
            this._checkData();
        });

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });
    }

    _checkData() {
        if (this.tab.fields && !this.tab.fields.length) {
            this.noFormMessage = this._noSectionMessage;
            this.noFormName = this.tab.name;
        } else {
            this.noFormMessage = null;
        }
    }

    /**
     * Save name of the data model
     */
    loadData(event) {
        const selectedLanguage: Language = event.value;

        // Utilisez la fonction pour mettre à jour la langue sélectionnée
        this._languageService.setSelectedLanguage(selectedLanguage);
    }
}
