import { Component, ElementRef, OnInit, ViewChild ,AfterViewInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "app/core/services/dashboard/dashboard.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { UnsubscribePipe } from "app/shared/pipes";
import { Subscription } from "rxjs";
import { ListData, MaestroBlocData, MaestroConfiguration, MaestroDashboard, MaestroData, MaestroDataType, MaestroMethod, MaestroQuery, MaestroRequest } from "../../../../shared/models/dashboard";
import { query as getBlocs } from "../queries/bloc-list.query";
import { query as getDashboard } from "../queries/dashboard-view.query";
declare var jQuery: any;

@Component({
    selector: "app-dashboard-view",
    templateUrl: "./dashboard-view.component.html",
    styleUrls: ["./dashboard-view.component.scss"],
})
export class DashboardViewComponent implements OnInit {
    data = false;
    dashboard: MaestroDashboard;
    blocs: MaestroBlocData[] = [];
    blocDatas: MaestroBlocData[] = [];
    blocList = [];
    blocSelectable = [];
    blocSelected = [];
    configUpdated = [];
    @ViewChild("createModal", { static: false }) createModal: ElementRef;

    // Used to display examples graph

    sampleWorkflowList = {
        "Element Type's Workflow": [
            { id: "1", name: "A" },
            { id: "2", name: "B" },
            { id: "3", name: "C" },
        ],
    };

    sample = {
        0: {
            sample: {
                module: "sample",
                items: [
                    { id: 1, data: "A" },
                    { id: 2, data: "B" },
                    { id: 3, data: "C" },
                ],
            },
        },
        1: {
            sample: [
                { label: "A", data: "25" },
                { label: "B", data: "50" },
                { label: "C", data: "75" },
            ],
        },
        2: {
            sample: [
                {
                    label: "A",
                    data: "25",
                    chartDatas: [
                        { label: "a1", data: "1" },
                        { label: "a2", data: "2" },
                    ],
                },
                {
                    label: "B",
                    data: "50",
                    chartDatas: [
                        { label: "b3", data: "3" },
                        { label: "b4", data: "4" },
                    ],
                },
                {
                    label: "C",
                    data: "75",
                    chartDatas: [
                        { label: "c5", data: "5" },
                        { label: "c6", data: "6" },
                    ],
                },
            ],
        },
        3: {
            name: "Workflow",
            chartDatas: [
                {
                    label: "Step 1",
                    datas: [{ subData: "X1" }, { subData: "X3" }, { subData: "X9" }],
                },
                {
                    label: "Step 2",
                    datas: [{ subData: "X2" }, { subData: "X5" }],
                },
                {
                    label: "Step 3",
                    datas: [{ subData: "X6" }, { subData: "X7" }, { subData: "X8" }, { subData: "X10" }],
                },
            ],
        },
    };

    private _subscriptions: Subscription[] = [];
    @ViewChild("showDashboardConfigurationModal") showDashboardConfigurationModal: ElementRef<any>;

    constructor(private _route: ActivatedRoute, private _service: DashboardService, private router: Router, private _layout: LayoutService, private _unsubscribePipe: UnsubscribePipe) {}

    ngOnInit() {
        const dashboard = this._route.snapshot.data.dashboard.data;

        if (dashboard) {
            // TODO : change status code when data = null
            this.data = true;

            this.dashboard = dashboard.getDashboard;

            this.extractQueries(this.dashboard);

            this.setLayout();
        } else {
            this._service.getDashboardData(getDashboard).subscribe((res) => {
                if (res.data) {
                    this.data = true;
                    this.dashboard = res.data.getDashboard;
                    this.extractQueries(this.dashboard);
                    this.setLayout();
                }
            });
        }
    }

    setLayout() {
        this._layout.sidebar.enable = false;
        this._layout.actionButton.enable = this.dashboard ? true : false;
        this._layout.actionButton.title = "configureDashboard";
        this._layout.actionButton.icon = "pi pi-cog";
        this._subscriptions.push(this._layout.actionButton.click$.subscribe((_) => this.showDashboardConfiguration()));
    }

    ngOnDestroy() {
        jQuery(this.showDashboardConfigurationModal.nativeElement).modal("hide");
        this._unsubscribePipe.transform(this._subscriptions);
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.actionButton.icon = null;
    }

    extractQueries(dashboard: MaestroDashboard) {
        if (undefined !== dashboard && undefined !== dashboard.configurations) {
            let configToExtract;
            const userConfiguration: MaestroConfiguration = dashboard.configurations.find((conf) => conf.default === false && conf.active === true);

            if (userConfiguration) {
                configToExtract = { ...userConfiguration };
            } else {
                const defaultConfiguration: MaestroConfiguration = dashboard.configurations.find((conf) => conf.default === true && conf.active === true);
                configToExtract = { ...defaultConfiguration };
            }

            for (const configurationToBloc of configToExtract.configurationToBlocs) {
                let list: number;
                let dataset: number;

                const dataTypes: MaestroDataType[] = configurationToBloc.bloc.dataTypes;

                list = dataTypes.findIndex((item) => item.type === "list"); // dataTypes.map(e => e.type).indexOf('list')
                dataset = dataTypes.findIndex((item) => item.type === "dataset"); // dataTypes.map(e => e.type).indexOf('dataset');

                let dataTypeList: MaestroRequest = {};
                let dataTypeDataset: MaestroRequest = {};

                if (list !== -1) {
                    dataTypeList = this.buildQueries(dataTypes[list]);
                }

                if (dataset !== -1) {
                    dataTypeDataset = this.buildQueries(dataTypes[dataset]);
                }

                const listNotEmpty = Object.keys(dataTypeList).length > 0 ? true : false;
                const bloc: MaestroBlocData = {
                    // ['id']: configurationToBloc.bloc.id,
                    ["name"]: configurationToBloc.bloc.name,
                    ["type"]: configurationToBloc.bloc.blocType.type,
                    ["level"]: configurationToBloc.bloc.blocType.level,
                    ["typeName"]: configurationToBloc.bloc.blocType.name,
                    ["position"]: configurationToBloc.position,
                    ["methods"]: listNotEmpty ? dataTypeList["methods"] : dataTypeDataset["methods"], // TODO : see if duplicate (/*methods.filter((v, i, a) => a.indexOf(v) === i)*/ // Get an array with unique values // see for Set object method)
                    ["subMethods"]: listNotEmpty ? dataTypeDataset["methods"] : null,
                    ["query"]: listNotEmpty ? dataTypeList["query"] : dataTypeDataset["query"],
                    ["subQuery"]: listNotEmpty ? dataTypeDataset["query"] : null,
                };

                this.blocs.push(bloc);
            }

            this.executeQueries(this.blocs);
        }
    }

    buildQueries(dataType: MaestroDataType) {
        let properties = "";
        let queries = "";
        const methods: MaestroMethod[] = [];
        const dataTypeType = "query";

        dataType.dataOptions.forEach((dataOption, i) => {
            dataOption.dataOptionProperties.forEach((dataOptionProperty, j) => {
                if (j + 1 === dataOption.dataOptionProperties.length) {
                    properties += `${dataOptionProperty.property}`;
                } else {
                    properties += `${dataOptionProperty.property}, `; // Note the " ," for concatenation
                }
            });

            if (i + 1 === dataType.dataOptions.length) {
                dataOption.parameter ? (queries += `${dataOption.method}${dataOption.parameter}{${properties}}`) : (queries += `${dataOption.method}{${properties}}`);
            } else {
                dataOption.parameter ? (queries += `${dataOption.method}${dataOption.parameter}{${properties}}, `) : (queries += `${dataOption.method}{${properties}}, `); // Note the " ," for concatenation
            }

            properties = "";

            const methodDataOptionName: MaestroMethod = {
                [dataOption.method]: dataOption.name,
            };
            methods.push(methodDataOptionName);
        });

        const query: MaestroQuery = {
            [dataTypeType]: `{${queries}}`,
        };

        const data: MaestroRequest = {
            methods: methods,
            query: query,
        };

        return data;
    }

    executeQueries(blocs: MaestroBlocData[]) {
        let maestroDataOrList: MaestroData | ListData = {};

        let globalQuery = "{"; // Encapsulate all queries

        for (const bloc of blocs) {
            globalQuery += bloc.query.query.substring(1, bloc.query.query.length - 1) + ","; // bloc.query = MaestroQuery | bloc.query.query = property value
        }

        globalQuery += "}";

        const maestroQuery: MaestroQuery = { query: globalQuery };

        this._service.getDashboardData(maestroQuery).subscribe((res) => {
            for (const bloc of blocs) {
                for (const method of bloc.methods) {
                    for (const [key, value] of Object.entries(method)) {
                        const renamedData: MaestroData | ListData = {
                            [value]: res.data[key],
                        };

                        Object.assign(maestroDataOrList, renamedData);
                    }
                }

                this.blocDatas.push({ ...bloc, data: maestroDataOrList });

                // TODO : find a better way to order
                this.blocDatas.sort((a, b) => {
                    return a.position - b.position;
                });

                maestroDataOrList = {};
            }
        });
    }

    showDashboardConfiguration() {
        if (this.dashboard) {
            jQuery(this.showDashboardConfigurationModal.nativeElement).modal("show");

            this._service.getDashboardData(getBlocs).subscribe((res) => {
                this.blocList = res.data.getBlocs;
            });
        }
    }

    getBlocSelected(config: any) {
        let configurationToBlocs = config.configurationToBlocs;

        configurationToBlocs.sort(function (a, b) {
            return a.position - b.position;
        });

        this.blocSelectable[config.id] = [];
        this.blocSelected[config.id] = [];

        this.blocSelected[config.id] = configurationToBlocs.map((blocConf) => {
            return blocConf.bloc;
        });

        this.blocSelectable[config.id] = this.blocList.filter((object1) => {
            return !this.blocSelected[config.id].some((object2) => {
                return object1.id === object2.id;
            });
        });
    }

    saveDashboardConf() {
        let configs = [];

        Object.entries(this.configUpdated).forEach(([key, value]) => {
            let baseConf = this.dashboard.configurations.find((bconf) => {
                return +bconf.id == +key;
            });

            let blocs = Object.entries([...value]).map(([key, val]) => {
                let pos = +key;
                return { blocId: +val.id, position: ++pos };
            });

            let config = {
                id: +baseConf.id,
                name: baseConf.name,
                type: baseConf.type,
                active: baseConf.active,
                default: baseConf.default,
                blocs: blocs,
                createdBy: +baseConf.createdBy,
            };

            configs.push(config);
        });

        const operationName = "ManageConfiguration";
        const query: MaestroQuery = {
            query: `
            mutation ${operationName}($data: [ConfigurationInput!]!) {
                  manageConfiguration(manageConfigurationInput: $data) {
                    	id
                    }
                }
            `,
            variables: { data: configs },
            operationName: operationName,
        }; // @TODO: See why empty array returned

        // Reload dashboard
        this._service.getDashboardData(query).subscribe((res) => {
            setTimeout(() => {
                this._route.snapshot.data.dashboard.data = null;
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = "reload";
                this.router.navigate(["/"]);
            }, 1000); // @TODO: Temporary, find a better way to do a refresh
        });
    }

    updateConfig(configId: number, configActive: boolean) {
        this.addConfInArray(configId);
        this.manageConfigActivation(configId, configActive);
    }

    manageConfigActivation(configId: number, configActive: boolean) {
        if (configActive) {
            let configs = this.dashboard.configurations.filter((conf) => {
                if (conf.default === false && conf.active === true && conf.id !== configId) {
                    return conf;
                }
            });

            configs.forEach((conf) => {
                conf.active = false;

                this.getBlocSelected(conf);

                this.addConfInArray(conf.id);
            });
        }
    }

    addConfInArray(configId: number) {
        if (!this.configUpdated[configId]) {
            this.configUpdated[configId] = [];
        }

        this.configUpdated[configId] = [...this.blocSelected[configId]];
    }
}
