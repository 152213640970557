import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { PimWorkflowService } from "app/core/services/admin/workflow/pim-workflow.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { ElementService } from "app/core/services/pim/element.service";
import { ElementExportService } from "app/core/services/pim/export.service";
import { VersionService } from "app/core/services/pim/version.service";
import { TagService } from "app/core/services/tag/tag.service";
import { WorkflowService } from "app/core/services/workflow/workflow.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons/action";
import { SidebarRoutingComponent, SidebarRoutingOptions } from "app/shared/components/sidebar";
import { ACL } from "app/shared/models/acl";
import { Language } from "app/shared/models/language";
import { Tab } from "app/shared/models/pim/element-resources.model";
import { SelectedTagsPipe } from "app/shared/pipes/selected-tags.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { TreeNode } from "primeng-lts/api";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { environment } from "src/environments";
import { activatedModules } from "src/environments/activated-modules";
import Swal from "sweetalert2";
import { Element, ElementResources, ElementVersions, ElementWorkflow } from "../../../../../shared/models/pim/element-resources.model";

@Component({
    selector: "app-element-update",
    templateUrl: "./element-update.component.html",
    providers: [NgbActiveModal],
})
export class ElementUpdateComponent implements OnInit, OnDestroy {
    workflow: ElementWorkflow;
    tagNodes = [];
    selectedTags: TreeNode[] = [];
    showTagList: boolean = false;
    propagateSelectionUp: boolean = false;

    versions: ElementVersions;
    element: Element;
    setValue: boolean;
    listActuel: any;

    versionName: string;
    noTab: boolean;
    noTabMessage: string;
    noTabName: string;

    readonly routerLink = ["../../../", MAESTRO_ROUTES.pim.elementType, MAESTRO_ROUTES.actions.update];
    readonly faSave = faSave;
    readonly actions: ActionButtonsDef;
    readonly settings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        allowSearchFilter: true,
        searchPlaceholderText: this._translate.instant("general.search"),
        enableCheckAll: false,
        noDataAvailablePlaceholderText: this._translate.instant("general.no.data"),
    };

    newVersion: FormGroup;

    private _ngUnsubscribe: Subject<void>;

    @ViewChild("versionSwal", { static: true }) versionSwal: SwalComponent;

    tagAsSalabilityIndicator: boolean = environment.tagAsSalabilityIndicator;

    // Exemple de données factices représentant une liste de langues
    languagesList: Language[] = [];
    selectedLanguage : Language;

    constructor(
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _translate: TranslateService,
        private _selectedTagsPipe: SelectedTagsPipe,
        private _swalModalService: SwalModalService,
        private _tagService: TagService,
        private _toaster: ToastService,
        private _loader: SpinnerService,
        private _elementService: ElementService,
        private _exportElementService: ElementExportService,
        private _versionService: VersionService,
        private _swalTargets: SwalPortalTargets,
        private _fb: FormBuilder,
        private _workflowService: WorkflowService,
        private _pimWorkflowService: PimWorkflowService,
        private _router: Router,
        private _userService: UserService,
        private _treeService: TreeService,
        private _dataService: DataService,
        private cdRef: ChangeDetectorRef,
        private _languageService: LanguageService
    ) {
        this.actions = [];
        this.setValue = false;
        this._ngUnsubscribe = new Subject();
        this.noTabMessage = this._translate.instant("pim.element.noTabs");
    }

    ngOnInit() {
        this._setActions();

        let acl = this._userService.getUserAclFromToken();

        this.newVersion = this._fb.group({
            origin: ["", Validators.required],
            existing: [""],
            new: [""],
            tags: [],
        });

        /**
         * Get data from the resolver
         */
        this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: ElementResources }) => {
            this.workflow = data.resources.workflow;
                        
            this.setValue = data.resources.workflow.authoSetValue;
            
            this._elementService.currentAuth.next(this.setValue);
            this.setTagNodes(data.resources.tags);
            this.versions = {
                allVersions: [],
                elementVersions: [],
                validVersions: [],
            };
            this.element = data.resources.element;
            this.routerLink.push(this.element.elementTypeId.toString());
            if (this.element.tabs.length > 0 && !window.location.href.includes("media")) {
                this._elementService.currentElement.next(this.element);
                this._elementService.currentTab.next(this.element.tabs[0]);
                this._router.navigate([`${this.element.tabs[0].id}`], { relativeTo: this._route, skipLocationChange: true });
            }
            if (activatedModules.dam && acl.SYSPAD_PIM_MEDIA_READ) {
                this.element.tabs.push({ id: "media", name: this._translate.instant("pim.media"), fields: null });
            }
            let referent = this.element.referent ? this.element.referent : this.element.id;

            /**
             * Get all versions information about this element
             */
            this._versionService
                .getVersionValid(referent)
                .pipe(map((r) => r.data.list))
                .subscribe((versions) => {
                    this.versions.validVersions = versions;
                });
            this._versionService
                .getVersions()
                .pipe(map((r) => r.data.list))
                .subscribe((versions) => {
                    this.versions.allVersions = versions;
                });
            this._versionService
                .getVersionsByElement(referent)
                .pipe(map((r) => r.data.list))
                .subscribe((versions) => {
                    this.versions.elementVersions = versions;
                    this._setVersionName();
                });

            this._initSideBar();
        });

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });

        this.initSelectedLanguage();
    }

    ngOnDestroy(): void {
        this._layout.generalActionButtons.enable = false;
        this._layout.generalActionButtons.actions = null;
        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
        this.element = null;
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Add a new tag to the element
     */
    addTag(event: any): void {
        // const ids = this.getNodeIdWithParent(event.node); // @TOKEEP : Propagate to parent(s)
        const ids = [event.node.id];
        this._loader.disable();
        this._tagService.addTag(this.element.id, ids).subscribe(
            () => {
                this._toaster.show({
                    message: this._translate.instant("general.addedTag"),
                    type: "success",
                });
                this._loader.activate();
            },
            (err: HttpErrorResponse) => {
                this._loader.activate();
            }
        );
    }

    /**
     * Remove a tag from the element
     */
    removeTag(event: any): void {
        // const ids = this.getNodeIdWithParent(event.node); // @TOKEEP : Propagate to parent(s)
        const ids = [event.node.id];

        this._loader.disable();
        this._tagService.removeTag(this.element.id, ids).subscribe(
            () => {
                this._toaster.show({
                    message: this._translate.instant("general.deletedTag"),
                    type: "success",
                });
                this._loader.activate();
            },
            (err: HttpErrorResponse) => {
                this._loader.activate();
            }
        );
    }

    /**
     * Open version modal
     */
    onNewVersion(): void {
        this.newVersion.controls["tags"].setValue([...this.selectedTags]);
        this.versionSwal.fire();
    }

    /**
     * Save the element's name
     */
    onSaveName(name: string, nameTranslations: object, selectedLanguage: Language): void {
        this._elementService.changeName(this.element.id, name, nameTranslations).subscribe((data) => {
            this.element = { ...this.element, ...{ name: data.data.nameTranslations[selectedLanguage.internationalCode], nameTranslations: data.data.nameTranslations, dateUpdate: data.data.dateUpdate } };
        });
    }

    /**
     * Prepare for creation version
     */
    createVersion(action: string): void {
        const existing = this.newVersion.value.existing;
        const origin = this.newVersion.value.origin;
        const n = this.newVersion.value.new;

        if (!n && !existing) {
            return;
        }

        let newV: any;
        let tagIds = this.newVersion.value.tags.map((t) => {
            return t.id;
        });
        if (action === "old") {
            newV = { version_id: existing, element_id: origin, tags: tagIds };
        } else if (action === "new") {
            newV = { version: n, element_id: origin, tags: tagIds };
        }
        this._createNewVersion(newV);
    }

    /**
     * Trigger a workflow action
     */
    actionWorkflow(event: any): void {
        this._workflowService.validationWorkflow(environment.pimUrl, { object_id: this.element.id, transition: event.key, note: event.note }).subscribe((data) => {
            this._elementService.getElementResources(this.element.id).subscribe((resources: ElementResources) => {
                this.workflow = resources.workflow;

                this.setValue = resources.workflow.authoSetValue;
                this._elementService.currentAuth.next(this.setValue);
                let referent = this.element.referent ? this.element.referent : this.element.id;

                this._versionService
                    .getVersionValid(referent)
                    .pipe(map((r) => r.data.list))
                    .subscribe((versions) => {
                        this.versions.validVersions = versions;
                    });
                this._versionService
                    .getVersions()
                    .pipe(map((r) => r.data.list))
                    .subscribe((versions) => {
                        this.versions.allVersions = versions;
                    });
                this._versionService
                    .getVersionsByElement(referent)
                    .pipe(map((r) => r.data.list))
                    .subscribe((versions) => {
                        this.versions.elementVersions = versions;
                        this._setVersionName();
                    });
            });
        });
    }

    /**
     * Change version
     */
    onVersionChange(version: any): void {
        this._versionService.elementsByVersion(this.element.referent, version.id, version.version_number).subscribe((data) => {
            this.element = data.data;
            this.selectedTags = data.data.tagView;

            this._layout.sidebar.sideBarDef = null;

            this._initSideBar();

            this._router.navigate(["/pim", "element", "update", this.element.id]);
        });
    }

    /**
     * Create a new version
     */
    private _createNewVersion(version: any): void {
        this._versionService.createVersion(version).subscribe((data) => {
            data = data.data;
            this.onVersionChange({ id: data.versionId, version_number: data.version_number });
            this.newVersion.reset({
                origin: "",
                existing: "",
                new: "",
                tags: [],
            });
            Swal.close();
        });
    }
    /**
     * Init sidebar
     */
    private _initSideBar() {
        if (this.element.tabs && this.element.tabs.length) {
            const mediaTab = this.element.tabs.find((t) => t.id == "media");

            if (!mediaTab) {
                this.element.tabs.push({ id: "media", name: this._translate.instant("pim.media"), fields: null });
            }

            this._layout.sidebar.enable = true;
            this._layout.sidebar.sideBarDef = {
                component: SidebarRoutingComponent,
                options: <SidebarRoutingOptions>{
                    absoluteRoute: `/pim/element/update/${this.element.id}`,
                    data: this.element.tabs,
                    dataDontMove: [],
                    aclOptions: {
                        create: 0,
                        delete: 0,
                        edit: 0,
                    },
                    clicked: (tab: Tab) => this.setCurrentTab(tab),
                },
            };
        }
    }

    /**
     * Change tab
     */
    setCurrentTab(tab: Tab): void {
        this._elementService.currentElement.next(this.element);
        this._elementService.currentTab.next(tab);
    }

    /**
     * Set the actions for the page
     */
    private _setActions(): void {
        const acl: ACL = this._userService.getUserAclFromToken();

        if (acl.SYSPAD_PIM_ELEMENT_READ) {
            this.actions.push({ type: ActionType.View, right: "SYSPAD_PIM_ELEMENT_READ" });
            this.actions.push({ type: ActionType.DownloadPdf, right: "SYSPAD_PIM_ELEMENT_READ" });
        }
        // if (acl.SYSPAD_PIM_ELEMENT_READ) {
        //     this.actions.push({ type: ActionType.Export, right: "SYSPAD_PIM_ELEMENT_READ" });
        // }
        if (acl.SYSPAD_PIM_ELEMENT_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_ELEMENT_DELETE" });
        }

        this._layout.generalActionButtons.enable = true;
        this._layout.generalActionButtons.actions = this.actions;

        this._layout.generalActionButtons.click$.subscribe((event: any) => {
            if (event === ActionType.Delete && this.element) {
                this._elementService.deleteElements([this.element.id]).subscribe(() => {
                    this._router.navigate(["/pim", "element"]);
                });
                return;
            }
            if (event === ActionType.View) {
                this._router.navigate(["/pim", "element", "view", this.element.id]);
                return;
            }
            if (event === ActionType.DownloadPdf) {
                this._elementService.exportPdf([this.element.id]).subscribe((res) => {
                    this._dataService.downloadFile(res.data.blob, res.data.fileName);
                });
                return;
            }
            switch ((event as ActionEvent).action) {
                case ActionType.Export:
                    this._exportElementService.generate(this.element.id, event["export"]);
                    break;
            }
        });
    }

    /**
     * Set version name
     */
    private _setVersionName(): void {
        const versionElement = this.versions.elementVersions.filter((version) => version.id === this.element.version)[0];
        this.versionName = versionElement.title + " - v." + this.element.version_number.toFixed(2);
    }

    setTagNodes(tags: any) {
        this.tagNodes = this._treeService.createTreeNode([...tags], "name", "label");

        this.selectTagLabel(this.tagNodes);
    }

    selectTagLabel(tmp: any) {
        tmp.forEach((tag) => {
            if (tag.selected) {
                this.selectedTags.push(tag);
            }

            if (undefined !== tag.children && null !== tag.children) {
                this.selectTagLabel(tag.children);
            }
        });
    }

    getNodeIdWithParent(node: any) {
        let ids = [];

        ids.push(node.id);

        if (undefined !== node.parent && null !== node.parent) {
            ids = [...ids, ...this.getNodeIdWithParent(node.parent)];
        }

        return ids;
    }

    initSelectedLanguage()
    {
        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });
    }

    getBasicLanguage() : Language
    {
        const basicLanguage = this.languagesList.find(language => language.isBasic);

        if (basicLanguage) {
            return basicLanguage;
        }

        // Si aucune langue de base n'est trouvée, retourner une valeur par défaut
        return {
            id: 0,
            label: 'Français',
            icon: '',
            code: 'fr',
            internationalCode: 'fr_FR',
            isVisible: true,
            isBasic: true
        };
    }

    onExpand(event) {
        this.listActuel = event.node;
    }

    addValue() {
        this._swalModalService.confirmText({}).then((result) => {
            if (result.isConfirmed) {
                this.listActuel.children.push({
                    children: null,
                    id: 100,
                    key: result.value.toLowerCase(),
                    label: result.value,
                    name: result.value,
                    parent: this.listActuel,
                    selected: false,
                });
            }
        });
    }

    validStepTrad(event){
        this._pimWorkflowService.validationStepTrad(this.element.id, event.step, event.langue, event.note).subscribe((data) => {
            this._languageService.setTransitionsLanguage(data.data);

            this.workflow.display.forEach((transition) => {
                if(transition.startPlace.id == event.step){
                    transition.startPlace.languages.forEach(language => {
                        if(language.id == event.langue){
                            language.valid = !language.valid;
                        }
                    });
                }
                if(transition.endPlace.id == event.step){
                    transition.endPlace.languages.forEach(language => {
                        if(language.id == event.langue){
                            language.valid = !language.valid;
                        }
                    });
                }
            });
            
            this._languageService.setValidLanguage(this._languageService.getValidLanguage());
        });
    }
}
