import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, Router, UrlTree } from "@angular/router";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { Language } from "app/shared/models/language";
import { Observable } from "rxjs";

@Injectable()
export class LanguageListGuard implements Resolve<Language>, CanActivate {
    constructor(private _service: LanguageService, private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Language> {
         return this._service.getAll();
    }
}
