<swal #createModal [showCloseButton]="true" [showConfirmButton]="true" confirmButtonText="{{'general.save' | translate}}">
    <div *swalPortal>
        <form [formGroup]="form" >
            <div class="modal-body">
                <div class=" text-left alert-info p-2" style="background-color: #e0f3f8"><i class=" pi pi-info-circle "></i> {{ 'admin.users.choiceLanguage' | translate }}</div>
                <div class="text-left mt-3 ">
                    <label>{{ 'admin.users.systemLanguage' | translate }}</label>
                    <select formControlName="systemLanguage" class="form-control">
                        <option value="fr" >{{ "general.lang.french" | translate }}</option>
                        <option value="en" >{{ "general.lang.english" | translate }}</option>
                    </select>
                </div>
                <div class="mt-2 text-left">
                    <label>{{ 'admin.users.contentLanguages' | translate }}</label>
                    <p-multiSelect  display="chip" optionLabel="label" optionValue="internationalCode"
                                    [options]="languagesList"
                                    formControlName="contentLanguages"
                                    [style]="{'width':'100%'}"
                                    defaultLabel="{{ 'admin.users.contentLanguages' | translate }}">
                    </p-multiSelect>
                </div>
                <div class="alert-warning p-2 mt-3 ">
                    <a>
                        <i class="pi pi-info-circle"></i> {{ 'admin.users.warningChoiceLanguage' | translate }}
                        <span (click)="redirectUserConfig($event)" class="user-link">{{ 'admin.users.interfaceAdmin' | translate }}</span>
                    </a>

                </div>
            </div>
        </form>
    </div>
</swal>



