import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import { Router } from "@angular/router";
import { faEyeSlash, faPaperPlane, faSave } from "@fortawesome/free-regular-svg-icons";
import { faArrowsAltH, faEdit, faEye, faLink, faList, faPlusCircle, faSort, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NgbNav, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { FieldsetService } from "app/core/services/pim/fieldset.service";
import { ListService } from "app/core/services/pim/list.service";
import { ListCascadeService } from "app/core/services/pim/listcascade.service";
import { TabService } from "app/core/services/pim/tab.service";
import { fieldTypes } from "app/shared/models/field-type.enum";
import { TreeNode } from "primeng-lts/api";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {LanguageService} from "app/core/services/admin/language/language.service";
import {Language} from "app/shared/models/language";

@Component({
    selector: "app-element-type-update-tab",
    templateUrl: "./element-type-update-tab.component.html",
    styleUrls: ["./element-type-update-tab.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ElementTypeUpdateTabComponent implements OnInit, OnDestroy {
    formArray: FormArray;
    formLinkInputRef: FormGroup;
    sections: any[];
    hasFields: boolean;
    currentSection: number = 0;

    lists: any[];
    fieldsets: any[];
    inputs: any[];
    elementTypes: any[];
    listcascades: any[];
    tooltipText: string;
    @ViewChild("nav") nav: NgbNav;
    actualField: number;
    actualSection: FormGroup;

    readonly faPlus = faPlusCircle;
    readonly faSave = faSave;
    readonly faList = faList;
    readonly faPreview = faEye;
    readonly faNoPreview = faEyeSlash;
    readonly faTrash = faTrash;
    readonly faEdit = faEdit;
    readonly faSort = faSort;
    readonly faClose = faTimes;
    readonly faSend = faPaperPlane;
    readonly faArrowsAltH = faArrowsAltH;
    readonly faLink = faLink;
    readonly lang: string;
    isSortable: boolean = true;
    /**
     * Sortable config for sections
     */
    readonly sortableJSOptionsSection = {
        ghostClass: ".non-sortable",
        handle: ".sortable-section",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.formArray.controls, from, to);
            array_move(this.formArray.value, from, to);
            array_move(this.sections, from, to);
            this.nav.select(to);
            this.formArray.markAsDirty();
        }.bind(this),
        onMove: (event: any) => {
            if (event.related.className.includes("non-sortable")) {
                return false; // Empêche le tri si l'élément est non triable
            }
        },
    };

    /**
     * Sortable config for fields
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.formArray.controls[this.currentSection].controls, from, to);
            array_move(this.formArray.value[this.currentSection], from, to);
            this.formArray.markAsDirty();
        }.bind(this),
    };

    private _tab: any;
    private _elementType: any;
    private _ngUnsubscribe: Subject<void>;

    fieldTypes = fieldTypes;

    updateName: boolean;
    currentOpen: number;

    @ViewChild("changeSectionModal") private _changeSection: SwalComponent;
    @ViewChild("linkInputRef") private linkInputRef: SwalComponent;
    currentControlIndex: number;
    title: string = "";
    currentTable: string = ""; // Table in JSON
    currentSectionForm: FormGroup;
    tabTypeList = [];
    selectedSectionId;
    selectedSectionOrTab: TreeNode;
    selectedLanguage : Language;
    languagesList : Language[] = [];

    constructor(
        private _elementTypeService: ElementTypeService,
        private _fieldsetService: FieldsetService,
        private _listService: ListService,
        private _listcascadeService: ListCascadeService,
        private _fb: FormBuilder,
        private _translate: TranslateService,
        private _modal: SwalModalService,
        private _swalPortalTargets: SwalPortalTargets,
        private cdRef: ChangeDetectorRef,
        private _tabService: TabService,
        private _treeService: TreeService,
        private _router: Router,
        private _languageService: LanguageService
    ) {
        this._ngUnsubscribe = new Subject();
        this.hasFields = false;
        this.sections = [];
        this.fieldTypes = this.fieldTypes.sort((a, b) => this._translate.instant("fieldType." + a).localeCompare(this._translate.instant("fieldType." + b)));
    }

    ngOnInit(): void {
        this._elementTypeService.currentElementType.pipe(takeUntil(this._ngUnsubscribe)).subscribe((elementType) => {
            this._elementType = elementType;
        });

        this._elementTypeService.currentTab.pipe(takeUntil(this._ngUnsubscribe)).subscribe((tab) => {
            this._tab = tab;
            this._initData();
            this.canChangeTab();
            this.formArray.statusChanges.subscribe((data) => {
                this.canChangeTab();
            });
        });

        this._listService
            .getLists()
            .pipe(map((r) => (r as any).data))
            .subscribe((lists) => {
                this.lists = lists;
            });

        this._fieldsetService
            .getFieldsets()
            .pipe(map((r) => (r as any).data))
            .subscribe((fieldsets) => {
                this.fieldsets = fieldsets;
            });

        this._fieldsetService
            .getInputs()
            .pipe(map((r) => (r as any).data))
            .subscribe((inputs) => {
                this.inputs = inputs;
            });

        this._elementTypeService
            .getElementTypes()
            .pipe(map((r) => (r as any).data))
            .subscribe((elementTypes) => {
                this.elementTypes = elementTypes;
            });

        this._languageService.languageOptions$.subscribe((languages) => {
            this.languagesList = languages;
        });

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
            this.changeLang()
        });

        this._listcascadeService.getListCascades().pipe(map((r) => (r as any).data)).subscribe((listcascades) => {
            this.listcascades = listcascades;
        });
    }

    ngOnDestroy(): void {
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
    }

    /**
     * Update current section when switching
     * @param event
     */
    onTabChange(event: NgbNavChangeEvent): void {
        this.currentSection = event.nextId;
    }

    /**
     * Update section's name
     * @param section
     * @param id
     */
    saveName(section: any, id: number): void {
        let otherSection = this.sections.filter((sec, i) => i !== id);
        let sectionsName = otherSection.map((sec) => sec.name);
        if (($("#section-name-" + id).val() as string).trim() === "") {
            section = $("#section-name-" + id).css("border-color", "red");
            $("#faSave-:disabled" + id);
            this.tooltipText = this._translate.instant("pim.elementTypes.error.emptySection");
        } else if (sectionsName.includes($("#section-name-" + id).val())) {
            section = $("#section-name-" + id).css("border-color", "red");
            $("#faSave-:disabled" + id);
            this.tooltipText = this._translate.instant("pim.elementTypes.error.section");
        } else {
            this.isSortable = true;
            section.name = $("#section-name-" + id).val();
            this.updateName = false;
            this.tooltipText = null;
            this.saveTab(false, section.id);
        }
    }

    /**
     * Add a new section
     * this.saveTab(true)
     */
    addSection(): void {
        this.isSortable = false;
        this.sections.push({ id: this._generateId(), name: this._translate.instant("general.newSection"), nameTranslations: {}, fields: [] });
        this.formArray.push(this._fb.array([]));
        this.nav.select(this.formArray.length - 1);
        this.currentSection = this.formArray.length - 1;
        this.updateName = true;
        this.currentOpen = this.formArray.length - 1;
    }

    /**
     * Delete a section
     *
     * @param section
     * @param index
     */
    deleteSection(section: any, index: number): void {
        this.updateName = false;
        this._modal.deleteSection().then((result) => {
            if (result.value) {
                this.formArray.removeAt(index);
                this.sections = this.sections.filter((s) => s !== section);
                this.currentSection = 0;
                setTimeout(() => {
                    this.nav.select(0);
                }, 100);
                this.saveTab();
            }
        });
    }

    /**
     * Delete a field
     *
     * @param formArray
     * @param index
     */
    deleteField(formArray: FormArray, index: number): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                formArray.removeAt(index);
                this.saveTab();
            }
        });
    }

    /**
     * Move a field from a section to another
     *
     * @param formArray
     * @param sectionFromId
     * @param index
     */
    openChangeSectionModal(formArray: FormArray, sectionFromId: number, index: number): void {
        this.selectedSectionId = sectionFromId;

        this.setTabTypeList();

        this._changeSection.fire().then((result) => {
            if (result.value && undefined !== this.selectedSectionOrTab && null !== this.selectedSectionOrTab) {
                const type = this.selectedSectionOrTab.type;
                let newSectionId;
                const field = formArray.value[index];
                field.source = (formArray.controls[index] as FormGroup).controls.source.value;

                if ("section" === type && this.selectedSectionOrTab.parent.data == this._tab.id) {
                    newSectionId = this.selectedSectionOrTab.data;

                    if (sectionFromId != newSectionId && sectionFromId !== -1 && newSectionId != -1 && field.id !== -1) {
                        this.sections.find((s) => s.id == newSectionId).fields.push(field);
                        this.sections.find((s) => s.id == sectionFromId).fields = this.sections.find((s) => s.id == sectionFromId).fields.filter((f) => f.id != field.id);
                        this.formArray = this._fb.array([], [this.uniqueValues(), this.uniqueValueFieldName()]);
                        this.sections.forEach((s) => {
                            const fA = this._fb.array([]);
                            s.fields.forEach((f) => {
                                fA.push(
                                    this._fb.group({
                                        id: [f.id, Validators.required],
                                        name: [f.name, [Validators.required]],
                                        nameTranslations: [f.nameTranslations || ""],
                                        protocol: [f.protocol || ""],
                                        fieldType: [f.fieldType, Validators.required],
                                        source: [{value: f.source, disabled: true}],
                                        multiple: [f.multiple || false],
                                        previewable: [f.previewable || f.preview || false],
                                        mandatory: [f.mandatory || false],
                                        salabilityIndicator: [f.salabilityIndicator || false],
                                        defaultValue: [f.defaultValue || null],
                                        translatable: [f.translatable || null],
                                        collectionReferent: [f.collectionReferent || null]
                                    })
                                );
                            });

                            this.formArray.push(fA);
                        });
                        this.formArray.markAsDirty();
                        this.saveTab();
                    }
                } else {
                    let newTabId;
                    let newIndex;

                    if ("tab" === type) {
                        newTabId = this.selectedSectionOrTab.data;
                        newSectionId = this.selectedSectionOrTab.children.length ? this.selectedSectionOrTab.children[0].data : -1;
                        newIndex = 0;
                    } else {
                        newTabId = this.selectedSectionOrTab.parent.data;
                        newSectionId = this.selectedSectionOrTab.data;
                        newIndex = this.selectedSectionOrTab.key;
                    }

                    this._tabService.moveFieldToAnotherTabSection(field.id, newSectionId, newTabId).subscribe((res) => {
                        const index = this.formArray.value[this.currentSection].findIndex((f) => {
                            return f.id == field.id;
                        });

                        (this.formArray.controls[this.currentSection] as FormArray).removeAt(index);

                        this._elementTypeService.onFieldChangeTab$.next({fieldId: field.id, sectionId: newSectionId, tabId: newTabId, oldTabId: this._tab.id});

                        this.currentSection = 0; // newIndex; // Usefull only if change tab in parent component (actually commented)
                    });
                }
            } else if (result.isDismissed) {
                this.selectedSectionOrTab = null;
            }
        });
    }

    setAllPreview(): void {
        (this.formArray.controls[this.currentSection] as FormArray).controls.forEach((c: FormGroup) => {
            c.controls.previewable.setValue(true);
        });
        this.formArray.markAsDirty();
    }

    setAllNoPreview(): void {
        (this.formArray.controls[this.currentSection] as FormArray).controls.forEach((c: FormGroup) => {
            c.controls.previewable.setValue(false);
        });
        this.formArray.markAsDirty();
    }

    /**
     * Add a new field
     */
    addField(): void {
        const formGroup = this._fb.group({
            id: [-1, Validators.required],
            name: ["", [Validators.required]],
            nameTranslations: [{}],
            protocol: [""],
            fieldType: ["", Validators.required],
            source: [-1, Validators.required],
            multiple: [false],
            previewable: [false],
            mandatory: [false],
            salabilityIndicator: [false],
            defaultValue: [null],
            translatable: [false],
            collectionReferent: [null]
        });

        formGroup.controls.fieldType.valueChanges.pipe(takeUntil(this._ngUnsubscribe)).subscribe((value) => {
            const control = formGroup.controls.source;
            ["list", "fieldset", "productLink","listcascade"].includes(value) ? control.patchValue("") : control.patchValue(-1);
        });

        (this.formArray.controls[this.currentSection] as FormArray).push(formGroup);
        this.formArray.markAsDirty();
        this.formArray.updateValueAndValidity();
    }

    /**
     * Save the current tab
     * @returns
     */
    saveTab(isAdd = false, currentSectionId = -1): void {
        if (this.formArray.invalid) {
            return;
        }

        const fields = [];
        const internationalCode = this.selectedLanguage ? this.selectedLanguage.internationalCode : 'fr_FR';
        const basicLanguage = this.getBasicLanguage();

        this.formArray.controls.forEach((formArray: FormArray, index: number) => {
            const section = this.sections[index];
            if (section.name !== this._translate.instant("general.unnamedSection")) {
                const nameTranslations = section.nameTranslations;
                if (currentSectionId === section.id) {
                    nameTranslations[internationalCode] = section.name.trim();
                }
                fields.push({
                    FieldId: typeof section.id === "string" ? -1 : section.id,
                    Nom: section.nameTranslations[basicLanguage.internationalCode] || section.name,
                    NomTranslations: nameTranslations,
                    Preview: true,
                    "Type de champ": "section",
                    Protocol: "",
                    Multiple: false,
                });
            }

            formArray.controls.forEach((sc: FormGroup) => {
                const nameTranslations = sc.controls.nameTranslations.value;
                nameTranslations[internationalCode] = sc.controls.name.value.trim();

                const object = {
                    FieldId: sc.controls.id.value,
                    Nom: nameTranslations[basicLanguage.internationalCode] || sc.controls.name.value.trim(),
                    NomTranslations: nameTranslations,
                    Preview: sc.controls.previewable.value,
                    Mandatory: sc.controls.mandatory.value,
                    SalabilityIndicator: sc.controls.salabilityIndicator.value,
                    "Type de champ": sc.controls.fieldType.value,
                    Multiple: sc.controls.multiple.value,
                    Protocol: sc.controls.protocol.value.trim(),
                    "Default Value": sc.controls.defaultValue.value,
                    Translatable: sc.controls.translatable.value,
                    collectionReferent: sc.controls.collectionReferent.value
                };

                if (sc.controls.fieldType.value === "list") {
                    object["Liste de liste"] = sc.controls.source.value;
                } else if (sc.controls.fieldType.value === "fieldset") {
                    object["Liste de fieldset"] = sc.controls.source.value;
                } else if (sc.controls.fieldType.value === "productLink") {
                    object["Liste de type"] = sc.controls.source.value;
                } else if (sc.controls.fieldType.value === "listcascade") {
                    object["Liste de listcascade"] = sc.controls.source.value;
                }

                fields.push(object);
            });
        });
        const payload = {
            model: {
                "Contener Field": fields,
            },
        };

        this._elementTypeService
            .updateFormField(this._elementType.id, this._tab.id, payload)
            .pipe(map((r) => r.data))
            .subscribe((tab) => {
                this.formArray.markAsPristine();
                this._tab = tab;
                this._elementTypeService.setElementTypeTab(tab);
                this._initData();
                this.canChangeTab();
                this.changeLang();

                if (isAdd) {
                    setTimeout(() => {
                        const inputElement = document.getElementById("section-name-" + this.currentSection) as HTMLInputElement;
                        inputElement.focus();
                        inputElement.select();
                    }, 100);
                }
            });
    }

    /**
     * Validator to control that fieldName is unique in each tab
     * @returns
     */
    uniqueValueFieldName(): ValidatorFn {
        return (formArray: FormArray): null => {
            let values = [];
            formArray.value.forEach((fields) => {
                values = values.concat(fields);
            });
            values = values.map((v) => v.name);

            // let otherTabName = [];

            // this._elementType.tabs
            // .filter((t) => t.id !== this._tab.id)
            // .map((t) => t.fields)
            // .forEach((fields: any[]) => {
            //     otherTabName = otherTabName.concat(fields.filter((f) => f.fieldType !== "section"));
            // });

            // otherTabName = otherTabName.map((v) => v.name);

            formArray.controls.forEach((form: FormArray) => {
                form.controls.forEach((formGroup: FormGroup) => {
                    const control = formGroup.controls.name;
                    if (values.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 1 && control.value) {
                        control.setErrors({uniqueValue: true});
                        // } else if (otherTabName.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 0 && control.value) {
                        //     control.setErrors({ uniqueValueCrossTab: true });
                        //
                    } else {
                        const errors = control.errors;
                        if (errors && errors.uniqueValue) {
                            delete errors.uniqueValue;
                        }
                        // if (errors && errors.uniqueValueCrossTab) {
                        //     delete errors.uniqueValueCrossTab;
                        // }
                        if (errors && Object.keys(errors).length === 0) {
                            control.setErrors(null);
                        } else {
                            control.setErrors(errors);
                        }
                    }
                });
            });

            return null;
        };
    }

    /**
     * Validator to control that protocol is unique in each tab
     * @returns
     */
    uniqueValues(): ValidatorFn {
        return (formArray: FormArray): null => {
            let values = [];
            formArray.value.forEach((fields: any[]) => {
                values = values.concat(fields);
            });
            values = values.map((v) => v.protocol);

            let otherTabProtocols = [];

            this._elementType.tabs
                .filter((t) => t.id !== this._tab.id)
                .map((t) => t.fields)
                .forEach((fields: any[]) => {
                    otherTabProtocols = otherTabProtocols.concat(fields.filter((f) => f.fieldType !== "section"));
                });

            otherTabProtocols = otherTabProtocols.map((v) => v.protocol);

            formArray.controls.forEach((formArray: FormArray) => {
                formArray.controls.forEach((formGroup: FormGroup) => {
                    const control = formGroup.controls.protocol;
                    if (values.filter((v: string) => v && v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 1 && control.value) {
                        control.setErrors({uniqueValue: true});
                    } else if (otherTabProtocols.filter((v: string) => v.trim().toLowerCase() === control.value.trim().toLowerCase()).length > 0 && control.value) {
                        control.setErrors({uniqueValueCrossTab: true});
                    } else {
                        const errors = control.errors;
                        if (errors && errors.uniqueValue) {
                            delete errors.uniqueValue;
                        }
                        if (errors && errors.uniqueValueCrossTab) {
                            delete errors.uniqueValueCrossTab;
                        }
                        if (errors && Object.keys(errors).length === 0) {
                            control.setErrors(null);
                        } else {
                            control.setErrors(errors);
                        }
                    }
                });
            });

            return null;
        };
    }

    /**
     * Initialize sections + fields
     */
    private _initData(): void {
        this.sections = [];
        this.formArray = this._fb.array([], [this.uniqueValues(), this.uniqueValueFieldName()]);

        if (this._tab.fields.length > 0) {
            this.hasFields = true;
            if (this._tab.fields[0].fieldType !== "section") {
                const fields = [{id: this._generateId(), name: this._translate.instant("general.unnamedSection"), fieldType: "section"}];
                this._tab.fields = fields.concat(this._tab.fields);
            }
            this._buildSections(this._tab.fields);
            this.sections.forEach((s) => {
                const fA = this._fb.array([]);
                s.fields.forEach((f) => {

                    fA.push(
                        this._fb.group({
                            id: [f.id, Validators.required],
                            name: [f.name || "", [Validators.required]],
                            nameTranslations: [f.nameTranslations || ""],
                            protocol: [f.protocol || ""],
                            fieldType: [f.fieldType, Validators.required],
                            source: [{value: f.source, disabled: true}],
                            multiple: [f.multiple || false],
                            previewable: [f.preview || false],
                            mandatory: [f.mandatory || false],
                            salabilityIndicator: [f.salabilityIndicator || false],
                            defaultValue: [f.defaultValue || null],
                            translatable: [f.translatable || null],
                            collectionReferent: [f.collectionReferent || null]
                        })
                    );
                });
                this.formArray.push(fA);
            });
        } else {
            this.sections.push({ id: this._generateId(), name: this._translate.instant("general.unnamedSection"), nameTranslations: {}, fields: [] });
            this.formArray.push(this._fb.array([]));
        }
        setTimeout(() => {
            if (this.currentSection >= this.nav.items.length) {
                this.currentSection = 0;
            }
            this.nav.select(this.currentSection);
        }, 10);
    }


    private _buildSections(fields: any[]): void {
        for (const field of fields) {
            if (field.fieldType === "section") {
                this.sections.push({ id: field.id, name: field.name, nameTranslations: field.nameTranslations, fields: [] });
            } else {
                this.sections[this.sections.length - 1].fields.push(field);
            }
        }
    }

    private _generateId() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }

    isOnePreview(): boolean {
        return this.formArray.controls.map((fg: FormGroup) => fg.value.previewable as boolean).some((previewable) => previewable);
    }

    isOneNoPreview(): boolean {
        return this.formArray.controls.map((fg: FormGroup) => fg.value.previewable as boolean).some((previewable) => !previewable);
    }

    /**
     * Configure a table field
     *
     * @param sectionForm
     * @param index
     */
    openConfigureTableModal(sectionForm: FormGroup, controlIndex: number) {
        this.currentSectionForm = sectionForm;
        this.currentControlIndex = controlIndex;
        this.title = this._translate.instant("pim.elementTypes.configureTable") + " : " + this.currentSectionForm.value[controlIndex].name;
        this.currentTable = this.currentSectionForm.value[this.currentControlIndex].defaultValue;
    }

    setCells(cells: any) {
        let defaultValue = this.currentSectionForm.controls[this.currentControlIndex]["controls"].defaultValue;
        defaultValue.setValue(cells);
        defaultValue.markAsDirty(); // Enabled save button
        this.currentTable = cells;
        this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
    }

    canChangeTab() {
        let isFormValid = true;

        if (!this.formArray.pristine) {
            isFormValid = false;
        }
        this._elementTypeService.isFormValid.next(isFormValid);
    }

    focusSelectedNameSection(id) {
        setTimeout(() => {
            this.isSortable = false;
            document.getElementById("section-name-" + id).focus();
        }, 100);
    }

    setTabTypeList() {
        this.tabTypeList = [];

        [...this._elementType.tabs].forEach((data) => {
            let children = [];
            let subIndex = 0;
            data.fields.forEach((subData) => {
                if (subData.fieldType == "section" && typeof subData.id === "number") {
                    children.push({
                        data: subData.id,
                        label: subData.name,
                        expandedIcon: "pi pi-bookmark",
                        collapsedIcon: "pi pi-bookmark",
                        selectable: subData.id != this.selectedSectionId ?? false,
                        type: "section",
                        key: subIndex,
                    });

                    subIndex++;
                }
            });

            this.tabTypeList.push({
                data: data.id,
                label: data.name,
                expandedIcon: "pi pi-folder-open",
                collapsedIcon: "pi pi-folder",
                selectable: data.id != this._tab.id ?? false,
                children: children,
                type: "tab",
            });
        });
    }

    expandRecursive(isExpanded: boolean) {
        this.tabTypeList.forEach((node) => {
            this._treeService.expandRecursive(node, isExpanded);
        });
    }

    openLinkInputRef(sectionForm: FormGroup, controlIndex: number) {

        this.formLinkInputRef = this._fb.group({
            input: [sectionForm.value[controlIndex].collectionReferent]
        });
        this.actualField = controlIndex;
        this.actualSection = sectionForm;

        this.linkInputRef.fire().then((result) => {

            if (this.formLinkInputRef.value.input != "null") {
                sectionForm.controls[controlIndex]['controls'].collectionReferent.setValue(this.formLinkInputRef.value.input);
            } else {
                sectionForm.controls[controlIndex]['controls'].collectionReferent.setValue(null);
            }
            sectionForm.controls[controlIndex]['controls'].collectionReferent.markAsDirty();
        });
    }

    getInputRefName(fieldsetId, inputId) {
        if (this.inputs === undefined) {
            return '';
        }
        const ref = this.inputs[fieldsetId].find(input => input.id == inputId);

        return ref ? ref.name : '';
    }

    changeLang()
    {
        if (!this.selectedLanguage) {
            return
        }
        this.formArray.controls.forEach(section => {
            section['controls'].forEach( field => {
                const nameTranslations = field.get('nameTranslations').value || {};
                this.languagesList.forEach(language => {
                    if (!nameTranslations.hasOwnProperty(language.internationalCode) || !nameTranslations[language.internationalCode]) {
                        nameTranslations[language.internationalCode] = field.get('name').value;
                    }
                })

                const translatedValue = nameTranslations[this.selectedLanguage.internationalCode];
                field.get('name').setValue(translatedValue);
            })
        })
    }

    getBasicLanguage() : Language
    {
        const basicLanguage = this.languagesList.find(language => language.isBasic);

        if (basicLanguage) {
            return basicLanguage;
        }

        // Si aucune langue de base n'est trouvée, retourner une valeur par défaut
        return {
            id: 0,
            label: 'Français',
            icon: '',
            code: 'fr',
            internationalCode: 'fr_FR',
            isVisible: true,
            isBasic: true
        };
    }

    isNotBasic(): boolean
    {
        return this.selectedLanguage && !this.selectedLanguage.isBasic;
    }
}
