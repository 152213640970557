<ol class="breadcrumb">
    <li *ngFor="let breadcrumb of breadcrumbDef; let i = index" [attr.data-index]="i" class="d-none d-md-block">
        <span *ngIf="breadcrumb && (breadcrumb.routerLink === null || i + 1 == breadcrumbDef.length)">
            <fa-icon *ngIf="i == 0" [icon]="faHome" style="margin-right: 5px;"></fa-icon>
            &nbsp;<span class="d-inline-block">{{ breadcrumb.name | translate }}</span> /
        </span>
        <a *ngIf="breadcrumb && breadcrumb.routerLink !== null && i + 1 != breadcrumbDef.length"
            [routerLink]="breadcrumb.routerLink" [queryParams]="breadcrumb.queryParams || null">
            <fa-icon *ngIf="i == 0" [icon]="faHome" style="margin-right: 5px;"></fa-icon>
            &nbsp;<span class="d-inline-block">{{ breadcrumb.name | translate }}</span> /
        </a>
    </li>

    <li class="breadcrumb-menu d-none d-md-block">
        <div class="row">

            <div class="pr-2">
                <div [ngClass]="searchInput ? 'input-group mb-2' : 'invisible input-group mb-2'"
                    style="margin-bottom: 0 !important;">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fa-icon [icon]="faFilter"></fa-icon>
                        </div>
                    </div>
                    <input type="text" class="form-control" [ngModel]="searchValue" id="inlineFormInputGroup"
                        placeholder="{{ 'breadcrumb.filter' | translate }}" (debounceInput)="onSearch($event)" />
                </div>
            </div>
            <app-jsx *ngIf="downloadJsx && canChangeJSX"></app-jsx>
            <div *ngIf="flatplanTooblar" class="pr-2">
                <app-bread-crumb-flatplan-toolbar></app-bread-crumb-flatplan-toolbar>
            </div>

            <div *ngIf="viewButtons" class="pr-2">
                <app-view-buttons [defaultView]="viewButtonsDefault" [views]="viewButtonsActions"
                    (view)="onViewButtonclick($event)"></app-view-buttons>
            </div>

            <div *ngIf="generalActionButtons || !(searchInput || flatplanTooblar || viewButtons || generalActionButtons)" class="pr-2">
                <app-bread-crumb-traduction *ngIf="!canShow()"></app-bread-crumb-traduction>
                <app-action-buttons [actions]="generalActionButtonsActions" (action)="onGeneralActionButtonclick($event)"></app-action-buttons>
            </div>

            <div *ngIf="importButton" class="mr-2">
                <button type="button" class="btn btn-primary" (click)="onImportClick()">
                    <i class="pi pi-file-excel"></i>
                    <span class="d-inline-block ml-2">{{ importButtonTitle | translate }}</span>
                </button>
            </div>

            <div *ngIf="actionButton" class="mr-4">
                <button type="button" [id]="buttonId" class="btn btn-primary" (click)="onButtonclick()">
                    <span *ngIf="undefined === actionButtonIcon || null === actionButtonIcon || '' === actionButtonIcon; else elseBlock">
                        <fa-icon [icon]="faPlusSquare"></fa-icon>
                    </span>
                    <ng-template #elseBlock>
                        <i [ngClass]="actionButtonIcon"></i>
                    </ng-template>
                    <span class="d-inline-block ml-2">{{ 'breadcrumb.' + buttonTitle | translate }}</span>
                </button>
            </div>
        </div>
    </li>
    <li class="breadcrumb-menu d-block d-md-none ml-auto"
        *ngIf="searchInput || flatplanTooblar || viewButtons || generalActionButtons || actionButton">
        <button type="button" data-toggle="dropdown" id="options" class="btn btn-primary">
            <span>{{ 'breadcrumb.options' | translate }}</span>
        </button>
        <div class="dropdown-menu">

            <div *ngIf="searchInput" class="p-2 border-bottom">
                <div class="input-group pb-2" style="margin-bottom: 0 !important;">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <fa-icon [icon]="faFilter"></fa-icon>
                        </div>
                    </div>
                    <input type="text" class="form-control" [ngModel]="searchValue" id="optionInputGroup"
                        placeholder="{{ 'breadcrumb.filter' | translate }}" (debounceInput)="onSearch($event)" />
                </div>
            </div>

            <div *ngIf="flatplanTooblar" class="p-2 border-bottom">
                <app-bread-crumb-flatplan-toolbar></app-bread-crumb-flatplan-toolbar>
            </div>

            <div *ngIf="viewButtons" class="p-2 border-bottom">
                <app-view-buttons [defaultView]="viewButtonsDefault" [views]="viewButtonsActions"
                    (view)="onViewButtonclick($event)"></app-view-buttons>
            </div>

            <div *ngIf="generalActionButtons" class="p-2 border-bottom">
                <app-action-buttons [actions]="generalActionButtonsActions"
                    (action)="onGeneralActionButtonclick($event)"></app-action-buttons>
            </div>
            <div *ngIf="actionButton" class="p-2 border-bottom">
                <button type="button" [id]="buttonId" class="btn btn-primary" (click)="onButtonclick()">
                    <fa-icon [icon]="faPlusSquare"></fa-icon>
                    <span class="d-inline-block ml-2">{{ 'breadcrumb.' + buttonTitle | translate }}</span>
                </button>
            </div>
        </div>
    </li>
    <li class="breadcrumb-sidebar" [ngClass]="{ 'ml-auto': !(searchInput || flatplanTooblar || viewButtons || generalActionButtons || actionButton) }" style="display: flex">
        <app-language-selector *ngIf="canShow()"></app-language-selector>
        <app-bread-crumb-menu></app-bread-crumb-menu>
    </li>
</ol>
