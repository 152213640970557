<ng-container *ngIf="tab.fields && tab.fields.length > 0; else noSectionAvailable">
    <app-section-tab #sectionTab *ngIf="tab.fields[0].sections" [sections]="tab.fields[0].sections"
                     [sectionNameTranslations]="tab.fields[0].sectionNameTranslations"
        [setValue]="setValue" (onSaveField)="saveField($event)" (onHistoryClick)="openHistory($event)"
        (onAddCollection)="addCollection($event)" (onRemoveCollection)="removeCollection($event)"
        (onSwitchCollections)="switchCollections($event)"></app-section-tab>
</ng-container>

<ng-template #noSectionAvailable>
    <app-element-no-field-data [link]="routerLink" buttonTitle="{{ 'general.edit' | translate }}">
        <p>
            {{ noSectionMessage }}
        </p>
    </app-element-no-field-data>
</ng-template>
<swal #historyModal width="700px" [showConfirmButton]="false" title="{{ 'history.historyChange' | translate }}">
    <div *swalPortal>
        <div *ngIf="history.length === 0" class="alert alert-warning" role="alert">
            {{ 'history.notHistory' | translate  }}
        </div>
        <div *ngIf="history.length > 0">
            <div *ngIf="!tableHistory && !checkboxHistory && !colorsHistory" class="row justify-content-center">
                <label class="switch switch-label switch-outline-primary-alt">
                    <input class="switch-input" type="checkbox" (change)="switchDiff()" />
                    <span class="switch-slider" data-checked="Diff" data-unchecked="Diff"></span>
                </label>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr class="tr-font-family">
                            <th>{{ 'history.modify' | translate }}</th>
                            <th>{{ 'history.modifyBy' | translate }}</th>
                            <th>{{ 'history.publicationDate' | translate }}</th>
                            <th *ngIf="!tableHistory && !checkboxHistory">{{'history.action' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let modification of history; let i = index">
                            <ng-container *ngFor="let values of modification.value; let j = index">
                                <tr>
                                    <td *ngIf="tableHistory">
                                        <app-form-table [id]="j" [cells]="_tableService.parse(values.newValue.value)"
                                            [readOnly]="true">
                                        </app-form-table>
                                    </td>
                                    <td style="max-width: 150px;" *ngIf="!tableHistory">
                                        <div *ngIf="fieldTypeHistory != 'fieldset'">
                                            <span *ngIf="showDiff === false || !values.oldValue.value"
                                                [innerHtml]="fieldTypeHistory === 'colors' || fieldTypeHistory === 'checkbox' ? null : sanitize(values.newValue.value)"></span>
                                            <div *ngIf="fieldTypeHistory === 'colors'">
                                                <app-color-picker-maestro [value]="values.newValue.value" [disabled]="true">
                                                </app-color-picker-maestro>
                                            </div>
                                            <div *ngIf="fieldTypeHistory === 'checkbox'">
                                                <label class="switch switch-pill switch-primary">
                                                    <input [disabled]="true" type="checkbox" class="switch-input col-10"
                                                        [checked]="values.newValue.value && values.newValue.value === '1'">
                                                    <span class="switch-slider"></span>
                                                </label>
                                            </div>
                                            <span *ngIf="showDiff === true && values.oldValue.value" diff
                                                [left]="values.oldValue.value" [right]="values.newValue.value"></span>
                                        </div>
                                        <div *ngIf="fieldTypeHistory == 'fieldset'">
                                            <div>
                                                <span *ngIf="showDiff === false || !values.oldValue.value"
                                                    [innerHtml]="sanitize(values.newValue.value)"></span>
                                                <span *ngIf="showDiff === true and values.oldValue.value" diff
                                                    [left]="values.oldValue.value"
                                                    [right]="values.newValue.value">
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td *ngIf="modification.nameUser.length === 0">
                                        {{ 'history.maestroUser' | translate }}
                                    </td>
                                    <td *ngIf="modification.nameUser.length > 0">
                                        {{ modification.nameUser }}
                                    </td>
                                    <td>
                                        {{ modification.date}}
                                    </td>
                                    <td *ngIf="!tableHistory && !checkboxHistory">
                                        <button *ngIf="!modification.isCopy" data-toggle="popover" [id]="'tooltip-' + i"
                                            (click)="copyToClip(values.newValue.value, i)"
                                            data-placement="top" class="btn btn-secondary">
                                            <fa-icon [icon]="faClone"></fa-icon>
                                        </button>
                                        <p *ngIf="modification.isCopy" class="p-copied">
                                            <i style="color: black; font-size: 15px;" class="bi bi-check-lg"></i>
                                            {{'tooltip.copied' | translate }}
                                        </p>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</swal>
