import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Editor, Toolbar } from "ngx-editor";
import { isMarkActive } from "ngx-editor/helpers";
import { toggleMark } from "prosemirror-commands";
import { EditorState, Plugin, Transaction } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import schema from "./extension/editor.schema";

@Component({
    selector: "app-text-editor",
    templateUrl: "./text-editor.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .NgxEditor__MenuItem {
                height: 30px;
                width: 30px;
            }

            .NgxEditorFlex {
                display: flex;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextEditorComponent),
            multi: true,
        },
    ],
})
export class TextEditorComponent implements OnInit, ControlValueAccessor, OnChanges {
    @Input() value: string;

    @Output() focusOut = new EventEmitter<string>();
    @Output() historyClick = new EventEmitter<void>();

    @Input() showHistory = false;
    @Input() disabled = false;
    @Input() closeInput: boolean = false;

    @Input() keepActive = true;

    @Input() fieldId = "";
    @Input() focus = false;
    editor: Editor;

    isEditorActive = false;

    @Input() pluginActivated = false;

    showCode = false;

    toolbar: Toolbar = [
        ["bold", "italic"],
        ["underline", "strike"],
        ["code", "blockquote"],
        ["ordered_list", "bullet_list"],
        [{ heading: ["h1", "h2", "h3", "h4", "h5", "h6"] }],
        //["sub","sup"],
        // [{ script: 'sub'}, { script: 'super' }],
        ["link"],
        //['link', 'image'],
        //['text_color', 'background_color'],
        //['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    form: FormGroup;

    textAreaFormControl = new FormControl();

    private _originValue: string;

    // This lets to manage <sup> & <sub> tags and was taken from https://github.com/bymounib/ngx-editor-add-subscript-superscript-tools/tree/main
    subIsActive = false;
    subIsDisabled = false;
    supIsActive = false;
    supIsDisabled = false;

    private _changeCallbacks: Array<(isoStrDate: string) => any> = [];
    private _touchedCallbacks: Array<(isoStrDate: string) => any> = [];

    constructor(private _fb: FormBuilder, private _sanitizer: DomSanitizer, private _changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.form = this._fb.group({
            editorContent: [this.value || ""],
        });
        this.editor = new Editor({
            history: true,
            inputRules: true,
            keyboardShortcuts: true,
            schema,
        });

        this._originValue = this.value;

        const plugin = new Plugin({
            view: () => {
                return {
                    update: this.update,
                };
            },
        });
        if (!!this.editor) {
            this.editor.registerPlugin(plugin);
        }
    }

    ngOnDestroy(): void {
        this.editor.destroy();
    }

    writeValue(obj: string): void {
        this.value = obj;
        this._onChange(this.value);
    }

    registerOnChange(fn: (str: string) => any) {
        this._changeCallbacks.push(fn);
    }

    registerOnTouched(fn: (str: string) => any): void {
        this._touchedCallbacks.push(fn);
    }

    private _onChange(str: string) {
        this._changeCallbacks.forEach((cb) => cb(str));
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    emitOut(): void {
        const value = this.form.value.editorContent;
        this.value = value;
        this._onChange(this.value);

        if (value !== this._originValue) {
            this._originValue = value; //@TODO Do it only if the value is saved
            this.focusOut.emit(value);
        }
    }

    openEditor(): void {
        if (!this.disabled && !this.keepActive) {
            setTimeout(() => {
                this.editor = new Editor({
                    history: true,
                    keyboardShortcuts: true,
                    inputRules: true,
                    schema,
                });
                this.isEditorActive = true;
                this._changeDetector.markForCheck();
            });
        }
    }

    closeEditor(): void {
        if (this.editor) {
            this.editor.destroy();
        }
        this.isEditorActive = false;
    }

    getContent(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.form.value.editorContent);
    }

    insertTrident(): void {
        this.editor.commands.insertText("Ψ").exec();
    }

    insertInsec(): void {
        this.editor.commands.insertText("→").exec();
    }

    insertCarre(): void {
        this.editor.commands.insertText("■").exec();
    }

    insertSautColonne(): void {
        this.editor.commands.insertText("⇔").exec();
    }

    insertTriangle(): void {
        this.editor.commands.insertText("▲").exec();
    }

    insertRetour(): void {
        this.editor.commands.insertText("¬").exec();
    }

    insertBulle(): void {
        this.editor.commands.insertText("• ").exec();
    }

    showSource(): void {
        this.showCode = !this.showCode;
    }

    getWordsNumber(): number {
        const splitted = this.form.value.editorContent
            .replace(/<\/?[^>]+(>|$)/g, "")
            .trim()
            .split(" ");
        if (splitted[0] === "") {
            return 0;
        }
        return splitted.length;
    }

    getCharactersNumber(): number {
        return this.form.value.editorContent.replace(/<\/?[^>]+(>|$)/g, "").trim().length;
    }

    updateWord(): void {
        this._changeDetector.markForCheck();
    }

    updateSource(event): void {
        let value = event["originalTarget"]["value"];
        this.form.controls.editorContent.setValue(value);
        this.emitOut();
    }

    setFocus(editor) {
        if(this.focus){
            setTimeout(() => {
                editor.focus()
                document.getElementById(this.fieldId).focus();
            },100);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        //console.log(changes.value)
        if (this.form && changes.value) {
            this.form.controls.editorContent.setValue(changes.value.currentValue ?? '');
        }
    }

    onClick(e: MouseEvent, tag: string): void {
        if (!!this.editor) {
            e.preventDefault();
            const { state, dispatch } = this.editor.view;
            this.execute(tag, state, dispatch);
        }
    }

    execute(tag: string, state: EditorState, dispatch?: (tr: Transaction) => void): boolean {
        const { schema } = state;
        return toggleMark(schema.marks[tag])(state, dispatch);
    }

    update = (view: EditorView) => {
        const { state } = view;
        const { schema } = state;
        this.subIsActive = isMarkActive(state, schema.marks.sub);
        this.subIsDisabled = !this.execute("sub", state); // returns true if executable
        this.supIsActive = isMarkActive(state, schema.marks.sup);
        this.supIsDisabled = !this.execute("sup", state); // returns true if executable
    };
}
