import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/admin/auth/auth.service";

/**
 * This guard is used to control the access to the admin module
 */
@Injectable()
export class AdminModuleGuard implements CanLoad {
    constructor(private _authService: AuthService, private _router: Router) {}

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        const acl = this._authService.getUserAclFromToken();
        const hasAuthorization = acl.SYSPAD_ADMIN_ROLE_LIST || acl.SYSPAD_ADMIN_USER_LIST || acl.MAESTRO_ADMIN_GROUP_LIST || acl.MAESTRO_ADMIN_WORKFLOW_LIST || acl.MAESTRO_ADMIN_LANGUAGE_LIST;

        const routeUser = segments.find((s) => "users" === s.path); // Used to control that the route concerne user
        const routeUpdate = segments.find((s) => "update" === s.path); // Used to control that the route concerne update
        const currentUserId = this._authService.getUserIdFromToken();
        const routeId = segments.find((s) => currentUserId === +s.path);
        const userAccessGranted = undefined !== routeUser && undefined !== routeUpdate && undefined !== routeId;

        let result;
        if (hasAuthorization || userAccessGranted) {
            result = true;
        } else {
            result = this._router.parseUrl("/");
        }

        return result;
    }
}
