<div class="tableComponent row">
    <div class="col-lg-12">
        <app-table tableTitle="{{ 'lists.title' | translate }} : {{ table.length }} {{ (table.length > 1 ? 'general.results' : 'general.result') | translate }}" [table]="table" [columns]="columns" [actions]="actions" (action)="onActionClick($event)"></app-table>
    </div>
</div>


<swal #historyModal [showConfirmButton]="false" title="{{ 'history.title' | translate }}">
    <div *swalPortal>
        <app-modal-history [history]="history" [service]="_listService" [objectId]="elementStory" [start]="startHistory" ></app-modal-history>
    </div>
</swal>

