import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { ThumbsnailContainerComponent } from "./thumbsnail-container.component";
import { ThumbsnailContainerResolver } from "./thumbsnail-container.resolver";
import {
    ModalFormLanguageModule
} from "app/shared/components/modal-form-language/modal-form-language.module";

@NgModule({
    imports: [SharedModule, SharedComponentsModule, ModalFormLanguageModule],
    declarations: [ThumbsnailContainerComponent],
    providers: [ThumbsnailContainerResolver],
})
export class ThumbsnailContainerModule { }
