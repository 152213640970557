import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroExport, MaestroExports } from "app/shared/models";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { ProjectService } from "./project.service";
import {Language} from "app/shared/models/language";

@Injectable()
export class ProjectDashboardService {
    constructor(private _http: HttpClient, private _project: ProjectService) {}

    /**
     * Get project information
     * @param id
     * @returns
     */
    getProject(id: number) {
        return this._project.getById(id);
    }

    getDashboardByProjectId(projectId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/dashboard/${projectId}.json`).pipe(map((data: any) => this._deserialize([...data.data])));
    }

    /**
     * Get page steps
     *
     * @param projectId
     * @returns
     */
    getPageStep(projectId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/list_step.json`);
    }

    /**
     * Get all exports for a page
     *
     * @param pageId
     * @returns
     */
    getExportPage(pageId: number) {
        return this._http.get(`${environment.projectsUrl}/page/${pageId}/export.json`);
    }

    private _deserialize(resources: any): MaestroExports {
        return resources.map(
            (data) =>
                <MaestroExport>{
                    id: data.id,
                    name: data.name,
                    type: data.type,
                    description: data.description,
                    elementLength: data.nbElement,
                    pageLength: data.nbPage,
                    maxLength: data.nbPageMax,
                    color: data.color,
                    workflow: { id: data.workflow_id },
                    versions: data.versions,
                }
        );
    }

    /**
     * Download CSV
     * @param idExport
     * @param selectedLanguage
     * @returns
     */
    getCsv(idExport: number, selectedLanguage: Language = null): Observable<any> {
        let lang = '';
        if (selectedLanguage && !selectedLanguage.isBasic) {
            // Si une langue est spécifiée, définir l'en-tête Accept-Language avec la langue sélectionnée
            lang = '?lang='+`${selectedLanguage.internationalCode}`;
        }

        return this._http.get(`${environment.projectsUrl}/export/${idExport}/flux/csv.json`+lang, {
            observe: "response" as "body",
            responseType: "blob",
        });
    }

    /**
     * Download CSV
     * @param idProject
     * @returns
     */
    getSeparator(idExport: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${idExport}/flux/separator.json`);
    }

    /**
     * Download XML
     * @param idExport
     * @returns
     */
    getXml(idExport: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${idExport}/xml.json`, {
            observe: "response" as "body",
            responseType: "blob",
        });
    }

    /**
     * Download Json
     * @param idExport
     * @returns
     */
    getJson(idExport: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${idExport}/json.json`, {
            observe: "response" as "body",
            responseType: "blob",
        });
    }

    openDocument(id: number, filename: string) {
        this._http.get(`${environment.projectsUrl}/project-document/${id}.json`, { responseType: 'blob' })
            .subscribe(response => {
                saveAs(response, filename);
            });
    }
}
