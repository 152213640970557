import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { DataService } from "app/core/services/global/data/data.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { Language } from "app/shared/models/language";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Observable } from "rxjs";

@Component({
    selector: "app-language-form",
    templateUrl: "./language-form.component.html",
})
export class LanguageFormComponent implements OnInit {
    language: Observable<Language>;
    form: FormGroup;
    id = this._route.snapshot.params["id"];

    constructor(
        private formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _languageService: LanguageService,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _dataService: DataService
    ) {}

    ngOnInit() {
        if(this.id){
            this._languageService.findById(this.id).subscribe(data => {
                if (data.data !== undefined) {
                    this.language = data.data;
                    this.initForm();
                }

            });
        } else {
            this.initForm();
        }

    }

    initForm() {
        this.form = this.formBuilder.group({
            label: [this.language && this.language["label"] ? this.language["label"] : "", Validators.required],
            code: [ this.language && this.language["code"] ? this.language["code"] : "", Validators.required],
            internationalCode: [ this.language && this.language["internationalCode"] ? this.language["internationalCode"] : "", Validators.required],
            flag: [ this.language && this.language["flag"] ? this.language["flag"] : ""],
            isVisible: [ this.language && this.language["isVisible"] ? this.language["isVisible"] : false],
        });
    }
    get formControls() {
        return this.form.controls;
    }

    formSubmit() {
        if (this.form.invalid) {
            return;
        }
        const formData = new FormData();
        // Ajoutez les valeurs du formulaire à l'objet FormData
        Object.keys(this.form.value).forEach(key => {
            if(key === 'isVisible'){
                if(this.form.value[key] === false){
                    this.form.value[key] = 0;
                } else {
                    this.form.value[key] = 1;
                }
            }
            formData.append(key, this.form.value[key]);
        });

        if(this.id){
            const idLanguage = this.language['id'];
            this._languageService.update(idLanguage, formData).subscribe(
                (data) => {
                    this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                    this.onBack();
                },
                (error) => {
                    this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
                }
            );
        } else {
            this._languageService.create(formData).subscribe(
                () => {
                    this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                    this.onBack();
                },
                () => {
                    this._toaster.show({ type: "danger", message: this._translate.instant("general.error") });
                }
            )
        }
    }

    onBack() {
        this._router.navigateByUrl(`/${MAESTRO_ROUTES.admin.base}/${MAESTRO_ROUTES.admin.languages}`);
    }

    onChange(){
        this.formControls.isVisible.setValue(!this.formControls.isVisible.value)
    }

    updateInternationalCode() {
        const codeValue = this.formControls['code'].value;
        switch (codeValue) {
            case codeValue:
                this.formControls['internationalCode'].setValue(codeValue ? `${codeValue}_${codeValue.toUpperCase()}` : '');
                if (codeValue.length >= 2) {
                    this._languageService.getImageURL(codeValue).then(path => {
                        this.formControls['flag'].setValue(`${path}`);
                    });
                } else {
                    this.formControls['flag'].setValue(``);
                }

                break;
            // Ajoutez d'autres cas au besoin
            default:
                this.formControls['internationalCode'].setValue('');
        }
    }
}

