import { HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlusSquare, faSave, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faTools } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { AdminWorkflowService } from "app/core/services/admin/workflow/admin-workflow.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { MAESTRO_ROUTES } from "app/shared/routes";

@Component({
    selector: "app-workflow-form",
    templateUrl: "./workflow-form.component.html",
})
export class WorkflowFormComponent implements OnInit, OnDestroy {
    formPlace: FormGroup;
    formTransition: FormGroup;
    formConfiguration: FormGroup;
    listPlace: any[];
    defaultPlace: any;
    listDefault: any[];
    configuration: any;
    dropdownUsersSettings : {};
    dropdownUsersList = [];
    languages = [];

    @Input() id: number = null;
    @Input() moduleModal: string = null;

    @Output() onSaveWorkflow = new EventEmitter<boolean>();

    readonly faTimesCircle = faTimesCircle;
    readonly faTools = faTools;
    readonly faSave = faSave;
    readonly faPlusSquare = faPlusSquare;

    dropdownCommandsSettings = {};
    dropdownCommandsList = [];

    dropdownElementTypesSettings = {};
    dropdownElementTypesList = [];

    dropdownElementsSettings = {};
    object_id: number;
    module: string;



    constructor(
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private _layout: LayoutService,
        private translate: TranslateService,
        private _workflowService: AdminWorkflowService,
        private _swalModal: SwalModalService,
        private _languageService: LanguageService
    ) {}

    ngOnInit(): void {

        this.formPlace = this.fb.group({
            places: this.fb.array([]),
        });
        this.formTransition = this.fb.group({
            transitions: this.fb.array([]),
        });
        this._layout.breadcrumb.setPath({ routerLink: null, name: "Workflows" }, 1);

        this.module = this.moduleModal ? this.moduleModal : this.route.snapshot.data.module;

        this.object_id = this.id ? this.id : this.route.snapshot.params["id"];



        const self = this;
        this.listPlace = [];
        this.listDefault = [];
        this.defaultPlace = {};

        this.dropdownUsersSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            itemsShowLimit: 6,
            allowSearchFilter: true,
            enableCheckAll: true,
            selectAllText: this.translate.instant("general.select_all"),
            unSelectAllText: this.translate.instant("general.unselect_all"),
        };

        this.dropdownCommandsSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: this.translate.instant("general.select_all"),
            unSelectAllText: this.translate.instant("general.unselect_all"),
            allowSearchFilter: true,
        };

        this.dropdownElementTypesSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: this.translate.instant("general.select_all"),
            unSelectAllText: this.translate.instant("general.unselect_all"),
            allowSearchFilter: true,
        };

        this.dropdownElementsSettings = {
            singleSelection: false,
            idField: "id",
            textField: "name",
            selectAllText: this.translate.instant("general.select_all"),
            unSelectAllText: this.translate.instant("general.unselect_all"),
            allowSearchFilter: true,
        };

        if (this.module) {
            this.listCommand();
            if (this.object_id) {
                this._workflowService.getTransitions(this.module, this.object_id).subscribe((data: any) => {
                    this.configuration = data.data;
                    if (this.configuration) {
                        let index = 0;
                        this.configuration.places.forEach((place) => {
                            self.addPlace(place);
                            if (place.default) {
                                self.defaultChange(index);
                            }
                            index++;
                        });
                        this.formConfiguration = this.fb.group({
                            id: [this.object_id ? this.object_id : null],
                            name: [this.configuration ? this.configuration.name : "", Validators.required],
                            module: [{ value: this.module, disabled: true }, Validators.required],
                            elementTypes: [this.configuration ? this.configuration.elementTypes : []],
                            elements: [this.configuration ? this.configuration.elements : []],
                        });
                    }
                });
            } else {
                this.formConfiguration = this.fb.group({
                    id: [null],
                    name: ["", Validators.required],
                    module: [{ value: this.module, disabled: true }, Validators.required],
                    elementTypes: [[]],
                    elements: [[]],
                });
            }
        }

        this._workflowService.getUsers().subscribe((data: any) => (this.dropdownUsersList = data.data));
        this._workflowService.getElementTypes().subscribe((data: any) => (this.dropdownElementTypesList = data.data));

        this._languageService.getAll().subscribe((data) => {
            data.data.forEach((language) => {
                if (language.isVisible) {
                    this.languages.push({
                        id: language.id,
                        name: language.label
                    })
                }
            })
        })
    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
    }

    listCommand(): void {
        if (!this.module) {
            this.module = this.formConfiguration.value.module;
        }
        this._workflowService.getCommands(this.module).subscribe((data: any) => (this.dropdownCommandsList = data.data || []));
    }

    addPlace(place = null): void {
        const control = <FormArray>this.formPlace.controls.places;
        let defaultPlace = 0;
        if (control.length === 0) {
            defaultPlace = 1;
        }

        let usersLanguage = this.fb.array([]);

        if(place){
            place.languages.forEach((language) => {
                usersLanguage.push(
                    this.fb.group({
                        id: [language.id],
                        name :[language.name],
                        users : [language.users ? language.users : []]
                    })

                );
            })
        }

        control.push(
            this.fb.group({
                id: [place ? place.id : null],
                name: [place ? place.name : "", Validators.required],
                color: [place ? place.color : ""],
                date_fin: [place ? place.dateEnable : false],
                blockPictures: [place ? place.blockPictures : false],
                blockTexts: [place ? place.blockTexts : false],
                default: [place ? place.default : defaultPlace, Validators.required],
                users: [place ? place.users : []],
                commands: [place ? place.commands : []],
                sendMail: [place ? place.sendMail : false],
                languageValidation: [place ? place.languageValidation : false],
                languages: [place ? place.languages : []],
                usersLanguage: usersLanguage
            })
        );
    }

    changeLanguage(event, step, action)
    {
        if(action == "select"){
            step.get("usersLanguage").push(this.fb.group({
                id: [event.id],
                name :[event.name],
                users : [[]]
            }))
        } else if(action == "selectAll"){
            event.forEach((languageEvent) => {

                let index = step.value.usersLanguage.findIndex((language) => languageEvent.id == language.id);
                if(index < 0 ){
                    step.get("usersLanguage").push(this.fb.group({
                        id: [languageEvent.id],
                        name :[languageEvent.name],
                        users : [[]]
                    }))
                }
            })
        } else if(action == "deSelect"){

            let index = step.value.usersLanguage.findIndex((language) => event.id == language.id);
            let usersLanguageValue = step.value.usersLanguage;
            usersLanguageValue.splice(index,1);

            step.get("usersLanguage").controls.splice(index, 1);
        } else if(action == "deSelectAll"){
            // step.get("usersLanguage").setValue([]);
            console.log(step.get("usersLanguage").controls);

            while (step.get("usersLanguage").controls.length !== 0) {
                step.get("usersLanguage").removeAt(0)
            }
        }

    }

    addTransition(place: any, placeStart: any): void {
        const control = <FormArray>this.formTransition.controls.transitions;

        control.push(
            this.fb.group({
                name: [place ? place.name + "_Transition" : null, Validators.required],
                position: [0, Validators.required],
                debut: [placeStart ? placeStart.name : place.name, Validators.required],
                fin: [place ? place.name : "", Validators.required],
                users: [place ? place.users : []],
                commands: [place ? place.commands : []],
            })
        );
    }

    prepareTransition(): void {
        this.listPlace = this.formPlace.value.places;
        this.defaultPlace = this.listPlace.find((place) => place.default === 1);
        if (this.defaultPlace) {
            this.listDefault[0] = this.defaultPlace.name;
        }
        const control = <FormArray>this.formTransition.controls.transitions;
        while (control.length > 0) {
            control.removeAt(0);
        }
        for (let i = 0; i < this.listPlace.length; i++) {
            this.addTransition(this.listPlace[i], this.listPlace[i - 1]);
        }
    }

    onSubmit(): void {
        this._swalModal.confirm({}).then(result => {
            if(result.isConfirmed){
                this.prepareTransition();
                this._workflowService.save(this.module, this.formPlace, this.formTransition, this.formConfiguration).subscribe((data) => {
                    if(!this.moduleModal){
                        if (this.module === "projects") {
                            this.router.navigateByUrl(`${MAESTRO_ROUTES.admin.base}/workflow/project`);
                        } else {
                            this.router.navigateByUrl(`${MAESTRO_ROUTES.admin.base}/workflow/` + this.module);
                        }
                    } else {
                        this.onSaveWorkflow.emit(true);
                    }
                });
            }
        });
    }

    defaultChange(index: number) {
        const defPlace = this.formPlace.value.places[index];
        this.formPlace.value.places.forEach(function (place) {
            place.default = 0;
        });

        defPlace.default = 1;
    }

    notInDefault(place: any, index: number): boolean {
        let find = true;
        for (let i = 0; i <= index; i++) {
            if (this.listDefault[i] === place.name) {
                find = false;
            }
        }
        return find;
    }

    addDefault(place: any, index: number): void {
        this.listDefault[index + 1] = place;
    }

    removePlace(index: number, place: any): void {
        this._swalModal.delete().then((result) => {
            if (result.value) {
                const control = this.formPlace.controls.places["controls"];
                control.splice(index, 1);
                this.formPlace.value.places.splice(index, 1);
                this.prepareTransition();
                const options = {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                    }),
                    body: {
                        places: this.formPlace.value.places,
                        transitions: this.formTransition.value.transitions,
                        configuration: this.formConfiguration.value,
                        module: this.module
                    },
                };
                if (place.value.id) {
                    this._workflowService.deletePlace(this.module, place.value.id, options).subscribe();
                }
            }
        });
    }

    onBack(): void {
        if(this.moduleModal){
            this.onSaveWorkflow.emit(false)
        } else {
            this._swalModal.confirm({}).then(result => {
                if(result.isConfirmed){
                    if (this.module === "projects") {
                        this.router.navigateByUrl(`${MAESTRO_ROUTES.admin.base}/workflow/project`);
                    } else {
                        this.router.navigateByUrl(`${MAESTRO_ROUTES.admin.base}/workflow/` + this.module);
                    }
                }
            })
        }
    }
}
