<div class="form-group"  [formGroup]="tableForm">
    <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="colonneActive = 0;">
        <li *ngFor="let exportTab of tableForm.controls.exports['controls']; let i = index" [id]="'export' + i" [ngbNavItem]="i">
            <a ngbNavLink>
                {{ exportTab.get('name').value }}
            </a>
            <ng-template ngbNavContent formArrayName="exports">
                <div [formGroupName]="i">
                    <div class="buttonHeader">
                        <div class="row">
                            <div class="col-2">
                                <button style="height: auto; margin-right: 5%;" (click)="newCol(exportTab.get('id').value)" class="btn btn-primary ml-1 actionButton " [title]="'projects.export.new_col' | translate">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                                <button class="btn btn-info" (click)="createDefaultFlux(exportTab.get('id').value)" [title]="'general.autoCreate' | translate">
                                    <fa-icon [icon]="faSitemap"></fa-icon>
                                </button>
                            </div>
                            <div class="col-2">
                                <input type="search" class="form-control" (change)="listFieldCascade($event.target.value, exportTab.get('id').value)" [placeholder]="'general.selectFilter' | translate">
                            </div>
                            <div class="col-2">
                                <p-cascadeSelect  appendTo="body" disabled="" (onChange)="onFilter($event, exportTab.get('id').value)" [options]="cascadefieldList" placeholder="Select Item" [optionGroupChildren]="['fields']" optionLabel="name" optionGroupLabel="name" dataKey="id"></p-cascadeSelect>
                            </div>

                            <div class="col-6 text-right">
                                <div class="btn-group">
                                    <label style="margin-top: 6px;">{{'general.separator' | translate }}: </label>
                                    <input style="width: 50px; margin-right: 5px;" type="text" class="text" #separator formControlName="separatorFlux" maxlength="1">
                                    <button [disabled]="!tableForm.valid" class="btn btn-primary" (click)="onSubmit()">
                                        <span class="d-none d-md-inline-block">{{ 'general.save' | translate }}</span>
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                    <table formArrayName="colonnes" class=" tab-pane fade show active table-striped col-12" [ngClass]="{'tab-pane': true, 'fade':true,'show':true}" style="margin-left: 1%;">
                        <tr class="row">
                            <td class="col-4" style="text-align: center;font-weight: bold">{{'projects.export.colFieldList' | translate}}</td>
                            <td class="col-8" style="text-align: center;font-weight: bold">{{'projects.export.colFieldListFieldset' | translate}}</td>
                        </tr>
                        <tr [id]="indexColonne" class="row" *ngFor="let colonne of exportTab['controls'].colonnes['controls']; let indexColonne = index" style="width: 100%;" [ngStyle]="{'display': colonne.value.removeCol  ? 'flex' : 'none', 'background-color': colonne.value.bgColor ? '#eeeeee' : 'white'}">
                            <td  [formGroupName]="indexColonne" *ngIf="colonne.value.removeCol" class="col-4 showBorder" [ngClass]="{'colFilter' : colFilter.indexOf(indexColonne) >= 0}" [ngStyle]="{'border-color':colonne.controls.protocol.invalid ? 'red' : 'black', 'padding': '0 5px 0px 5px'}">
                                <tr class="row ">
                                    <label *ngIf="colonneActive != indexColonne" class="col-7" style="margin: 0;">{{selectedLanguage && !selectedLanguage.isBasic && colonne.value.protocolTranslations.hasOwnProperty(selectedLanguage.internationalCode) && colonne.value.protocolTranslations[selectedLanguage.internationalCode] ? colonne.value.protocolTranslations[selectedLanguage.internationalCode] : colonne.value.protocol}}</label>
                                    <div *ngIf="colonneActive == indexColonne" class="col-7">
                                        <input class="form-control" formControlName="protocol" [attr.disabled]="colonne.value.colonneLink.length > 0 ? true : null" [ngClass]="colonne.controls.protocol.valid ? 'is-valid' : 'is-invalid'" [value]="selectedLanguage && !selectedLanguage.isBasic && colonne.value.protocolTranslations.hasOwnProperty(selectedLanguage.internationalCode) && colonne.value.protocolTranslations[selectedLanguage.internationalCode] ? colonne.value.protocolTranslations[selectedLanguage.internationalCode] : colonne.value.protocol">
                                        <div *ngIf="colonne.controls.protocol.invalid" class="invalid-feedback">
                                            <span *ngIf="colonne.controls.protocol.hasError('uniqueValue')">
                                                {{"pim.elementTypes.error.unique" | translate }}</span>
                                        </div>
                                    </div>
                                    <button [title]="'general.update' | translate" class=" btn col-1 " (click)="activeAndDesactiveCol(indexColonne)">
                                        <span *ngIf="colonneActive != indexColonne"><fa-icon class="text-info" [icon]="faEdit"></fa-icon></span>
                                        <span *ngIf="colonneActive == indexColonne"><fa-icon class="text-info" [icon]="faMinusSquare"></fa-icon></span>
                                    </button>
                                    <button [title]="'general.move' | translate" class=" btn col-1 " *ngIf="colonne.value.removeCol"
                                    (click)="openModalDragAndDrop(indexColonne, exportTab.get('id').value)">
                                        <fa-icon [icon]="faSort"></fa-icon>
                                    </button>
                                    <button [title]="'general.remove' | translate" class=" btn col-1 " *ngIf="colonne.value.removeCol"
                                    (click)="removeCol(indexColonne, exportTab.get('id').value)">
                                        <fa-icon class="text-danger"[icon]="faTrash"></fa-icon>
                                    </button>
                                    <button  class=" btn col-1 " *ngIf="colonne.value.colonneLink.length > 0"
                                    (click)="activeColonneInput(indexColonne, exportTab.get('id').value)">
                                        <fa-icon [title]="'general.hideInput' | translate" *ngIf="inputActive.includes(indexColonne)" [icon]="faAngleDoubleLeft"></fa-icon>
                                        <fa-icon [title]="'general.showInput' | translate" *ngIf="!inputActive.includes(indexColonne)" [icon]="faAngleDoubleRight"></fa-icon>
                                    </button>

                                </tr>
                                <div *ngIf="colonneActive == indexColonne" formArrayName="elementTypes">
                                    <tr class="row" [formGroupName]="indexElementType" *ngFor="let elementType of colonne.controls.elementTypes['controls']; let indexElementType = index" style="    margin-right: 0;margin-left: 0;">
                                        <td class="col-12">
                                            <div class="row">
                                                <label class="col-3">DM</label>
                                                <label class="col-9">{{ elementType.value.name }}</label>
                                            </div>
                                            <div class="row">
                                                <label class="col-3"> Champs: </label>
                                                <ng-multiselect-dropdown
                                                    class="col-9"
                                                    [formControl]="elementType.controls['fields']"
                                                    [placeholder]="placeholder"
                                                    [settings]="dropdownListSettings"
                                                    [data]="elementType.value.list"
                                                    (onSelect)="onSelect($event, indexColonne, indexElementType, exportTab.get('id').value)"
                                                    (onDeSelect)="onDeSelect($event, indexColonne, indexElementType, exportTab.get('id').value)"
                                                >
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div class="row">
                                                <label class="col-3"> Concat: </label>
                                                <div class="col-9 "><input class="form-control" type="text" formControlName="concat"></div>
                                            </div>
                                        </td>
                                    </tr>
                                </div>

                            </td>
                            <td *ngIf="inputActive.includes(indexColonne)" class="col-8">

                                <table class="tableInput">
                                    <tr class="row">
                                        <td [formGroupName]="indexInput" class="col-4 showBorder" *ngFor="let indexInput of colonne.value.colonneLink" [ngStyle]="{'border-color':exportTab['controls'].colonnes['controls'][indexInput].controls.protocol.invalid? 'red' : 'black', 'padding': '0'}">
                                            <tr class="row">

                                                <label *ngIf="colonneActive != indexInput" class="col-9" style="padding-left: 20px;margin: 0; margin-left: 5%;">{{ exportTab['controls'].colonnes['controls'][indexInput].value.protocol }}</label>
                                                <div  *ngIf="colonneActive == indexInput" class="col-9" style="margin-left: 5%;">
                                                    <input class="form-control" formControlName="protocol"  [ngClass]="exportTab['controls'].colonnes['controls'][indexInput].controls.protocol.valid ? 'is-valid' : 'is-invalid'">
                                                    <div *ngIf="exportTab['controls'].colonnes['controls'][indexInput].controls.protocol.invalid" class="invalid-feedback">
                                                        <span *ngIf="exportTab['controls'].colonnes['controls'][indexInput].controls.protocol.hasError('uniqueValue')">
                                                            {{"pim.elementTypes.error.unique" | translate }}</span>
                                                    </div>
                                                </div>
                                                <ng-container class="col-2">
                                                    <div class="row">
                                                        <button class="btn col-1" (click)="activeAndDesactiveCol(indexInput, exportTab.get('id').value)">
                                                            <span *ngIf="colonneActive != indexInput"><fa-icon class="text-info" [icon]="faEdit"></fa-icon></span>
                                                            <span *ngIf="colonneActive == indexInput"><fa-icon class="text-info" [icon]="faMinusSquare"></fa-icon></span>
                                                        </button>

                                                    </div>
                                                </ng-container>
                                            </tr>
                                            <div *ngIf="colonneActive == indexInput" formArrayName="elementTypes">
                                                <tr class="row" [formGroupName]="indexElementType" *ngFor="let elementType of exportTab['controls'].colonnes['controls'][indexInput].controls.elementTypes['controls']; let indexElementType = index" >
                                                    <td class="col-12">
                                                        <div class="row">
                                                            <label style="margin-left: 5%;" class="col-3">DM</label>
                                                            <label class="col-8">{{ elementType.value.name }}</label>
                                                        </div>
                                                        <div class="row">
                                                            <label style="margin-left: 5%;" class="col-3"> Champs: </label>
                                                            <ng-multiselect-dropdown
                                                                class="col-8"
                                                                formControlName="fields"
                                                                [placeholder]="placeholder"
                                                                [settings]="dropdownListSettings"
                                                                [data]="elementType.value.list"
                                                                (onSelect)="onSelect($event, indexInput, indexElementType, exportTab.get('id').value)"
                                                                (onDeSelect)="onDeSelect($event, indexInput, indexElementType, exportTab.get('id').value)"
                                                            >
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                        <div class="row">
                                                            <label style="margin-left: 5%;" class="col-3"> Concat: </label>
                                                            <div class="col-8"><input class="form-control" type="text" formControlName="concat"></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
</div>

<swal #changeColPositionModal title="{{ 'pim.elementTypes.change.sectionOrTab' | translate }}"
    confirmButtonText="{{ 'general.save' | translate }}" [showCloseButton]="true">
    <div *swalPortal>
        <div class="row form-group">
            <label class="col"><h2>{{ currentCol }}</h2></label>


            <form  [formGroup]="modalMoveForm" class="mt-2 row">
                <div class="row">

                    <label class="col-3">Déplacer</label>
                    <select class="form-control col-3" formControlName="action">
                        <option value="before">{{ 'general.before' | translate }}</option>
                        <option value="after">{{ 'general.after' | translate }}</option>
                    </select>

                    <select class="form-control col-5" formControlName="newPosition">
                        <option *ngFor="let head of headers" [value]="head.id">{{ head.protocol }}</option>
                    </select>
                </div>
            </form>
        </div>
    </div>
</swal>
