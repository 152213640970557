import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActionButtonsDef, ActionType } from "app/shared/components/action-buttons";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments";
import { UserService } from "../admin/user/user.service";

@Injectable()
export class TagService {
    protected modulePath = environment.pimUrl;
    private redirectionPath = `${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.tag}`;
    private filterPath = `${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.element}`;
    private breadcrumb = new BehaviorSubject<any[]>([]);
    private dam_regexp = /\/dam\//;
    private module = "pim";
    public tagsfilter = new BehaviorSubject<any[]>([]);

    constructor(private _http: HttpClient, private _userService: UserService) {}

    setModulePath(module: "pim" | "dam"): void {
        switch (module) {
            case "pim":
                this.modulePath = environment.pimUrl;
                break;
            case "dam":
                this.modulePath = environment.damUrl;
                break;
        }
    }

    getBreadcrumb(): Observable<any[]> {
        return this.breadcrumb.asObservable();
    }

    resetBreadcrumb() {
        this.breadcrumb.next([]);
    }

    getTagsFilter(): Observable<any[]> {
        return this.tagsfilter.asObservable();
    }

    setTagsFilter(tags: any[]){
        this.tagsfilter.next(tags);
    }

    resetTagsFilter() {
        this.tagsfilter.next([]);
    }

    getRootTags(url: string): Observable<any> {
        this.checkPath(url);
        return this._http.get(`${this.modulePath}/tag.json`);
    }

    getTag(id: number, url: string): Observable<any> {
        this.checkPath(url);
        return this._http.get(`${this.modulePath}/tag/${id}.json`);
    }

    getAllTags(): Observable<any> {
        return this._http.get(`${this.modulePath}/tag_without_level.json`);
    }

    getTagList(url: string, id?: number): Observable<any> {
        return id ? this.getChildren(url, id) : this.getRootTags(url);
    }

    setBreadcrumb(id: number, url: string): void {
        this.checkPath(url);

        this.getModel(id).subscribe((data: any) => {
            if (data.data.model.Parent) {
                this.setBreadcrumb(data.data.model.Parent.id, url);
            }
            const breadcrum = this.breadcrumb.value;
            breadcrum.unshift({ id: Number(id), name: data.data.model.Name });
            this.breadcrumb.next(breadcrum);
        });
    }

    createTags(tags: string[], parent: number): Observable<any> {
        return this._http.post<any>(`${this.modulePath}/tag/create.json`, { tags: tags, parent: parent });
    }

    deleteTags(tagIds: number[]): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            }),
            body: {
                ids: tagIds,
            },
        };

        return this._http.delete(`${this.modulePath}/tag.json`, options);
    }

    addTag(entityId: number, tagId: any): Observable<any> {
        return this._http.post(`${this.modulePath}/tag/${entityId}/add.json`, { id_tag: tagId });
    }

    removeTag(entityId: number, tagId: any): Observable<any> {
        return this._http.post(`${this.modulePath}/tag/${entityId}/remove.json`, { id_tag: tagId });
    }

    manageEntitiesTag(entity: string, tagIds: number[], entitiesId: number[], add: boolean): Observable<any> {
        return this._http.post(`${this.modulePath}/${entity}/manage/tag.json`, { entitiesId: entitiesId, add: add, tagIds: tagIds });
    }

    getModel(id: number): Observable<any> {
        return this._http.get(`${this.modulePath}/tag/${id}/model_field.json`);
    }

    updateFormField(updatedTags: { id; name, nameTranslations; parent }[]): Observable<any> {
        return this._http.put(`${this.modulePath}/tag/form_field_update.json`, updatedTags);
    }

    getEntityTags(id): Observable<any> {
        return this._http.get(`${this.modulePath}/tags/${id}.json`);
    }

    getSidebarTags(url?: string): Observable<any> {
        this.checkPath(url);
        return this._http.get(`${this.modulePath}/sidebar_tag.json`);
    }

    getChildren(url: string, id: number): Observable<any> {
        this.checkPath(url);
        return this._http.get(`${this.modulePath}/tag/${id}/children.json`);
    }

    checkPath(url: string) {
        if (!url) {
            // if !url => PIM
            this._changePath("pim");
        } else if (url.match(this.dam_regexp)) {
            this._changePath("dam");
        }
    }

    getRedirectionPath() {
        return this.redirectionPath;
    }

    getFilterPath() {
        return this.filterPath;
    }

    private _changePath(module: string) {
        this.module = module;
        switch (module) {
            case "dam":
                this.modulePath = environment.damUrl;
                this.redirectionPath = `${MAESTRO_ROUTES.dam.base}/${MAESTRO_ROUTES.dam.tag}`;
                this.filterPath = `${MAESTRO_ROUTES.dam.base}/${MAESTRO_ROUTES.dam.list}`;
                break;
            default:
                this.modulePath = environment.pimUrl;
                this.redirectionPath = `${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.tag}`;
                this.filterPath = `${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.element}`;
        }
    }

    setActions() {
        const acl: ACL = this._userService.getUserAclFromToken();

        const actions: ActionButtonsDef = [];
        switch (this.module) {
            case "dam":
                // actions.push({ type: ActionType.View, right: "SYSPAD_DAM_TAG_READ" });
                if (acl.SYSPAD_DAM_TAG_UPDATE) {
                    actions.push({ type: ActionType.Update, right: "SYSPAD_DAM_TAG_UPDATE" });
                }
                if (acl.SYSPAD_DAM_TAG_DELETE) {
                    actions.push({ type: ActionType.Delete, right: "SYSPAD_DAM_TAG_DELETE" });
                }
                break;

            default:
                // actions.push({ type: ActionType.View, right: "SYSPAD_PIM_TAG_READ" });
                if (acl.SYSPAD_PIM_TAG_UPDATE) {
                    actions.push({ type: ActionType.Update, right: "SYSPAD_PIM_TAG_UPDATE" });
                }
                if (acl.SYSPAD_PIM_TAG_DELETE) {
                    actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_TAG_DELETE" });
                }
        }

        return actions;
    }
}
