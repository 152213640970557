import {Component, ElementRef, HostListener, NgZone, OnInit, Renderer2} from "@angular/core";
import { Router } from "@angular/router";
import { faFlag } from "@fortawesome/free-regular-svg-icons";
import { faBars, faCheckCircle, faCopyright, faEdit, faIdCard, faImages, faLifeRing, faMagic, faPhone, faRegistered, faSignOutAlt, faTasks, faTools, faUser } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { NotificationService } from "app/core/services/admin/user/notification.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { DataService } from "app/core/services/global/data/data.service";
import { ExportPageService } from "app/core/services/project/export/export-page-view.service";
import { ACL } from "app/shared/models/acl";
import { MAESTRO_ROUTES } from "app/shared/routes/routes";
import { environment } from "environments/environment";
import * as $ from "jquery";
import { map } from "rxjs/operators";
import { activatedModules } from "src/environments/activated-modules";

@Component({
    selector: "app-maestro-header",
    templateUrl: "./maestro-header.component.html",
    styleUrls: ["./maestro-header.component.scss"],
})
export class MaestroHeaderComponent implements OnInit {
    subject: string;
    profile: string;
    username: string;
    showVersion: boolean = environment.showVersion;
    messagesInformations = [];

    notifications: Array<any>;
    nbNewNotif: number = 0;
    nbAllNotif: number = 0;
    indexNotif: number = 0;
    notifOpen: boolean = false;
    filterNotif: string;
    notifCount: number;
    notifAll: number;

    public acl: ACL;
    public activatedModules = activatedModules;

    private mercureHubGenerationTopic: string = "http://maestro/notification/create";
    private eventSource = new EventSource(environment.mercureHubUrl + "?topic=" + encodeURIComponent(this.mercureHubGenerationTopic));

    /**
     * Font Awesome
     */
    readonly faSignOutAlt = faSignOutAlt;
    readonly faCopyright = faCopyright;
    readonly faRegistered = faRegistered;
    readonly faLifeRing = faLifeRing;
    readonly faPhone = faPhone;
    readonly faTools = faTools;
    readonly faUser = faUser;
    readonly faFlag = faFlag;
    readonly faIdCard = faIdCard;
    readonly faCheckCircle = faCheckCircle;

    readonly dashboardIcon = faTasks;
    readonly pimIcon = faEdit;
    readonly projectIcon = faMagic;
    readonly damIcon = faImages;
    readonly faBars = faBars;

    readonly routes = MAESTRO_ROUTES;

    hasAdminAcl: boolean;
    hasDamAcl: boolean;
    hasPimAcl: boolean;
    hasProjectAcl: boolean;

    constructor(
        private _authService: AuthService,
        private _userService: UserService,
        private _translate: TranslateService,
        private _router: Router,
        private _notificationService: NotificationService,
        private renderer: Renderer2,
        private el: ElementRef,
        private _exportPageService: ExportPageService,
        private _dataService: DataService,
    ) {}

    ngOnInit() {
        this.acl = this._userService.getUserAclFromToken();
        this.notifications = [];
        if (!this.acl) {
            this.logout();
            return;
        }
        this.hasAdminAcl = this.acl.MAESTRO_MODULE_ADMIN_MODULE === 1;

        this.hasDamAcl = this.acl.MAESTRO_MODULE_DAM_MODULE === 1;

        this.hasProjectAcl = this.acl.MAESTRO_MODULE_PROJECT_MODULE === 1;

        this.hasPimAcl = this.acl.MAESTRO_MODULE_PIM_MODULE === 1;

        if (this._userService.getUserIdFromToken() === 0) {
            this.logout();
            return;
        }

        this._authService.showError$.next(false);

        this._userService
            .getById(this._userService.getUserIdFromToken())
            .pipe(map((r: any) => r.data))
            .subscribe(
                (data) => {
                    this.subject = data.email;
                    this.profile = data.profile;
                    this.username = data.firstName + " " + data.lastName;
                    this._authService.showError$.next(true);
                },
                () => {
                    this._authService.showError$.next(true);
                    this.logout();
                }
            );

        this._authService.refreshToken();

        this.countNewNotif();

        $("#notif").on("hide.bs.dropdown", function (e) {
            e.preventDefault();
        });

        this.eventSource.onmessage = (event) => {
            let data = JSON.parse(event.data);
            this.nbNewNotif = data[this._userService.getUserIdFromToken()].new;
            this.nbAllNotif = data[this._userService.getUserIdFromToken()].all;
        };

        const lang = localStorage.getItem('systemLanguage')
        if (lang) {
            this.setLang(lang);
        }
    }

    isRouteX(): boolean {
        const pageURL = window.location.href;
        return pageURL.includes("/pim/element") && window.innerWidth > 992;
    }

    logout(): void {
        this._authService.logout();
    }

    public isAdmin(): boolean {
        return this.acl.MAESTRO_MODULE_ADMIN_MODULE === 1;
    }

    public isPim(): boolean {
        return this.acl.MAESTRO_MODULE_PIM_MODULE === 1;
    }

    public isDam(): boolean {
        return this.acl.MAESTRO_MODULE_DAM_MODULE === 1;
    }

    public isProject(): boolean {
        return this.acl.MAESTRO_MODULE_PROJECT_MODULE === 1;
    }

    /**
     * Change the lang
     * @param lang
     */
    setLang(lang: string): void {
        this._translate.use(lang);
        localStorage.setItem('systemLanguage', lang);
    }

    getSelectedLang(): string {
        const systemLang = localStorage.getItem('systemLanguage');
        const selectedLang = ['en', 'fr'].includes(systemLang) ? systemLang : this._translate.currentLang;
        //this._translate.use(selectedLang);
        return selectedLang;
    }

    goToProfile() {
        return this._router.navigate([MAESTRO_ROUTES.admin.base, MAESTRO_ROUTES.admin.users, this._userService.getUserIdFromToken(), MAESTRO_ROUTES.actions.update]);
    }

    goToSubject(id: number, type: string) {
        if (type == "product") {
            return this._router.navigate([MAESTRO_ROUTES.pim.base, MAESTRO_ROUTES.pim.element, MAESTRO_ROUTES.actions.update, id]);
        }
    }

    calculateDateDiff(sentDate) {
        var date1: any = new Date(sentDate);
        var date2: any = new Date();
        let diffDays = "";
        if (date2 - date1 > 1000 * 60 * 60 * 24) {
            diffDays = `${Math.floor((date2 - date1) / (1000 * 60 * 60 * 24))} ${this._translate.instant("general.daysAGo")}`;
        } else if (date2 - date1 > 1000 * 60 * 60) {
            diffDays = `${Math.floor((date2 - date1) / (1000 * 60 * 60))} ${this._translate.instant("general.hoursAgo")}`;
        } else {
            diffDays = `${Math.floor((date2 - date1) / (1000 * 60))} ${this._translate.instant("general.minutesAgo")}`;
        }

        return diffDays;
    }

    countNewNotif() {
        this._notificationService.nbNewNotification(this._userService.getUserIdFromToken()).subscribe((data) => {
            this.nbNewNotif = data.data["new"];
            this.nbAllNotif = data.data["all"];
        });
        return this.nbNewNotif;
    }

    seeNotif(more: boolean, event) {
        event.preventDefault();
        if (this.notifOpen === false || more) {
            if (more) {
                this.indexNotif++;
            } else {
                this.notifOpen = true;
                this.indexNotif = 0;
                this.notifications = [];
            }
            this.listNotif();
        } else {
            this.notifOpen = false;
            $("#notifs").removeClass("show");
        }
    }

    readNotif(notif: any, event: any) {
        event.stopPropagation();
        let index = this.notifications.map((notification) => notification.id).indexOf(notif.id);

        if (index >= 0) {
            this.notifications[index].isRead = true;
            this._notificationService.readNotification(notif.id).subscribe();
        }
    }

    readAllnotif() {
        this.notifications.forEach((element) => (element.isRead = true));
        this._notificationService.UpdateReadAllAction().subscribe(() => {
            this.countNewNotif();
        });
    }

    listNotif() {
        this._notificationService.listNotifications(this._userService.getUserIdFromToken(), this.indexNotif, this.filterNotif).subscribe((data) => {
            let ids = [];

            data.data.notifs.forEach((notif) => {
                this.notifications.push(notif);
                if (!notif.isSee) {
                    ids.push(notif.id);
                    notif.isSee = true;
                    this.nbNewNotif--;
                }
            });
            for (let index = 0; index < this.notifications.length; index++) {
                if (!this.notifications[index].isSee) {
                    ids.push(this.notifications[index].id);
                    this.notifications[index].isSee = true;
                }
            }
            if (ids.length > 0) {
                this._notificationService.seeNotifications(ids).subscribe();
            }

            this.nbAllNotif = data.data.nbNotif;
        });
    }

    onSelectChange(event: any) {
        this.filterNotif = event.target.value;
        this.indexNotif = 0;
        this.notifications = [];
        this.listNotif();
    }

    @HostListener("document:click", ["$event"])
    onPageClick(event: MouseEvent) {
        const targetElement = event.target as HTMLElement;

        // Vérifier si le clic est effectué à l'intérieur du bloc des notifications
        if (!this.el.nativeElement.contains(targetElement)) {
            this.notifOpen = false;
        }
    }

    downloadFileFromNotif(notif: any) {
        this._exportPageService.getPackage(notif.info.path).subscribe((res) => {
            this._dataService.downloadFile(res.data, notif.subject, "application/gzip");
        });
    }
}
