<span *ngIf="breadcrumbDisplay">
    <button  *ngIf="!selectedLanguage?.isBasic && languagesList.length > 0 " [ngbTooltip]="translationTooltip()"   (click)="displayTraduction()" class="btn btn-primary mr-2">
        <svg *ngIf="!isDisplayTraduction;else elseblock" xmlns="http://www.w3.org/2000/svg" width="17.5"
             height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
             stroke-linecap="round" stroke-linejoin="round"
             class="lucide lucide-flag"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"/><line x1="4" x2="4" y1="22" y2="15"/></svg>
        <ng-template #elseblock>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="lucide lucide-flag-off"><path d="M8 2c3 0 5 2 8 2s4-1 4-1v11"/><path d="M4 22V4"/><path d="M4 15s1-1 4-1 5 2 8 2"/><line x1="2" x2="22" y1="2" y2="22"/></svg>
        </ng-template>
    </button>
    <app-language-selector></app-language-selector>
</span>

