<div class="row d-flex justify-content-center search-container">
    <div class="col-md-6 mb-2 text-right" *ngIf="acl.SYSPAD_PIM_ELEMENT_CREATE === 1">
        <button id="createElement" (click)="openModal()" class="btn btn-lg col-lg-12 btn-primary" data-toggle="modal" data-target="#createModal">{{
            'pim.element.create' | translate }}</button>
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12 table-div">
        <app-home-table tableTitle="{{ 'pim.home.last.actions' | translate }}" [data]="lastActions | tableData"
            (action)="onGlobalLastEdit($event, true)" type="lastActions"> </app-home-table>
    </div>

    <div class="col-lg-6 col-md-12 table-div">
        <app-home-table tableTitle="{{ 'pim.home.last.updates' | translate }}" [data]="tasks | tableData"
            (action)="onGlobalLastEdit($event, false)" type="lastUpdates"> </app-home-table>
    </div>
</div>
<div class="row">
    <div class="col table-div">
        <app-home-workflow-table [data]="workflowData" (action)="onWorkflowClick($event)" type="lastWorkflows"></app-home-workflow-table>
    </div>
</div>

<!-- @TODO: Create a shared component ? -->
<div #createModal data-backdrop="false" class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ "breadcrumb.pim.element.create" | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="chips = []">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <i class="pi pi-info-circle"></i> {{ elementsSeparator }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="form-group p-fluid">
                    <p-chips class="form-control" [(ngModel)]="chips" allowDuplicate="false" class="w-100">
                        <ng-template let-item pTemplate="item">
                            <span>{{item}}</span>
                        </ng-template>
                    </p-chips>
                </div>

                <div class="form-group">
                    <select class="form-control" id="modalElements">
                        <option *ngFor="let elementType of elementTypes" value="{{ elementType.id }}">{{ elementType.name }}</option>
                    </select>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="chips = []">{{ 'general.close' | translate }}</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="create()" [disabled]="!chips.length">{{ 'general.save' | translate }}</button>
            </div>
        </div>
    </div>
</div>
