<!-- Modal -->
<div class="row" *ngIf="form">
    <div class="col-12">
        <h3 *ngIf="dataProfile">
            {{ 'admin.users.configure' | translate }}
        </h3>
        <h3 *ngIf="!dataProfile">{{ 'admin.users.create' | translate }}</h3>
    </div>
</div>
<div class="tableComponent row">
    <div class="col-12">
        <div class="card">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="formSubmit()" autocomplete="off">
                <div class="card-body">
                    <ng-template [ngIf]="activeRoute === 'create' || activeRoute === 'update'">
                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="lastName" translate>{{ 'admin.users.lastName' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <input type="text" id="lastName" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': formControls.lastName.dirty && formControls.lastName.errors }"/>
                                <div *ngIf="formControls.lastName.dirty && formControls.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="formControls.lastName.errors.required">
                                        {{ 'general.required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="firstName" translate>{{ 'admin.users.firstName' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <input type="text" id="firstName" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': formControls.firstName.dirty && formControls.firstName.errors }"/>
                                <div *ngIf="formControls.firstName.dirty && formControls.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="formControls.firstName.errors.required" translate>
                                        {{ 'general.required' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="email" translate>{{ 'admin.users.email' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <input type="email" id="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': formControls.email.dirty && formControls.email.errors }" (change)="onChange($event)" />
                                <div *ngIf="formControls.email.dirty && formControls.email.errors" class="invalid-feedback">
                                    <div *ngIf="formControls.email.errors.required">
                                        {{ 'general.required' | translate }}
                                    </div>
                                    <div *ngIf="formControls.email.errors.emailMatch">
                                        {{ 'admin.users.error.invalid' | translate }}
                                    </div>
                                    <div *ngIf="formControls.email.errors.duplicate">
                                        {{ 'admin.users.error.exist' | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="profile">{{ 'admin.roles.role' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <select formControlName="profile" class="custom-select col" id="profile">
                                    <ng-container *ngFor="let profile of profiles">
                                        <option [value]="profile.id">{{ profile.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">

                            <label class="col-5 col-md-2" >{{ 'admin.groups.title' | translate }}</label>

                            <div class="col-7 col-md-10">

                                <p-multiSelect display="chip" formControlName="formGroups" defaultLabel="{{ (groups.length ? 'admin.groups.selectAssociated' : 'admin.groups.noGroup') | translate }}" [options]="groups" optionLabel="name" [style]="{'width':'100%'}"></p-multiSelect>

                            </div>
                        </div>

                        <div *ngIf="form.value.sendEmail" class="form-group row">
                            <label class="col-5 col-md-2" for="sendEmail" translate>{{ 'admin.users.sendEmail' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <label class="switch switch-success mb-0" style="padding-top: 5px;">
                                    <input id="sendEmail" type="checkbox" class="switch-input" formControlName="sendEmail">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="isWorkflowMail" translate>{{ 'admin.users.mailType' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <p-multiSelect display="chip" formControlName="isWorkflowMail" defaultLabel="{{ 'admin.mail.selectMail' | translate }}" [options]="receiveMail" optionLabel="name" [style]="{'width':'100%'}"></p-multiSelect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="systemLanguage">{{ 'admin.users.systemLanguage' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <select formControlName="systemLanguage" class="custom-select col" id="systemLanguage">
                                    <option value="fr" [selected]="form.value.systemLanguage === 'fr' ? true : null">{{ "general.lang.french" | translate }}</option>
                                    <option value="en" [selected]="form.value.systemLanguage === 'en' ? true : null">{{ "general.lang.english" | translate }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-5 col-md-2" for="contentLanguages" translate>{{ 'admin.users.contentLanguages' | translate }}</label>
                            <div class="col-7 col-md-10">
                                <p-multiSelect display="chip" formControlName="contentLanguages" defaultLabel="{{ 'admin.users.contentLanguages' | translate }}" [options]="allLanguages" optionLabel="label" optionValue="internationalCode" [style]="{'width':'100%'}"></p-multiSelect>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div class="card-footer">
                    <div class="row">
                        <button class="btn btn-danger" type="button" (click)="onBack()">
                            <fa-icon [icon]="faTimes"></fa-icon>
                            <span class="d-none d-md-inline-block ml-2">{{ 'general.close' | translate }}</span>
                        </button>
                        <div class="btn-group ml-auto">
                            <button *ngIf="currentIdUser === +userId && activeRoute === 'update'" class="btn btn-warning" (click)="changePassword()">
                                <fa-icon [icon]="faKey"></fa-icon>
                                <span class="d-none d-md-inline-block ml-2">
                                    {{ 'change_password.change' | translate }}
                                </span>
                            </button>
                            <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="formSubmit()">
                                <fa-icon [icon]="faSave"></fa-icon>
                                <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
