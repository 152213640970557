<div class="tableComponent row">
    <div class="col-lg-12">
        <div class="row">
            <div class="col-md-12">
                <app-datatable
                    tableTitle="{{ 'projects.title' | translate }}"
                    [actions]="actions"
                    (action)="onActionClick($event)"
                    searchDataParameter="project"
                    (lazyEvent)="loadList($event)"
                    [tableRows]="tableRows"
                    [tableColumns]="tableColumns"
                    [totalRecords]="totalRecords"
                    [recordsFiltered]="recordsFiltered"
                    [globalFilterFields]="globalFilterFields"
                    [_selectedColumns]="_selectedColumns"
                    [filters]="allFilters"
                    [shortPaginatorIsFirstPage]="shortPaginatorIsFirstPage"
                    [shortPaginatorIsLastPage]="shortPaginatorIsLastPage"
                    (shortPaginatorPrev)="shortPaginatorPrev()"
                    (shortPaginatorReset)="shortPaginatorReset()"
                    (shortPaginatorNext)="shortPaginatorNext()"
                    [first]=currentDatatableFilters.first
                    [showAdvancedSearch]="true"
                    (changeAdvancedSearch)="changeAdvancedSearch($event)"
                    [selectRows]="true"
                    [exportRows]="true"
                    (downloadArchive)="downloadArchive($event)"
                    >
                </app-datatable>
            </div>
        </div>
    </div>
</div>

<app-modal-form-language></app-modal-form-language>
