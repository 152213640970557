<div class="input-group">
    <div class="input-group-prepend">
        <button class="btn btn-outline-secondary" (click)="active ? d.toggle() : null" type="button">
            <fa-icon [icon]="faCalendar"> </fa-icon>
        </button>
    </div>
    <input value="{{ value | date: 'dd/MM/yyyy' }}" [readOnly]="true" type="text" floating-label [name]="'name'" ngbDatepicker [minDate]='minDate' (dateSelect)="onDateSelect($event)" #d="ngbDatepicker" class="form-control" />
    <button class="btn btn-danger" *ngIf="isDateFilled()" (click)="resetDate()" [disabled]="(selectedLanguage && !selectedLanguage.isBasic)">X</button>

</div>

