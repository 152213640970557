import { Component,OnInit } from "@angular/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { Language } from "app/shared/models/language";

@Component({
    selector: "app-language-selector",
    templateUrl: "./language-selector.component.html",
})
export class LanguageSelectorComponents implements OnInit {
    languagesList: Language[] = []
    isDisplayTraduction:boolean;
    selectedLanguage;

    constructor(private _languageService : LanguageService){}

    ngOnInit() {
        this._languageService.languageOptions$.subscribe((options) => {
            this.languagesList = options;

            if (this.languagesList.length > 0) {
                setTimeout(() => {
                    this.loadData(this.languagesList[0]);
                }, this.getSeconds());
            }
        });
        this._languageService.isToggled$.subscribe(data => {
            this.isDisplayTraduction = data;
        })

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });
    }

    loadData(selectedLanguage: Language) {
        // Utilisez la fonction pour mettre à jour la langue sélectionnée
        this._languageService.setSelectedLanguage(selectedLanguage);
        if(selectedLanguage.isBasic){
            return;
        }else {
            this._languageService.displayTraduction();
        }
    }

    getSeconds(): number {
        const urlWithoutHost = window.location.href.replace(/^https?:\/\/[^\/]+/, ''); // Supprimer le HOST de l'URL
        return urlWithoutHost.includes('/projects') ? 5000 : 2000;
    }
}
