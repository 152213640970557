// @TODO : Remove this pipe when datatable.component will be full implemented

import { Pipe, PipeTransform } from "@angular/core";
import { TableDef } from "app/shared/components/table";
import { MaestroElement } from "app/shared/models";

@Pipe({
    name: "tableData",
})
export class TableDataPipe implements PipeTransform {
    /**
     *
     * @param {any[]}  value { any } todo: define a real model
     * */
    transform(value: any[]): TableDef {
        let columns = [];
        const rows = [];
        if (value && value.length) {
            value.forEach((rowset: MaestroElement) => {
                columns = Object.keys(rowset);

                // Remove ID column
                const values = Object.keys(rowset).map((index) => rowset[index]);
                values.shift();
                rows.push([rowset.id, values /*, rowset.auths*/]);
            });
            // Remove ID column
            columns.shift();
        }

        return [columns, rows];
    }
}
