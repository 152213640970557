import { Component,OnInit,OnDestroy } from "@angular/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { Language } from "app/shared/models/language";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-bread-crumb-traduction",
    templateUrl: "./bread-crumb-traduction.component.html",
    styleUrls:['bread-crumb-traduction.component.scss']
})
export class BreadCrumbTraductionComponent implements OnInit,OnDestroy {
    languagesList: Language[] = []
    isDisplayTraduction:boolean;
    selectedLanguage;
    breadcrumbDisplay:boolean
    constructor(private _languageService : LanguageService,private _translate: TranslateService){}

    ngOnInit() {
        this._languageService.enable$.subscribe(display => {
            this.breadcrumbDisplay = display;
        });
        this._languageService.languageOptions$.subscribe((options) => {
            options.forEach((l) =>  l.isVisible)
            this.languagesList = options;
        });
        this._languageService.isToggled$.subscribe(data => {
            this.isDisplayTraduction = data;
        })

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });
 }
    ngOnDestroy() {
        this._languageService.enable = false;
    }

    displayTraduction(){
        this._languageService.toggle();
    }

    loadData(event) {
        const selectedLanguage: Language = event.value;
        // Utilisez la fonction pour mettre à jour la langue sélectionnée
        this._languageService.setSelectedLanguage(selectedLanguage);
        if(selectedLanguage.isBasic){
            return;
        }else {
            this._languageService.displayTraduction();
        }
    }

    translationTooltip(){
        if(this.selectedLanguage?.isBasic){
            return this._translate.instant('translation.disabledButton');
        }
        if(!this.isDisplayTraduction){
            return this._translate.instant('translation.displayTranslation');
        } else {
            return (this._translate.instant('translation.hiddenTranslation'));
        }
    }
}
