import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { ListUpdateComponent } from "./list-update.component";
import { ListUpdateGuard } from "./list-update.guard";
import { ListUpdateResolver } from "./list-update.resolver";
import {
    LanguageSelectorModule
} from "app/shared/components/language-selector/language-selector.module";

@NgModule({
    imports: [SharedModule, LanguageSelectorModule],
    declarations: [ListUpdateComponent],
    exports: [],
    providers: [ListUpdateGuard, ListUpdateResolver],
})
export class ListUpdateModule {}
