<div   *ngIf="display && display.display">
    <section class="d-flex justify-content-between  align-items-center"
        *ngIf="display.places && display.places.length > 0 && !isTrad">
        <ul class="d-flex tab-workflow justify-content-between align-items-center w-100">
            <ng-container *ngFor="let place of places; let i = index">
                <li class="position-relative text-center"
                    [tooltipClass]="(i < indexCurrentPlace  && canSetValue) ? 'cancel-workflow' : (place.isActive && canSetValue) ? 'valid-workflow' : null"
                    [ngbTooltip]="((i < indexCurrentPlace && !place.disabled) && canSetValue) ? cancelWorkflow : (place.isActive && canSetValue)  ? validateTheWorkflow : null"
                    >
                    <button
                        (click)="getTransitionKey(display.places[i === indexCurrentPlace  ? i +1 :  i],$event)"
                        [ngClass]="{'forbidden-input': (i !== indexCurrentPlace - 1 && i !== indexCurrentPlace) || !canSetValue}"
                        [disabled]=" (i !== indexCurrentPlace - 1 && i !== indexCurrentPlace) || !canSetValue" >
                        <span class="circle"
                            [style]="(place.isActive && canSetValue) ? {'border-color' :'#0c83ff','color': '#0c83ff'} : (i < indexCurrentPlace) ? {'background-color' :'#00BDAC'} :  (canSetValue ? null : {'background-color' :'#C8CED3'})"
                        >
                        <i *ngIf="i === indexCurrentPlace && canSetValue" class="bi bi-pencil"></i>
                        <i *ngIf="i < indexCurrentPlace " class="bi bi-check-lg"></i>
                        <p class="number" *ngIf="(i > indexCurrentPlace - 1 && i !== indexCurrentPlace && !place.isValidated) || (i > indexCurrentPlace - 1 && !canSetValue)">{{ i + 1 }}
                        </p>
                        </span>
                    </button>
                    <div class="position-absolute style-position ff-title" style="z-index: 10;">
                        {{ place.title }}
                        <i *ngIf="display.places[i].transitions !== undefined" (click)="getInfoWorkflow(display.places[i])" title="{{ getTitle(place) }}" class="bi bi-clock"></i>                       
                        <div *ngIf="display.places[i].languages.length > 0" class="languageTooltip pl-1"><i class="bi bi-flag"></i>
                            <span class="tooltiptext" >
                               <ul>
                                    <li *ngFor="let language of display.places[i].languages" class="row"><span class="col-6 pl-0 pt-0">{{language.name}}</span> <fa-icon class="col-1 pt-0" [icon]="language.valid ? faValidate : faClose"></fa-icon> </li>
                               </ul>
                            </span>
                        </div>
                    </div>
                    
                </li>
                <div [style]="i < indexCurrentPlace ? {'border' : '1px solid #00BDAC'} : {'border' : '1px solid #c8ced3'}" *ngIf="i % places.length != places.length -1" class="hr-style"></div>
            </ng-container>
        </ul>

    </section>

    <section class="d-flex justify-content-between  align-items-center"
        *ngIf="display.places && display.places.length > 0 && isTrad">
        <ul class="d-flex tab-workflow justify-content-between align-items-center w-100">
            <ng-container *ngFor="let place of places; let i = index">
                <li class="position-relative text-center"
                    [tooltipClass]="(place.isActiveTrad && place.isValidatedTrad  && canSetValue && i < indexCurrentPlace) ? 'cancel-workflow' : (place.isActiveTrad && !place.isValidatedTrad && canSetValue && i < indexCurrentPlace) ? 'valid-workflow' : null"
                    [ngbTooltip]="(place.isActiveTrad && place.isValidatedTrad && canSetValue && i < indexCurrentPlace) ? cancelWorkflow : (place.isActiveTrad && !place.isValidatedTrad && canSetValue && i < indexCurrentPlace)  ? validateTheWorkflow : null"
                    >
                    <button
                        (click)="validationTradStep(place.id,$event)"
                        [ngClass]="{'forbidden-input': !place.isActiveTrad|| !canSetValue || i >= indexCurrentPlace}"
                        [disabled]=" !place.isActiveTrad || !canSetValue || i >= indexCurrentPlace" >
                        <span class="circle"
                            [style]="{'border-color' : (place.isActiveTrad && canSetValue ? '#0c83ff' : null),'color': (place.isActiveTrad && canSetValue ? '#0c83ff' : null), 'background-color' : (place.isValidatedTrad ? '#00BDAC' :(place.isActiveTrad ? null : '#C8CED3') )}"
                        >
                        <i *ngIf="canSetValue && !place.isValidatedTrad && place.isActiveTrad" class="bi bi-pencil"></i>
                        <i *ngIf="place.isValidatedTrad" class="bi bi-check-lg"></i>
                        <p class="number" *ngIf="(!place.isActiveTrad || !canSetValue)">{{ i + 1 }}
                        </p>
                        </span>
                    </button>
                    <p class="position-absolute style-position ff-title">
                        <span *ngIf="!isTrad">
                            {{ place.title }}
                        </span>
                        <span *ngIf="isTrad">
                            {{ place.titleTrad }}
                        </span>
                        <i *ngIf="display.places[i].transitionsLanguage !== undefined && display.places[i].transitionsLanguage.length > 0" (click)="getInfoWorkflow(display.places[i])" title="{{ getTitle(place) }}" class="bi bi-clock"></i>
                    </p>
                </li>
                <div [style]="{'border' : '1px solid #c8ced3'}" *ngIf="i % places.length != places.length -1" class="hr-style"></div>
            </ng-container>
        </ul>

    </section>
</div>
