import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { Language } from "app/shared/models/language";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import {MaestroUser} from "app/shared/models";

@Component({
    selector: "app-modal-form-language",
    templateUrl: "modal-form-language.component.html",
    styleUrls: ["modal-form-language.component.scss"],

})
export class ModalFormLanguageComponent implements AfterViewInit {

    @ViewChild("createModal", { static: false }) createModal: SwalComponent;
    dataProfile:any;
    form: FormGroup;
    languagesList: Language[];
    allLanguages: Language[];
    browserLang: string;
    profiles: any;
    profileId: any;
    browserContentLanguage:any;
    groupsProfiles:any;
    isDisplayModal;
    systemLanguage;
    contentLanguages;

    constructor(private _userService: UserService, private formBuilder: FormBuilder, private _languageService: LanguageService, private _translateService: TranslateService, private _authService: AuthService,private router: Router) {
        this.isDisplayModal = localStorage.getItem('isDisplayModal');
        this.systemLanguage = localStorage.getItem('systemLanguage');
        this.contentLanguages = localStorage.getItem('contentLanguages') ? JSON.parse(localStorage.getItem('contentLanguages')) : [];
        this.browserLang = this._translateService.getBrowserLang();
    }

    ngAfterViewInit() {
        if(this._authService.isLoggedIn()){
            this._languageService.allLanguages$.subscribe((languages) => {
                this.languagesList = languages.filter(lang => lang.isVisible);
                this.displayModalLanguages();
            });

        }
    }

    displayModalLanguages() {
        if( this.contentLanguages.length === 0 && this.languagesList.length > 0 ) {
            const currentUser = this._userService.getUserIdFromToken();
            this.browserContentLanguage = this.languagesList.find(language => language.code === this.browserLang);
            this.initForm();

            if(this.isDisplayModal === "null" ){
                this.createModal.fire().then(result => {
                    localStorage.setItem('isDisplayModal',"true")
                    if(result.value){
                        this._userService.getById(currentUser).subscribe((maestroUser: MaestroUser) => {
                            if (maestroUser && maestroUser.data) {
                                this.dataProfile = maestroUser.data;
                                this._userService.getProfiles().subscribe(p => {
                                    this.profiles = p.data;
                                    this.groupsProfiles =  this.dataProfile.groups.map((g:any) => g.id);
                                    const nameToSearch = this.dataProfile.profile;
                                    const profile = this.profiles.find(profile => profile.name === nameToSearch);
                                    // Si un profil correspondant est trouvé, obtenir son ID
                                    if (profile) {
                                        this.profileId = profile.id;

                                        //UPDATE USER
                                        this._userService.updateUser(this.dataProfile.id, this.dataProfile.lastName, this.dataProfile.firstName, this.dataProfile.email, this.profileId, this.groupsProfiles, this.form.value.systemLanguage, this.form.value.contentLanguages, this.dataProfile.sendMail).subscribe(() => {
                                            if (this._userService.getUserIdFromToken() === this.dataProfile.id) {
                                                this._authService.refreshToken(true);
                                                localStorage.setItem("systemLanguage", this.form.value.systemLanguage);
                                                localStorage.setItem("contentLanguages", JSON.stringify(this.form.value.contentLanguages));
                                                this._languageService.updateLangOptions(this.allLanguages);
                                            }
                                            localStorage.setItem('isDisplayModal', 'true')
                                        });
                                        //END UPDATE USER
                                    } else {
                                        console.log("Aucun profil correspondant trouvé.");
                                    }
                                });
                            }
                        });
                    } else {
                        localStorage.setItem('isDisplayModal',"false");
                    }
                })
            }
        }
    }

    initForm() {
        this.form = this.formBuilder.group({
            systemLanguage: [this.browserLang ? this.browserLang : '', Validators.required],
            contentLanguages: [ this.browserLang && this.browserContentLanguage ? [this.browserContentLanguage.internationalCode] : [],Validators.required]
        });
    }


}
