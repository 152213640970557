<!-- @TODO : Remove this component when datatable.component will be full implemented -->
<div class="card">
    <div class="card-body">
                <h3 class="text-primary text-center">{{ tableTitle }}
                <span class="text-right ml-3" *ngIf="'lastActions' === type || 'lastUpdates' === type || 'lastWorkflows' === type">
                    <p-dropdown [options]="rowsPerPageOptions" (onChange)="loadData($event)"></p-dropdown>
                </span>
            </h3>
        <hr />
        <div *ngIf="!table || table.length === 0" class="text-center">
            <span class="font-italic font-weight-bold" translate>{{ 'general.no.result' | translate }}</span>
        </div>
        <div class="table-responsive">
            <table *ngIf="table && table.length" class="table table-hover">
                <thead>
                    <tr>
                        <ng-container *ngFor="let column of columns; let i = index">
                            <th scope="col" *ngIf="(i < 1 || !preview || preview.length === 0) && column !== 'draft' && column !== 'idOwnerGroup' && column !== 'idUser' && column !== 'archived'"> <!--&& column !== 'auths'-->
                                <span *ngIf="column === 'groups'"><i class="fas fa-users"></i></span> {{ 'table.' + column | translate }}
                            </th>
                        </ng-container>
                        <th *ngIf="!preview || preview.length === 0" scope="col">{{ 'table.actions' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let row of table;let index = index">

                        <tr
                            [ngClass]="{ 'table-primary font-weight-bold': preview && preview[0] && preview[0].id == row[0] }">
                            <ng-container *ngFor="let value of row[1]; let i = index">
                                <td *ngIf="columns[i] !== 'idOwnerGroup' && columns[i] !== 'idUser' && columns[i] !== 'action' && columns[i] !== 'tags' && columns[i] !== 'groups' && (!preview || preview.length === 0) && columns[i] !== 'draft' && columns[i] !== 'archived'"><!--&& columns[i] !== 'auths'-->
                                    <span *ngIf="i === 0">{{ value }}</span>
                                    <div [ngClass]="isBadge(value,i)" *ngIf="i > 0" [innerHtml]="safeHtml(value,i)"></div>

                                </td>
                                <td *ngIf="columns[i] !== 'idOwnerGroup' && columns[i] !== 'idUser' && columns[i] !== 'action' && columns[i] === 'tags' && columns[i] !== 'groups' && (!preview || preview.length === 0) && columns[i] !== 'draft' && columns[i] !== 'archived'"><!--&& columns[i] !== 'auths'-->
                                    <span class="badge badge-pill badge-primary mr-1" *ngFor="let tag of value">{{tag}}</span>
                                </td>
                                <td *ngIf="columns[i] !== 'idOwnerGroup' && columns[i] !== 'idUser' && columns[i] !== 'action' && columns[i] !== 'tags' && columns[i] === 'groups' && (!preview || preview.length === 0) && columns[i] !== 'draft' && columns[i] !== 'archived'"><!--&& columns[i] !== 'auths'-->
                                    <span class="badge badge-pill badge-primary mr-1" *ngFor="let group of value">{{group}}</span>
                                </td>
                                <td *ngIf="columns[i] !== 'idOwnerGroup' && columns[i] !== 'idUser' && columns[i] !== 'action' && preview && preview.length > 0 && i === 0 && columns[i] !== 'archived'"><!--&& columns[i] !== 'auths'-->
                                    <div (click)="this.select.emit(row[0])" class="pointer" [innerHtml]="safeHtml(value)"></div>
                                </td>
                                <td *ngIf="columns[i] === 'action'">
                                    <div>{{ "table.actionType." + value | translate }}</div>
                                </td>
                            </ng-container>
                            <td *ngIf="controlActionShow(row); else elseBlock">
                                <app-action-buttons [groupRight]="row[2] ? row[2]: []" [actions]="actions" (action)="onActionClick($event, row[0])" [hideViewIfEditAllowed]="hideViewButtonIfEditAllowed" buttonOutlined="true"></app-action-buttons>
                            </td>
                            <ng-template #elseBlock>
                                <td></td>
                            </ng-template>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
