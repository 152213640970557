import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ACL } from "app/shared/models/acl";
import jwt_decode from "jwt-decode";
import { BehaviorSubject, Observable, of, timer } from "rxjs";
import { catchError, mapTo, tap } from "rxjs/operators";
import { environment } from "src/environments";
import { User } from "../../../../shared/models";

@Injectable()
export class AuthService {
    private algorithm = ["HS256", "HS384", "HS512", "RS256", "RS384", "RS512", "ES256", "ES384", "ES512", "PS256", "PS384", "PS512"];

    showError$ = new BehaviorSubject<boolean>(true);

    private refreshInProgress = false;

    constructor(private http: HttpClient, private router: Router, private _swalService: SwalModalService, private _translateService: TranslateService) {}

    /**
     * Sign in into Maestro
     *
     * @param user
     * @returns
     */
    public login(user: { email: string; password: string }): Observable<boolean> {
        return this.http.post<any>(`${environment.adminAuthUrl}/sign_in.json`, user).pipe(
            tap((params) => this.storeToken(params.data)),
            mapTo(true),
            catchError((_) => {
                return of(false);
            })
        );
    }

    /**
     * When user sign in, store token
     *
     * @param user User
     */
    private storeToken(user: User) {
        localStorage.setItem("master", JSON.stringify({ code: user.master_code, language: user.master_language }));
        localStorage.setItem("current", JSON.stringify({ code: user.master_code, language: user.master_language }));
        localStorage.setItem("token", user.jwt);
        localStorage.setItem("refresh", user.refreshToken);
        localStorage.setItem("systemLanguage", user.systemLanguage);
        localStorage.setItem("contentLanguages", JSON.stringify(user.contentLanguages));
    }

    /**
     * Return the token from local storage
     *
     * @returns
     */
    public get currentUserValue() {
        return localStorage.getItem("token");
    }

    /**
     * Logout from the app by cleanning local storage and redirect to login page
     */
    public logout(badToken: boolean = false): void {
        localStorage.removeItem("master");
        localStorage.removeItem("current");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("systemLanguage");
        localStorage.removeItem("contentLanguages");

        if (badToken) {
            this._translateService.get("general.badTokenLogout").subscribe((data) => {
                // Avoid to display a modal without trads
                this._swalService.info(data);
            });
        }

        this.router.navigate(["login"]);
    }

    /**
     * Refresh the token
     *
     * @param force boolean
     */
    public refreshToken(force: boolean = false) {
        const source = timer(1000, 1000);

        source.subscribe(() => {
            const token = this.decodeToken();

            if (token && undefined !== token.exp && token.exp) {
                const exp = token.exp - 60;
                const date = Math.floor(new Date().getTime() / 1000);

                if ((date > exp || force) && !this.refreshInProgress) {
                    this.refreshInProgress = true;
                    this.updateToken().subscribe(() => {
                        force = false; // Prevent to refresh every seconds when it's a forced refresh like acl change
                    });
                }
            }
        });
    }

    /**
     * Update the token
     *
     * @returns
     */
    private updateToken() {
        return this.http.post<any>(`${environment.adminAuthUrl}/refresh.json`, { jwt: this.currentUserValue }).pipe(
            tap((user: any) => {
                localStorage.setItem("token", user.data.jwt);
                localStorage.setItem("refresh", user.data.refreshToken);
                this.refreshInProgress = false;
            })
        );
    }

    /**
     * Check if the user is logged in thanks to his token
     *
     * @return
     */
    public isLoggedIn() {
        let result;

        try {
            const header: { alg; typ } = jwt_decode(this.currentUserValue, { header: true });
            const token = this.decodeToken();

            if (undefined !== header.alg && this.algorithm.includes(header.alg) && undefined !== header.typ && "JWS" === header.typ && null !== token) {
                result = true;
            } else {
                result = false;
            }
        } catch (error) {
            result = false;
        }

        return result;
    }

    private decodeToken() {
        let token;

        try {
            token = jwt_decode(this.currentUserValue);
        } catch (error) {
            token = null;
        }

        return token;
    }

    public getUserIdFromToken(): number {
        return +this.getTokenParam("sub");
    }

    public getUserProfileIdFromToken(): number {
        return +this.getTokenParam("prof");
    }

    public getUserAclFromToken(): ACL {
        return this.getTokenParam("acl");
    }

    private getTokenParam(param: string) {
        const token = this.decodeToken();

        if (null !== token && undefined !== token[param]) {
            return token[param];
        } else {
            this.logout(true);
        }
    }
}
