<div>
    <button *ngIf="!field.addCollection && field.editable === true && selectedLanguage.isBasic" class="btn" (click)="addCollection(field, nav)">
        <fa-icon class="text-primary" [icon]="faPlus"></fa-icon>
    </button>
</div>
<ul ngbNav #nav="ngbNav" class="nav-tabs mt-3" [ngClass]="{ 'not-sortable': selectedLanguage.isBasic ? disabled : true}" style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; width: 500px;" [appSortable]="sortableJSOptions">
    <li *ngFor="let fieldset of field.value; let i = index" [ngbNavItem]="i">
        <a ngbNavLink class="sortable">
            <div>{{ (i + 1) }}
                <span *ngIf="!disabled && selectedLanguage.isBasic" class="position-relative ml-2 font-weight-light">
                    <fa-icon (click)="removeCollection($event, i,nav)" class="text-danger" [icon]="faTrash"></fa-icon>
                </span>
            </div>
        </a>
        <ng-template ngbNavContent>
            <div class="form-group row" *ngFor="let subField of fieldset">
                <label class="col-12 col-form-label text-truncate" for="">{{ subField.name }}</label>
                
                <div class="col-11">
                    <div class="row">
                        <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="selectedLanguage?.hasOwnProperty('flag') && !selectedLanguage.isBasic && ['text','textarea', 'number'].includes(subField.fieldType)">
                            <img [src]="selectedLanguage?.flag" />
                        </span>

                        <!-- Text -->
                        <input [disabled]="disabled || !subField.editable || (!selectedLanguage.isBasic && !subField.translatable)" (change)="updateValue($event.target.value, subField)" value="{{
                            selectedLanguage &&
                            !selectedLanguage.isBasic &&
                            subField.valueTranslations &&
                            subField.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                            subField.valueTranslations[selectedLanguage.internationalCode] &&
                            subField.translation
                                ? subField.valueTranslations[selectedLanguage.internationalCode]
                                : subField.value
                            }}" type="text" class="form-control col-11"
                               *ngIf="subField.fieldType === 'text'"/>
                        <!-- Number -->
                        <input [disabled]="disabled || !subField.editable || (!selectedLanguage.isBasic && !subField.translatable)" (change)="updateValue($event.target.value, subField)" (keypress)="checkIfNumber($event)" value="{{ selectedLanguage &&
                            !selectedLanguage.isBasic &&
                            subField.valueTranslations &&
                            subField.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                            subField.valueTranslations[selectedLanguage.internationalCode] &&
                            subField.translation
                                ? subField.valueTranslations[selectedLanguage.internationalCode]
                                : subField.value }}"
                               type="number" class="form-control col-11" *ngIf="subField.fieldType === 'number'"/>
                        <!-- Textarea -->
                        <app-text-editor [disabled]="disabled || !subField.editable || (!selectedLanguage.isBasic && !subField.translatable)" *ngIf="subField.fieldType === 'textarea'" [value]="selectedLanguage &&
                        !selectedLanguage.isBasic &&
                        subField.valueTranslations &&
                        subField.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                        subField.valueTranslations[selectedLanguage.internationalCode] &&
                        subField.translation
                            ? subField.valueTranslations[selectedLanguage.internationalCode]
                            : subField.value"
                                         (focusOut)="updateValue($event, subField)" class="col-11"></app-text-editor>
                        <!-- Checkbox -->
                        <div class="form-group" *ngIf="subField.fieldType === 'checkbox'">
                            <div class="col-10">
                                <label class="switch switch-pill switch-primary">
                                    <input [disabled]="disabled || !subField.editable || !selectedLanguage?.isBasic"
                                           (change)="updateValue($event.target.checked, subField)" type="checkbox"
                                           class="switch-input" [checked]="subField.value === '1'"
                                           value="{{ subField.value }}">
                                    <span class="switch-slider"></span>
                                </label>
                            </div>
                        </div>
    
                        <!-- Date -->
                        <app-date-picker *ngIf="subField.fieldType === 'date'"
                                         [active]="!disabled && subField.editable && selectedLanguage?.isBasic" (dateSelect)="updateValue($event, subField)" class="form-control p-0 col-11"
                                         [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}"
                                         [minDate]="{ year: 1930, month: 1, day: 1 }" [value]="subField.value"></app-date-picker>
    
                        <!-- List / Simple selection -->
                        <select [disabled]="disabled || !subField.editable || blockList || !selectedLanguage?.isBasic" (change)="updateValueList($event.target.value, subField)" [(ngModel)]="selectedLanguage &&
                        !selectedLanguage.isBasic &&
                        subField.valueTranslations &&
                        subField.valueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                        subField.valueTranslations[selectedLanguage.internationalCode]
                            ? subField.valueTranslations[selectedLanguage.internationalCode]
                            : subField.value" class="form-control col-11"
                            [ngClass]="{'offset-1' : !selectedLanguage?.isBasic ? true : null}"
                                *ngIf="subField.fieldType === 'list'">
                            <option></option>
                            <option *ngFor="let value of selectedLanguage &&
                            !selectedLanguage.isBasic &&
                            subField.listValueTranslations &&
                            subField.listValueTranslations.hasOwnProperty(selectedLanguage.internationalCode) &&
                            subField.listValueTranslations[selectedLanguage.internationalCode]
                                ? subField.listValueTranslations[selectedLanguage.internationalCode]
                                : subField.listValue">{{ value }}</option>
                        </select>
                        <!-- List / Multiple selection -->
                        <app-edit-list-multiple [disabled]="blockList || !selectedLanguage?.isBasic" (saveList)="updateValueList($event, subField)" [field]="subField" *ngIf="subField.fieldType === 'list multiple'" class="p-0" [ngClass]="!selectedLanguage?.isBasic ? 'offset-1 col-11' : 'col-12'"></app-edit-list-multiple>
    
                        <!-- Fieldset -->
                        <app-edit-fieldset [field]="subField" *ngIf="subField.fieldType === 'fieldset' || subField.fieldType === 'productLink'"></app-edit-fieldset>
    
                        <!-- Table -->
                        <app-edit-table *ngIf="subField.fieldType === 'table'" [isInput]="true" [field]="subField" (_saveValue)="_saveValue($event)"></app-edit-table>
    
                        <!-- Color -->
                        <app-color-picker-maestro
                            *ngIf="subField.fieldType === 'colors'"
                            (colorSelect)="updateValue($event, subField)"
                            [disabled]="disabled || !selectedLanguage?.isBasic"
                            [value]="subField.value"></app-color-picker-maestro>
                            
                        <!-- Media -->
                        <ng-container *ngIf="subField.fieldType === 'media'">
                            <app-edit-media [loadDataOnInit]="false" (mediasSaved)="updateValue($event, subField)" [medias]="subField.value" class="col-10 p-0" [disabled]="disabled || !subField.editable || !selectedLanguage?.isBasic" [pageDuplicateId]="undefined !== pageDuplicateId ? pageDuplicateId : -1" [singleSelection]="!subField.multiple" [forElementMedia]="false" [idElementValue]="subField.valuePimId" [idElementValueProject]="subField.valueProjectId ? subField.valueProjectId : -1"></app-edit-media>
                        </ng-container>
                    </div>
                </div>
                <div class="col-1" *ngIf="subField.fieldType !== 'productLink' && subField.fieldType !== 'fieldset'">
                    <svg *ngIf="subField.valueProjectId && !subField.isLoading" (click)="emitHistory(subField.valueProjectId, subField.fieldType)" style="width: 1.1rem; height: 1.1rem"
                         class="mt-2 pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                    <div *ngIf="subField.isLoading" class="spinner-border mx-auto text-primary spinner-border-sm align-middle" style="vertical-align: -webkit-baseline-middle !important;"
                         role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="col-11 my" *ngIf="!selectedLanguage.isBasic && ['text', 'textarea'].includes(subField.fieldType) && subField.translatable">
                    <div class="row">

                        <span class="col-1 d-flex justify-content-center align-items-center" *ngIf="basicLanguage?.hasOwnProperty('flag')">
                            <img [src]="basicLanguage?.flag" />
                        </span>

                        <input *ngIf="['text', 'number'].includes(subField.fieldType)" 
                        [value]="subField.value"
                        class="form-control col-11" 
                        [disabled]="true"/>

                        <app-text-editor *ngIf="subField.fieldType == 'textarea'" [disabled]="true" class="col-11">{{subField.value}}</app-text-editor>
                    </div>
                </div>
                <!-- <div class="col-1 mt-2"  *ngIf="productId && subField.pimInputId && subField.valueProjectId" [title]="_translate.instant('tooltip.reInitValuePIM')">
                    <fa-icon style="cursor: pointer;" (click)="reInitValue(subField, indexValue)" [icon]="faRedo"></fa-icon>
                </div> -->
            </div>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

