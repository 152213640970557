import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorkflowData } from "app/shared/components/workflow";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments";

@Injectable()
export class ExportPageService {
    constructor(private _http: HttpClient) {}

    /**
     * Get page by id
     * @param pageId
     * @returns
     */
    getById(pageId, pageDuplicateId?: number): Observable<any> {
        return this._http.post<any>(`${environment.projectsUrl}/page_export/${pageId}.json`, { pageDuplicateId: pageDuplicateId }).pipe(catchError((_) => of({ products: [1, 3, 4] })));
    }

    getTemplateByElement(pageId: number, elementId: number, exportId: number, pageDuplicateId: number = null, position) {
        return this._http.post(`${environment.projectsUrl}/element_gab_value/bis.json`, {
            pageId: pageId,
            elementId: elementId,
            exportId: exportId,
            pageDuplicateId: pageDuplicateId,
            position: position,
        });
    }

    getTemplateByElements(pageId: number, elementIds: number[], exportId: number, pageDuplicateId: number = null): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/element_gab_value/bis.json`, { pageId: pageId, elementId: elementIds, exportId: exportId, pageDuplicateId: pageDuplicateId });
    }

    /**
     * Update element's order in a page
     * @param id
     * @param products
     * @returns
     */
    updateTabsOrder(id: number, products: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/page/${id}/products.json`, { products: products });
    }

    /**
     * Get workflow for a page
     * @param pageId
     * @returns
     */
    getWorkflow(pageId: number, pageDuplicateId?: number): Observable<WorkflowData> {
        return this._http.post<WorkflowData>(`${environment.projectsUrl}/page/${pageId}/workflow/display.json`, { pageDuplicateId: pageDuplicateId });
    }

    /**
     * Get places for a page
     * @param pageId
     * @returns
     */
    getPlaces(pageId: number, pageDuplicateId?: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/${pageId}/place.json`, { pageDuplicateId: pageDuplicateId });
    }

    /**
     * Get authorisation for a page
     * @param pageId
     * @returns
     */
    getAutho(pageId: number, version?: number, pageDuplicateId?: number): Observable<boolean> {
        return this._http.post<boolean>(`${environment.projectsUrl}/page/${pageId}/set_value.json`, { version: version, pageDuplicateId: pageDuplicateId });
    }

    /**
     * Change a workflow step
     * @param data
     * @returns
     */
    validWorkflow(data: any): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/current_place.json`, data);
    }

    /**
     * Return medias associate to a product
     * @param id
     * @returns
     */
    getMedias(id?: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/media.json`, { id: id });
    }

    /**
     * Update media for a position
     *
     * @param object
     * @returns
     */
    updateMedia(object: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/element_media.json`, object);
    }

    /**
     * Update media for a position
     *
     * @param object
     * @returns
     */
    updateFieldMedia(id: number, object: any): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/element_media/${id}/field.json`, object);
    }

    /**
     * Remove media from a position
     * @param id
     * @returns
     */
    deleteMedia(id: number): Observable<any> {
        const pageId = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        return this._http.delete(`${environment.projectsUrl}/export_media/${pageId}/${id}.json`);
    }

    getPackage(path: string): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/download.json`, { path: path, observe: "response" as "body", responseType: "blob" });
    }
}
