import { NgModule } from "@angular/core";
import { CommonService } from "app/core/services/admin/common.service";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { LanguageListComponent } from "./language-list.component";

@NgModule({
    declarations: [LanguageListComponent],
    imports: [SharedModule, SharedComponentsModule],
    providers: [LanguageService, CommonService],
})
export class LanguageListModule {}
