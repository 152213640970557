import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { faArrowCircleRight, faClone, faRedo, faTrashRestoreAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { TableService } from "app/core/services/global/table/table.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { Language } from "app/shared/models/language";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import { PageTextTabService } from "../../../../../../../../core/services/project/export/page-text-tab.service";
import { ExportElement, ExportElementValue } from "../../../../../../../../shared/models/project/export-element.model";

@Component({
    selector: "app-page-text-tab",
    templateUrl: "./page-text-tab.component.html",
    styleUrls: ["./page-text-tab.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PageTextTabComponent implements OnInit, OnDestroy {
    @Input() product: ExportElement;
    @Input() disabled: boolean;
    @Input() products: ExportElement;

    @ViewChild("historyModal") historyModal: SwalComponent;

    history = [];
    showDiff = false;

    faClone = faClone;
    readonly faTrashRestoreAlt = faTrashRestoreAlt;
    faWindowClose = faWindowClose;
    faArrowCircleRight = faArrowCircleRight;
    faRedo = faRedo;

    private _exportId: number;
    private _pageId: number;
    version: number;
    pageDuplicateId: number;
    tableHistoryFromFieldset: boolean = false;
    nameProduct: string;
    acl: any;
    inputId: number = null;
    closeHide: boolean = false;
    emptyHide: boolean = true;
    inputHide: boolean = true;
    arrayInputId: number[] = [];
    // regex pour reconnaitre les balises vide.
    regexBalisePEmpty = /<\w+[^>]*>\s*<\/\w+>/g;

    tableHistory: boolean = false;
    fieldTypeHistory: string = "";
    checkboxHistory: boolean = false;
    colorsHistory: boolean = false;

    selectedLanguage: Language;
    basicLanguage: Language;
    dropdownList = [];


    languageSubscribe : Subscription;

    constructor(
        private _pageService: PageTextTabService,
        private _route: ActivatedRoute,
        private _loader: SpinnerService,
        private _toaster: ToastService,
        private _translate: TranslateService,
        private _sanitizer: DomSanitizer,
        private _tableService: TableService, // Used in html
        private _userService: UserService,
        private _languageService: LanguageService
    ) {
    }

    ngOnInit() {
        this._exportId = this._route.parent.parent.parent.snapshot.params.id;
        this._pageId = this._route.snapshot.params.id;
        this.version = this._route.queryParams["_value"]["version"];
        this.pageDuplicateId = this._route.queryParams["_value"]["duplicate"];
        this.acl = this._userService.getUserAclFromToken();

        this.languageSubscribe = this._languageService.selectedLanguage$.subscribe((language) => {
            this.selectedLanguage = language;
        });

        this.basicLanguage = this._languageService.getBasicLanguage();
    }

    ngOnDestroy(){
        this.languageSubscribe.unsubscribe();
    }

    displayInput(id: number, field) {
        if (field.editable === true) {
            this.inputId = id;
            this.closeHide = true;
            setTimeout(() => {
                document.getElementById("field-" + id).focus();
            }, 100);
        }
    }

    hideInput(id: number) {
        if (id === this.inputId) {
            this.inputId = null;
            this.closeHide = false;
        }
    }

    /**
     * permet d'afficher "empty" au textearea quand le text est effacer mais que la balise <p> reste
     * @param field champ de texte que l'on souhaite vérifier
     * @returns {boolean}
     */
    diplayEmptyTextarea(field) {
        let value = field.value;
        if (typeof value === "string") {
            //supprimer les balises <p></p> puis les caractères vides
            value = value.replace(/<\/?p>/g, "").trim();
        }
        const regex = /^\s*$/.test(value); // modification : on teste si le champ est vide
        if (((field.fieldType === "textarea" && regex) || (field.fieldType === "list" && (!field.value || field.value.length === 0))) && this.inputId !== field.templateTwigId) {
            return true;
        }
        return false;
    }

    /**
     * Update a value
     *
     * @param newValue
     * @param field
     */
    updateValue(newValue: string, field: ExportElementValue): void {
        if (this.selectedLanguage.isBasic) {
            if ("checkbox" === field.fieldType) {
                field.value = Number(newValue).toString();
            } else if ("media" === field.fieldType) {
                field.value = JSON.stringify(newValue);
            } else {
                field.value = newValue;
            }
        } else {
            if ("checkbox" === field.fieldType) {
                field.valueTranslations[this.selectedLanguage.internationalCode] = Number(newValue).toString();
            } else if ("media" === field.fieldType) {
                field.valueTranslations[this.selectedLanguage.internationalCode] = JSON.stringify(newValue);
            } else {
                field.valueTranslations[this.selectedLanguage.internationalCode] = newValue;
            }
        }

        this._saveValue(field);
    }

    /**
     * Update a list value
     *
     * @param newValue
     * @param field
     */
    updateValueList(newValue: any, field: ExportElementValue): void {
        const circularReferenceCheck = (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (objectsVisited.indexOf(value) !== -1) {
                    // Référence circulaire trouvée, renvoyer une représentation minimale
                    return { circularReference: true };
                }
                objectsVisited.push(value);
            }
            return value;
        };
        let objectsVisited: any[] = [];
        try {
            if (newValue instanceof Array) {
                let data = [];

                newValue.forEach((val) => {
                    if (val.hasOwnProperty("name")) {
                        data.push(val.name);
                    }
                });
                let arrayData = data.length ? data : newValue;

                field.value = JSON.stringify(arrayData, circularReferenceCheck);
            } else {
                field.value = JSON.stringify([newValue], circularReferenceCheck);
            }
            this._saveValue(field);
            try {
                field.value = JSON.parse(field.value);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
        } finally {
            objectsVisited = [];
        }
    }

    /**
     * Save a value
     *
     * @param field
     */
    private _saveValue(field: ExportElementValue) {
        this._loader.disable();
        field.isLoading = true;
        if (!field.countRequest) {
            field.countRequest = 1;
        } else {
            field.countRequest++;
        }

        if (typeof field.value === "string") {
            field.value = field.value.trim();
        }

        this._pageService
            .saveValue(field.value, field.valueTranslations, this._exportId, field.valueProjectId, field.elemTypeFieldId, field.productId, field.variableProjectId, this._pageId, this.version, this.pageDuplicateId, field.fieldType)
            .subscribe(
                (data) => {
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        field.valueProjectId = data.body.data.id;
                        field.value = field.fieldType === "list" || field.fieldType === "list multiple" ? JSON.parse(data.body.data.value) : data.body.data.value;

                        this._toaster.show({
                            message: this._translate.instant("general.savedfield", { key: field.key }),
                            type: "success",
                        });
                    }
                },
                () => {
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        this._toaster.show({
                            message: this._translate.instant("general.nosavedfield", { key: field.key }),
                            type: "danger",
                        });
                    }
                }
            );
    }

    checkIfNumber(event: any) {
        if (event.which !== 8 && isNaN(Number(String.fromCharCode(event.which)))) {
            event.preventDefault(); //stop character from entering input
        }
    }

    /**
     * Open history modal
     *
     * @param objectId
     * @param fieldType
     * @param tableHistoryFromFieldset
     */
    openHistory(objectId: number, fieldType: string, tableHistoryFromFieldset: boolean = false): void {
        this._pageService.getHistory(objectId).subscribe((response) => {
            this.history = tableHistoryFromFieldset ? this.parseNewValues(response) : response.map((item) => ({ ...item, isCopy: false }));

            this.historyModal.fire();
        });
        this.fieldTypeHistory = fieldType;
        this.tableHistory = fieldType === "table" ? true : false;
        this.checkboxHistory = fieldType === "checkbox" ? true : false;
        this.colorsHistory = fieldType === "colors" ? true : false;
        this.tableHistoryFromFieldset = tableHistoryFromFieldset;
    }

    showDiffSwitcher(): void {
        this.showDiff = !this.showDiff;
    }

    public copyToClip(value: string, index: number) {
        const currentElement = this.history[index];
        if (!currentElement.isCopy) {
            function listener(e) {
                e.clipboardData.setData("text/html", value);
                e.clipboardData.setData("text/plain", value);
                e.preventDefault();
            }

            document.addEventListener("copy", listener);
            document.execCommand("copy");
            document.removeEventListener("copy", listener);
            currentElement.isCopy = true;
            setTimeout(() => (currentElement.isCopy = false), 2000);
            // (<any>$("#tooltip-" + index)).tooltip("show");
            // setTimeout(() => (<any>$("#tooltip-" + index)).tooltip("hide"), 2000);
        }
    }

    sanitize(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }

    canUpdate(field) {
        let result;
        if (!this.version) {
            result = this.acl.MAESTRO_PROJECT_PROJECTS_UPDATE !== 1 || this.disabled || !field.editable;
        } else {
            result = this.disabled || !field.editable;
        }
        return result;
    }

    /**
     * Hack to get table value history in same format than simple field (same level of json parse)
     * @param history
     * @returns
     */
    parseNewValues(history: any) {
        history.forEach((element) => {
            if (element.hasOwnProperty("value") && undefined !== element.value[0] && element.value[0].hasOwnProperty("newValue") && element.value[0].newValue.hasOwnProperty("value")) {
                let value = element.value[0].newValue.value;
                try {
                    value = JSON.parse(value);
                    let result = [];
                    value.forEach((val) => {
                        result.push(val.name);
                    });

                    element.value[0].newValue.value = JSON.stringify(result);
                } catch (error) {
                    element.value[0].newValue.value = value;
                }
            }
        });

        return history;
    }

    changePToBr(field) {
        let value =
            this.selectedLanguage &&
                !this.selectedLanguage.isBasic &&
                field.valueTranslations &&
                field.valueTranslations.hasOwnProperty(this.selectedLanguage.internationalCode) &&
                field.valueTranslations[this.selectedLanguage.internationalCode]
                ? field.valueTranslations[this.selectedLanguage.internationalCode]
                : field.value;

        if (field.fieldType == "textarea" && field.value !== null) {
            let regex = new RegExp(this.regexBalisePEmpty).exec(field.value);
            if (regex && regex.length > 0) {
                regex.forEach((pEmpty) => {
                    value = field.value.replaceAll(pEmpty, "<br>");
                });
            }
        } else if (field.fieldType == "productLink" && field.value.length > 0) {
            value = [];
            field.value.forEach((element) => {
                value.push(element.name);
            });
        }

        if ((field.fieldType == "list" || field.fieldType == "list multiple") && this.selectedLanguage && !this.selectedLanguage.isBasic) {
            value = [];
            field.value.forEach(fieldVal => {
                let index = field.listValue.indexOf(fieldVal);
                
                if(field.listValueTranslations[index][this.selectedLanguage.internationalCode] ){
                    value.push(field.listValueTranslations[index][this.selectedLanguage.internationalCode]);
                } else {
                    value.push(field.listValue[index]);
                }
            });
        }

        return value;
    }

    isTypeOf(val, type = "string"): boolean {
        return typeof val === type;
    }

    reInitValue(field, indexField) {
        Swal.fire({
            title: this._translate.instant("general.reinitValue"),
            showCancelButton: true,
            confirmButtonText: this._translate.instant("modal.confirm"),
            cancelButtonText: this._translate.instant("general.cancel"),
        }).then((result) => {
            if (result.isConfirmed) {
                this._pageService.reInitValue(field.valueProjectId ? field.valueProjectId : 0, field.productId, field.elemTypeFieldId, field['layoutId'], this.pageDuplicateId, this.selectedLanguage.internationalCode, this.selectedLanguage.isBasic).subscribe(data => {
                    if (this.selectedLanguage.isBasic) {
                        this.product.values[indexField]['value'] = data.data ? data.data : null;
                    } else {

                        this.product.values[indexField]['valueTranslations'][this.selectedLanguage.internationalCode] = data.data ? data.data : null;
                    }
                });
            }
        });
    }
}
