<header class="app-header navbar">
    <button [style.visibility]="!isRouteX() ? 'visible' : 'hidden'" appSidebarButton class="navbar-toggler sidebar-toggler" type="button">
        <fa-icon class="text-white" [icon]="faBars" [fixedWidth]="true"></fa-icon>
    </button>
    <a class="navbar-brand d-block" routerLink="">
        <img class="navbar-brand-full mt-2" src="assets/img/logo.png" height="40" alt="Compos Juliot" />
        <img class="navbar-brand-minimized" src="assets/img/logo.png" width="30" height="30" alt="Compos Juliot" />
        <span *ngIf="showVersion === true" class="versions">v 2.0</span>
    </a>
    <ul class="nav navbar-nav d-md-down-none">
        <li *ngIf="activatedModules.dashboard" class="nav-item px-3">
            <a class="nav-link" [routerLink]="['/' + routes.dashboard.base]" routerLinkActive="active">
                <fa-icon [icon]="dashboardIcon" [fixedWidth]="true"></fa-icon>
                <span class="d-inline-block ml-1">
                    {{ 'header.dashboard' | translate }}
                </span>
            </a>
        </li>

        <li *ngIf="activatedModules.pim && hasPimAcl" class="nav-item px-3">
            <a class="nav-link" [routerLink]="['/' + routes.pim.base]" routerLinkActive="active">
                <fa-icon [icon]="pimIcon" [fixedWidth]="true"></fa-icon>
                <span class="d-inline-block ml-1">
                    {{ 'header.pim' | translate }}
                </span>
            </a>
        </li>
        <li *ngIf="activatedModules.dam && hasDamAcl" class="nav-item px-3">
            <a class="nav-link" [routerLink]="['/' + routes.dam.base]" routerLinkActive="active">
                <fa-icon [icon]="damIcon" [fixedWidth]="true"></fa-icon>
                <span class="d-inline-block ml-1">
                    {{ 'header.dam' | translate }}
                </span>
            </a>
        </li>
        <li *ngIf="activatedModules.project && hasProjectAcl" class="nav-item px-3">
            <a class="nav-link" [routerLink]="['/' + routes.projects.base]" routerLinkActive="active">
                <fa-icon [icon]="projectIcon" [fixedWidth]="true"></fa-icon>
                <span class="d-inline-block ml-1">
                    {{ 'header.project' | translate }}
                </span>
            </a>
        </li>
    </ul>
    <ul class="nav navbar-nav ml-auto">
        <span class="caret-style" [style]="{'display': notifOpen ? 'block': 'none'}">
            <i class="bi bi-caret-down-fill"></i>
        </span>
        <li id="notif">
            <div class="notif-position">
                <a (click)="seeNotif(false, $event)" class="nav-link" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                    <i class="bi bi-bell"></i>
                </a>
                <div *ngIf="nbNewNotif > 0" class="nb-notif d-flex justify-content-center align-items-center">{{ nbNewNotif }}</div>
            </div>


            <div id="notifs" class="dropdown-menu dropdown-menu-right notification" [style]="{'display': notifOpen ? 'block': 'none'}">
                <div class="dropdown-header  text-font notif">
                    <span class="text-notification">
                        {{ 'notification.title' | translate }}
                        <select (change)="onSelectChange($event)" class="select-notif">
                            <option value="all">{{ 'general.all' | translate }}</option>
                            <option value="unread">{{ 'general.unread' | translate }}</option>
                            <option value="read">{{ 'general.read' | translate }}</option>
                    </select>
                    </span>
                    <span (click)="readAllnotif()" class="check-text">
                    {{ 'general.allMarkedAsRead' | translate }}
                        <i class="bi bi-check-circle"></i>
                </span>
                </div>
                <br>
                <div class="text-center notification-empty" *ngIf="notifications.length <= 0">
                    {{ 'notification.empty' | translate }}
                </div>
                <div [style]="{'background-color':!notif.isRead ? '#f0f3f5' : '#ffffff'}" (click)="readNotif(notif, $event)" class="dropdown-item" *ngFor="let notif of notifications">
                    <span class="title" [style]="{'font-weight':!notif.isRead ? 'bold' : 'normal'}">
                        <span *ngIf="notif.info && notif.info.export">{{ notif.info.project.name }}/{{ notif.info.export.name }}/</span>{{ notif.subject }} :
                    </span>
                    <span *ngIf="notif.info && notif.action === 'message'" class="notifcation-contents">
                        {{ 'notification.startandEndDate' | translate }} : {{ notif.info }}
                    </span>
                    <span class="notifcation-contents" *ngIf="notif.type != 'page' && notif.action != 'package'">
                        {{ 'notification.' + notif.type + '.' + notif.action | translate }} <span *ngIf="notif.action == 'expired'" style="font-weight: bold;">{{ notif.info }}</span>
                    </span>
                    <a class="notification-link" *ngIf="notif.type == 'product'" target="_blank" [routerLink]="['/' + routes.pim.base, routes.pim.element, routes.actions.update, notif.subjectId]"
                       routerLinkActive="active">
                        {{ 'notification.accessProduct'| translate }}
                    </a>
                    <a class="notification-link" *ngIf="notif.type == 'page' && notif.action != 'package'" target="_blank"
                       [routerLink]="['/' + routes.projects.base, notif.info.project.id, routes.projects.export,notif.info.export.id,routes.projects.page, notif.subjectId]" routerLinkActive="active">
                        {{ 'notification.accessPage'| translate }}
                    </a>
                    <span class="notifcation-contents" *ngIf="notif.type == 'page' && notif.action == 'package' && notif.info && notif.info.path">
                        <button class="btn btn-primary notification-link" (click)="downloadFileFromNotif(notif)">
                            {{ 'projects.export.generation.package'| translate }}
                        </button>
                    </span>
                    <a class="notification-link" *ngIf="notif.type == 'media'" target="_blank"
                       [routerLink]="['/' + routes.dam.base, routes.dam.list]" [queryParams]="{ id: notif.subjectId }"
                       routerLinkActive="active">
                        {{ 'notification.accessPage'| translate }}
                    </a>
                    <br>
                    <span class="date text-font-date"> {{ calculateDateDiff(notif.create) }} </span>
                </div>
                <div *ngIf="notifications.length < nbAllNotif" (click)="$event.stopPropagation()" class="text-center m-2">
                    <a class="see-more-style" (click)="seeNotif(true, $event)">
                        {{ 'general.seeMore' | translate }}
                    </a>
                </div>
            </div>
        </li>
        <li *ngIf="activatedModules.admin && hasAdminAcl" class="nav-item px-3">
            <a class="nav-link" [routerLink]="acl.SYSPAD_ADMIN_USER_LIST ? ['/' + routes.admin.base + '/' + routes.admin.users] : ['/' + routes.admin.base]" routerLinkActive="active">
                <fa-icon [icon]="faTools" [fixedWidth]="true"></fa-icon>
                <span class="d-none d-md-inline-block ml-1">
                    {{ 'header.admin' | translate }}
                </span>
            </a>
        </li>
        <li class="nav-item dropdown">
            <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                <img class="img-avatar" src="/assets/img/gravatar-60-grey.jpg" alt="admin@bootstrapmaster.com"/>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-header text-center">
                    <strong>{{ 'header.account.title' | translate }}</strong>
                </div>
                <div class="dropdown-item">
                    <fa-icon [icon]="faUser" [fixedWidth]="true"></fa-icon>
                    {{ 'header.account.connectAs' | translate }} : {{ username }}
                </div>
                <button class="dropdown-item" (click)="goToProfile()">
                    <fa-icon [icon]="faIdCard" [fixedWidth]="true"></fa-icon>
                    {{ 'admin.users.profile' | translate }}
                </button>
                <a class="dropdown-item d-flex" (click)="$event.stopPropagation()">
                    <fa-icon class="mt-1" [icon]="faFlag" [fixedWidth]="true"></fa-icon>
                    <select (change)="setLang($event.target.value)" [value]="getSelectedLang()" class="form-control form-control-sm ml-2">
                        <option value="fr">{{ "general.lang.french" | translate }}</option>
                        <option value="en">{{ "general.lang.english" | translate }}</option>
                    </select>
                </a>
                <a (click)="logout()" class="dropdown-item">
                    <fa-icon [icon]="faSignOutAlt" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.account.logout' | translate }}
                    </span>
                </a>
                <div class="dropdown-header text-center">
                    <strong>{{ 'header.support.title' | translate }}</strong>
                </div>
                <a class="dropdown-item" href="http://support.compos-juliot.fr/" target="_blank">
                    <fa-icon [icon]="faLifeRing" [fixedWidth]="true"></fa-icon>
                    <span class="d-inline-block ml-1">
                        {{ 'header.support.online' | translate }}
                    </span>
                </a>
                <span class="dropdown-item" href="#">
                    <div class="message">
                        <div class="text-truncate">
                            <fa-icon [icon]="faPhone" [fixedWidth]="true"></fa-icon> +33 (0) 1 48 24 61 61
                        </div>
                    </div>
                </span>
                <div class="dropdown-header text-center">
                    <strong>{{ 'header.about' | translate }}</strong>
                </div>
                <a class="dropdown-item" href="http://www.maestro-solution.com" target="_blank">
                    <fa-icon [icon]="faRegistered" [fixedWidth]="true"></fa-icon>
                    Maestro
                </a>
                <a class="dropdown-item" href="http://www.compos-juliot.fr/" target="_blank">
                    <fa-icon [icon]="faCopyright" [fixedWidth]="true"></fa-icon>
                    Compos Juliot
                </a>
            </div>
        </li>

    </ul>
</header>
