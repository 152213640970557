import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "app/core/services/admin/language/language.service";
import { UserService } from "app/core/services/admin/user/user.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { WorkflowData } from "app/shared/components/workflow";
import { WorkflowStep } from "./workflow";


@Component({
    selector: "app-workflow",
    templateUrl: "./workflow.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["./workflow.component.scss"],

})


export class WorkflowComponent implements OnInit {
    @Input() display: WorkflowData;
    @Input() transitionsLanguage: any;
    @Input() canSetValue: boolean = false;
    @Output() transitionKey: EventEmitter<{ "key": string, "note": string }> = new EventEmitter();
    @Output() lastStep: EventEmitter<string> = new EventEmitter();
    @Output() validTrad: EventEmitter<{"step": number, "langue": string, "note": string}> = new EventEmitter();


    places: any;
    i = 0;
    workflow = false;
    disabledButton = true;
    cancelWorkflow = this.translate.instant('general.cancelWorkflow');
    validateTheWorkflow = this.translate.instant('general.validateTheWorkflow');
    isTrad: boolean = false;
    langue : any;

    indexCurrentPlace: number;

    public faClose = faTimes;
    public faValidate = faCheck;
    private key: string

    constructor(private translate: TranslateService, private _modalService: SwalModalService, private _languageService: LanguageService, private _userService: UserService) {

    }

    ngOnInit(): void {

        for (let i = 0; i < this.display.places.length; i++) {
            if (this.display.places[i].key === this.display.currentPlace) {
                this.indexCurrentPlace = i;
            }
        }

        this.places = [...this.display.places];
        this.places.pop();        

        this._languageService.selectedLanguage$.subscribe((language) => {
            this.getLanguage(language);
        });

        this._languageService.validLanguage$.subscribe(() => {   
            if(this.langue && !this.langue.isBasic){
                this.transitionsLanguage = this._languageService.getTransitionsLanguage();         
            }
            this.getLanguage(this.langue);
        });

        
        
    }

    getLanguage(language){
        this.langue = language;
                
        this.places = [...this.display.places];
        this.places.pop();        
        if(language && !language.isBasic){
            this.isTrad = true;
            this.places.forEach(place => {
                let index = place.languages.findIndex((stepLangue) => stepLangue.id == language.id);
                if(index >= 0){
                    place.isActiveTrad = true;
                    place.titleTrad = place.title + "-" + language.code 
                    place.isValidatedTrad = place.languages[index].valid;
                }else {
                    place.isActiveTrad = false;
                    place.titleTrad = place.title;
                }    
                if(this.transitionsLanguage){
                            
                    Object.keys(this.transitionsLanguage).forEach(langue => {
                        if(this.transitionsLanguage[langue][place.key]){
                            place.transitionsLanguage[langue] = this.transitionsLanguage[langue][place.key];
                        }
                    })
                }             
            });
        } else {
            this.isTrad = false;
        }        
    }

    ngAfterContentChecked(): void {
        //Called after every check of the component's or directive's content.
        //Add 'implements AfterContentChecked' to the class.
        for (let i = 0; i < this.display.places.length; i++) {
            if (this.display.places[i].key === this.display.currentPlace) {
                this.indexCurrentPlace = i;
            }
        }
        this.places = [...this.display.places];
        this.places.pop();
    }

    getTransitionKey(key, event) {
        event.preventDefault();
        if(this.canSetValue){
            this._modalService.confirmText({}).then((result) => {
    
                if (result.isConfirmed) {
                    let note = result.value ? result.value : '';
                    if (key) {
                        if (key.key) {
                            key = key.key;
                        }
                    } else {
                        return;
                    }
    
                    if (this.display.places.length > 0) {
                        const index = this.display.places.findIndex((place) => place.key === key);
                        const indexCheck = this.display.places.findIndex((place) => place.isActive === true);
                        let tran = null;
    
                        if (index >= indexCheck) {
                            tran = this.display.display.find((display) => display.endPlace.key === key);
                        } else {
                            tran = this.display.display.find((display) => display.endPlace.key === key && display.nom.includes("_Reject"));
                        }
    
                        this.transitionKey.emit({ key: tran.key, note: note });
    
                        if (tran.nom === "validWorkflow") {
                            this.lastStep.emit(note);
                        }
                    }
                }
            });
        }
    }

    validationTradStep(step, event){        
        event.preventDefault();
        if(this.canSetValue){
            this._modalService.confirmText({}).then((result) => {
                if(result.isConfirmed) {
                    let note = result.value ? result.value : '';
                    this.validTrad.emit({
                        "step": step,
                        "langue": this.langue.id,
                        "note": note
                    });
                }
            })
        }
    }

    getTitle(place: WorkflowStep): string {
        if (place.lastUserInteraction) {
            let result = place.lastDateInteraction + " - " + place.lastUserInteraction;

            if (place.note) {

                result += "\n Note: " + place.note;

            }

            return result;
        }
        return this.translate.instant("general.no.data");
    }
    getInfoWorkflow(key) {

        let html = `
        <div class="table-responsive">
            <h3 class="text-center">${this.translate.instant('general.actionHistory')}</h3>
        <table class="table table-striped">
        <thead>
            <tr>
                <th>${this.translate.instant("general.date.dateNow")}</th>
                <th>${this.translate.instant('sidebar.users')}</th>
                <th>${this.translate.instant('general.action')}</th>
                <th>${this.translate.instant('general.note')}</th>
            </tr>
        </thead>
        <tbody>`;

        if (this.langue.isBasic && key.transitions && Array.isArray(key.transitions)) {
            key.transitions.forEach((transition) => {
                if (transition['scenario'] === "Step_reject") {
                    transition['scenario'] = this.translate.instant('general.dismiss');
                } else {
                    transition['scenario'] = this.translate.instant('general.Tovalidate');

                }
                html += `<tr><td>${transition['date']}</td><td>${transition['user']}</td><td>${transition['scenario']}</td><td>${transition['note']}</td></tr>`

            });

            html += "</tbody></table></div>";

            this._modalService.historyModalWorkflow(html)
        } else if(!this.langue.isBasic && key.transitionsLanguage && Array.isArray(key.transitionsLanguage[this.langue.id])){           
            key.transitionsLanguage[this.langue.id].forEach((transition) => {
                if (transition['scenario'] === "Step_rejectLanguage") {
                    transition['scenario'] = this.translate.instant('general.dismiss');
                } else {
                    transition['scenario'] = this.translate.instant('general.Tovalidate');

                }
                html += `<tr><td>${transition['date']}</td><td>${transition['user']}</td><td>${transition['scenario']}</td><td>${transition['note']}</td></tr>`

            });

            html += "</tbody></table></div>";

            this._modalService.historyModalWorkflow(html)
        } else {
            this._modalService.error(this.translate.instant('history.emptyhistory'));
        }
    }
    isNotValidated(place: any): boolean {
        return !place.isValidated;
    }


    getLanguageStep(languages){
        let result = "";

        languages.forEach((language) => {
            if(result != "")[
                result += " | "
            ]
            result += language.name
        });

        return result;
    }
}
