import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "capitalizeFirstLetter",
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
    transform(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}
