<!-- Modal -->
<div class="row" *ngIf="form">
    <div class="col-12">
        <h3 *ngIf="language">
            {{ 'admin.language.configure' | translate }}
        </h3>
        <h3 *ngIf="!language">{{ 'admin.language.create' | translate }}</h3>
    </div>
</div>
<div class="tableComponent row">
    <div class="col-12">
        <div class="card">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="formSubmit()" autocomplete="off">
                <div class="card-body">
                    <div class="form-group row">
                        <label class="col-5 col-md-2"  translate>{{ 'table.label' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="text" id="label" formControlName="label" class="form-control" [ngClass]="{ 'is-invalid': formControls.label.dirty && formControls.label.errors }"/>
                            <div *ngIf="formControls.label.dirty && formControls.label.errors" class="invalid-feedback">
                                <div *ngIf="formControls.label.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="code" translate>{{ 'table.code' | translate }}  </label>
                        <div class="col-7 col-md-10">
                            <input type="text" id="code" formControlName="code" (input)="updateInternationalCode()"  class="form-control" [ngClass]="{ 'is-invalid': formControls.code.dirty &&
                            formControls.code.errors }"/>
                            <div *ngIf="formControls.code.dirty && formControls.code.errors" class="invalid-feedback">
                                <div *ngIf="formControls.code.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="internationalCode" translate>{{ 'table.internationalCode' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="text" id="internationalCode" formControlName="internationalCode" class="form-control" [ngClass]="{ 'is-invalid': formControls.internationalCode.dirty && formControls.internationalCode.errors }"/>
                            <div *ngIf="formControls.internationalCode.dirty && formControls.internationalCode.errors" class="invalid-feedback">
                                <div *ngIf="formControls.internationalCode.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="flag" translate>
                            {{ 'table.flag' | translate }}
                            <img *ngIf="formControls.flag.value" src="{{ formControls.flag.value }}" >
                        </label>
                        <div class="col-7 col-md-10">
                            <input type="text" id="flag" formControlName="flag" class="form-control" [ngClass]="{ 'is-invalid': formControls.flag.dirty && formControls.flag.errors }"/>
                            <div *ngIf="formControls.flag.dirty && formControls.flag.errors" class="invalid-feedback">
                                <div *ngIf="formControls.flag.errors.required">
                                    {{ 'general.required' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="form-group row">
                        <label class="col-5 col-md-2" for="flag" translate>{{ 'table.isVisible' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <label class="switch switch-label switch-sm switch-outline-primary">
                                <input class="switch-input" type="checkbox" [checked]="formControls.isVisible.value ? 'checked' : false" (change)="onChange()"/>
                                <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="row">
                        <button class="btn btn-danger" type="button" (click)="onBack()">
                            <!-- <fa-icon [icon]="faTimes"></fa-icon> -->
                            <span class="d-none d-md-inline-block ml-2">{{ 'general.close' | translate }}</span>
                        </button>
                        <div class="btn-group ml-auto">
                            <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="formSubmit()">
                                <!-- <fa-icon [icon]="faSave"></fa-icon> -->
                                <span class="d-none d-md-inline-block ml-2">{{ 'general.save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
