import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared";
import { SharedComponentsModule } from "app/shared/components.module";
import { ChipsModule } from "primeng-lts/chips";
import { HomeTableComponent } from "./home-table/home-table.component";
import { HomeWorkflowTableComponent } from "./home-workflow-table/home-workflow-table.component";
import { PimHomeComponent } from "./home.component";
import { PimHomeResolver } from "./home.resolver";import {
    ModalFormLanguageModule
} from "app/shared/components/modal-form-language/modal-form-language.module";

@NgModule({
    declarations: [PimHomeComponent, HomeTableComponent, HomeWorkflowTableComponent],
    imports: [SharedModule, SharedComponentsModule, ChipsModule, ModalFormLanguageModule],
    exports: [PimHomeComponent],
    providers: [PimHomeResolver],
})
export class PimHomeModule {}
